import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
            {
                imageSrc: "/images/blog/gruendungspreis/bmwi-gruendungspreisglogo.png",
                title: "Der Gründungspreis des BMWi",
                description: <>
                    Danke für diese großartige Auszeichnung! Von 388 eingereichten Ideenskizzen wurden unsere Idee als eine
                    der besten mit dem Gründungspreis des <b>BMWi, Bundesministeriums für Wirtschaft und Energie</b>
                    (<a href="https://bit.ly/3CUczn1">bit.ly/3CUczn1</a>), im Wert von 7.000 Euro ausgezeichnet. Zusätzlich
                    gibt es auch die Möglichkeit an verschiedenen Coachings und Events mit potentiellen Kund:innen und
                    Investor:innen teilzunehmen.
                    <br/>
                    <br/>
                    Aufgrund der COVID-19-Pandemie fand die veranstaltung digital statt und konnte über LinkedIn und YouTube
                    verfolgt werden. Die angenehme Moderation von Alexa von Busse und Dr. Alexander Tettenborn, sowie das
                    ermunternde Vorwort von Thomas Jarzombek, Beauftragter für die Digitale Wirtschaft und Startups, werden
                    uns positiv in Erinnerung bleiben. Herr Jarzombek beeindruckte mit dem Fakt, dass Startups, welche mit
                    dem BMWi Gründungspreis in der Vergangenheit ausgezeichnet wurden <b>zu 90% nach 3 Jahren noch auf dem
                    Markt</b> sind. Weiterhin motivierten die erwähnten erfolgreichen Beispiele wie Ecosia, Komoot,
                    MyMuesli, und Signavio, welche erst kürzlich einen EXIT an SAP gelang. Dies verdeutlichte, dass beim
                    BMWi Gründungspreis oftmals das Preisgeld nicht der größte Mehrwert ist, sondern, dass das Netzwerk und
                    die Signalwirkung an VC’s, Business Angels, und potentielle Kund:innen von immenser Bedeutung ist. Auch
                    wenn wir den Preis gerne persönlich in Berlin entgegengenommen hätten, blicken war auf eine rundum
                    gelungene Veranstaltung zurück.

                </>
            }, {
                imageSrc: "/images/blog/gruendungspreis/josua-from-atlat-presenting-during-bmwi-gruendungspreis.jpeg",
                title: "1-Line-Pitch: Präsentation des Startups in 60 Sekunden",
                description: <>
                    Die Gewinner-Teams hatten die Gelegenheit sich und ihre Innovationen in Form von One-Minute-Pitches
                    vorstellen. Unser Co-Founder Josua Ovari hat in weniger als 60 Sekunden die Probleme der
                    Arbeitsbedingungen in globalen Lieferketten, und die Vorteile des digitalen Beschwerdesystems von atlat
                    auf den Punkt erklärt.

                    <br/>
                    <br/>
                    Es freut uns, dass die Jury bei der Bewertung von atlat insbesondere den Mehrwert der <b>Vereinbarung
                    digitaler Softwarelösungen mit den nachhaltigen Entwicklungszielen der Vereinten Nationen</b> hervorhob.
                    Andere innovationen Ideen aus den verschiedensten Branchen wie Medizin, Maschinenbau, Tourismus wurden
                    ausgezeichnet. Der diesjährige Sonderpreis „Digitale Städte und Regionen“ wurde an das Aachener Startup
                    Cityscaper vergeben . Der Stream der Veranstaltung und somit auch der 1-Line-Pitch zu atlat lässt sich
                    mit folgenden Link abrufen: <a href="https://bit.ly/3COIgy4">bit.ly/3COIgy4</a>
                    <br/>
                    <br/>
                    Weitere Informationen zum Gründungspreis sind auch der Website des BMWi zu entnehmen: <a
                    href="https://bit.ly/3DYV1Y4">bit.ly/3DYV1Y4</a>
                </>
            }, {
                imageSrc: "/images/blog/gruendungspreis/atlat-wins-at-BMWi-high-five.png",
                title: "Danke und high 5 für diese großartige Leistung! 💚",
                description: <p>
                    Wir gratulieren allen weiteren Preisträger:innen und wünschen Ihnen alles gute für den weiteren
                    unternehmerischen Erfolg! Und wir bedanken uns für die Organisation dieses Preises beim BMWi und beim
                    VDI/VDE Innovation + Technik GmbH (<a href="https://bit.ly/3D7dnoR">bit.ly/3D7dnoR</a>).
                    <br/>
                    <br/>
                    Dieser Preis bestätigt uns in unserem Gründungsvorhaben und motiviert uns daran noch mehr Energie in
                    unsere Arbeit zu stecken, um jeder Beschwerde Gehör zu verschaffen!
                    <br/>
                    <br/>
                    Wir freuen uns über deine Mail:&nbsp;<a href="mailto:team@atlat.de">team@atlat.de</a>
                </p>
            }
            ,
        ]
    ;

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Gründungspreis des BMWi</HeadingTitle>
                        <HeadingDescription>
                            Von 388 eingereichten Gründungsideen für digitale Innovationen aus Deutschland wurde unser
                            Startup als eines der besten bewertet.🤩
                            <br/>
                            <br/>
                            Wir freuen uns sehr über diesen Gründungspreis des BMWi! 🥳
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Kontakt
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
