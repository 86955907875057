import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import {SectionHeading, Subheading as SubheadingBase} from "../misc/Headings.js";
import TeamIllustrationSrc from "../../images/team-illustration-2.svg";
import {ReactComponent as SvgDotPattern} from "../../images/dot-pattern.svg";
import {useTranslation} from "react-i18next";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
    tw`md:w-6/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.img(props => [
    props.imageRounded && tw`rounded`,
    props.imageBorder && tw`border`,
    props.imageShadow && tw`shadow`
]);

const DecoratorBlob = styled(SvgDotPattern)(() => [
    tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
    SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium`;

export default ({
                    subheading = "Our Expertise",
                    heading = (
                        <>
                            Designed & Developed by <span tw="text-primary-500">Professionals.</span>
                        </>
                    ),
                    imageSrc = TeamIllustrationSrc,
                    imageRounded = true,
                    imageBorder = false,
                    imageShadow = false,
                    imageDecoratorBlob = false,
                    textOnLeft = true,
                    steps = null,
                    decoratorBlobCss = null,
                }) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

    const {t} = useTranslation(['steps']);

    const defaultSteps = [
        {
            heading: t('steps:step1Header', 'Register'),
            description: t('steps:step1Text', 'Create an account with us using Google or Facebook or just your email address.'),
        },
        {
            heading: t('steps:step2Header', 'Analyze'),
            description: t('steps:step2Text', 'atlat enables you to analyse your supply chain to detect possible threats.'),
        },
        {
            heading: t('steps:step3Header', 'Act'),
            description: t('steps:step3Text', 'Based on the analysis concrete actions items will be presented.'),
        }
    ];

    if (!steps) steps = defaultSteps;

    return (
        <Container>
            <TwoColumn>
                <ImageColumn>
                    <Image src={imageSrc} imageBorder={imageBorder} imageShadow={imageShadow}
                           imageRounded={imageRounded}/>
                    {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss}/>}
                </ImageColumn>
                <TextColumn textOnLeft={textOnLeft}>
                    <TextContent>
                        <Subheading>{subheading}</Subheading>
                        <Heading>{heading}</Heading>
                        <Steps>
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepNumber>{(index + 1).toString().padStart(2, '0')}</StepNumber>
                                    <StepText>
                                        <StepHeading>{step.heading}</StepHeading>
                                        <StepDescription>{step.description}</StepDescription>
                                    </StepText>
                                </Step>
                            ))}
                        </Steps>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};
