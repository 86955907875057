import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-80 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc:
                "https://images.unsplash.com/photo-1589578527966-fdac0f44566c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=632&q=80",
            title: "Eckpunkte des Lieferkettengesetz",
            description:
                <p>Seit einigen Monaten befindet sich das Eckpunktepapier für das Lieferkettengesetz,
                    beziehungsweise Sorgfaltspflichtengesetz, wie der Titel nun wohl lauten wird,
                    in der Abstimmung zwischen den Ministerien. Während über einige Punkte wie die anzuwendende
                    Mitarbeitergrenze oder die zivilrechtliche Haftung noch heftig debattiert wird, sind andere
                    Aspekte bereits unumstritten von allen Seiten anerkannt. Eine Liste aller von einem breiten
                    zivil gesellschaftlichen Bündnis geforderter Inhalte des Gesetzes wird auf der
                    <a style={{color: "#1b8591"}} target="_blank" href="https://lieferkettengesetz.de/forderungen">Website
                        der Initiative Lieferkettengesetz</a>
                    dargestellt.
                    Die Einigung der beteiligten Ministerien darauf, einige dieser Forderungen in das fertige Gesetz
                    zu übernehmen, wurde bereits im Juni in diesem <a style={{color: "#1b8591"}} target="_blank"
                                                                      href="https://www.handelsblatt.com/politik/deutschland/lieferkettengesetz-heil-und-mueller-entschaerfen-die-haftungsregeln-fuer-unternehmen/25947310.html">Handelsblatt-Artikel</a> besprochen.
                </p>
        },

        {
            imageSrc:
                "https://images.unsplash.com/photo-1530099486328-e021101a494a?ixlib==rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
            title: "Beschwerdemechanismus wird verpflichtend",
            description:
                "Zu den feststehenden Eckpunkten, die sich voraussichtlich im Sorgfaltspflichtengesetz wiederfinden werden, gehört neben der Risikoanalyse und dem Sorgfaltsplan auch die Einführung von Beschwerdemechanismen für Beschäftigte in der Lieferkette. Vorausschauend agierende Unternehmen, die unter das neue Sorgfaltspflichtengesetz fallen werden, können sich also bereits auf diese neue Anforderung einstellen.",
        },
        {
            imageSrc:
                "https://images.unsplash.com/uploads/1413222992504f1b734a6/1928e537?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "Vorteile des Kommunikationskanals",
            description:
                "Insbesondere die Einführung von Beschwerdemechanismen für die Angestellten bei Zuliefererunternehmen ist hierbei interessant. Man kann davon ausgehen, dass diese Mechanismen eine positive Wirkung entfalten werden. Zum Einen helfen diese Mechanismen insbesondere in der Zeit zwischen Zertifizierungsaudits und anderen Werksbesuchen, um die Einhaltung von Standards sicherzustellen. Zum Anderen können Arbeiterinnen und Arbeiter bei Zulieferern auch aktiv ermutigt werden um nicht nur Beschwerden, sondern auch im Schutze der Anonymität ehrliches Feedback und Anregungen für Verbesserungen einzureichen.",
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1499159058454-75067059248a?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "Herausforderung bei Kommunikation in Lieferkette",
            description: "Allerdings stellen die Beschwerdemechanismen für Beschäftigte bei Zulieferfirmen die Unternehmen natürlich vor organisatorische Herausforderungen. Es muss ein Kommunikationskanal für eine Vielzahl von Menschen geöffnet werden, häufig in einer für ein deutsches Unternehmen ungewohnten Sprache. Dazu kommen praktische Beschränkungen, wie die Endgeräte, die den Menschen vor Ort zur Verfügung stehen. Außerdem ist es gar nicht so leicht, diesen Arbeiterinnen und Arbeitern eines Zuliefererbetriebs entlang der Wertschöpfungskette diesen Kanal überhaupt bekannt zu machen. Schlussendlich wird die Transparenzanforderung verlangen, dass über die Kommunikation mittels des Beschwerdemechanismus zumindest ein zusammenfassender Bericht veröffentlicht werden muss."
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1427751840561-9852520f8ce8?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "Einfach zu nutzende Softwarelösung",
            description: <> <p>Um das Potential einer Kommunikation mit den Angestellten entlang der Wertschöpfungskette
                zu nutzen und um neue rechtliche Anforderungen zu erfüllen, erarbeiten wir von True Colors eine
                Softwarelösung. Diese ermöglicht es, einen Inhalt wie ein Beschwerdeformular oder eine kurze Schulung
                auf Deutsch oder Englisch zu erstellen. Dieser Inhalt wird von uns sowohl in die Landessprache des
                Zulieferbetriebs übersetzt und in ein für ein mobiles Endgerät freundliches Format übertragen. Ihr
                Inhalt wird über einen Link, einen QR-Code oder eine Mobilfunknummer an den Zulieferer übermittelt, der
                diesen seinen Beschäftigten zugänglich macht. Diese können den Inhalt auf den ihnen zur Verfügung
                stehenden Geräten - seien es Computer, Smartphones, einfache Handys oder Telefone - nutzen und auch
                selbst Antworten eingeben. Das deutsche Unternehmen erhält über eine Dashboardlösung Feedback, sieht, ob
                Schulungen erfolgreich durchgeführt werden und hat so einen Kommunikationskanal geschaffen.</p><p>Wenn
                Sie mehr über unsere Softwarelösung für einen direkten Kommunikationskanal erfahren möchten, können Sie
                sich mehr Informationen auf der Produktseite anschauen. Oder kontaktieren Sie uns gerne einfach direkt
                um mit uns ins Gespräch zu kommen.</p></>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Kommunikation entlang der Lieferkette einfach gemacht</HeadingTitle>
                        <HeadingDescription>
                            Beschwerdemechanismen für Beschäftigte bei Zulieferern werden ein Bestandteil des
                            Sorgfaltspflichtengesetz. True Colors bietet eine einfach zu nutzende Softwarelösung an.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>

                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Erfahren Sie mehr
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
