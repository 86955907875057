import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "../components/headers/light.js";
import Footer from "../components/footers/FiveColumnWithInputForm.js";
import HappyIllustrationSrc from "../images/happy-illustration.svg";
import styled from "styled-components";
import {SectionHeading} from "../components/misc/Headings";
import {useTranslation} from "react-i18next";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Line = tw.p`mt-3 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

export default () => {
    const {t} = useTranslation(['imprint']);
    return (
        <AnimationRevealPage>
            <Header/>
            <Container>

                <TwoColumn>
                    <ImageColumn>
                        <Image imageSrc={HappyIllustrationSrc}/>
                    </ImageColumn>
                    <TextColumn textOnLeft={true}>
                        <TextContent>
                            <Heading>{t('imprint:heading', 'Imprint ')}</Heading>
                            <Line>atlat GmbH</Line>
                            <Line>c/o Impact Hub Munich</Line>
                            <Line>Gotzinger Str. 8</Line>
                            <Line>81371 München</Line>
                            <Line>team@atlat.de</Line>
                            <Line>+49 1573 5581058</Line>
                            <Line>Handelsregister: HRB 276406</Line>
                            <Line>USt-IDNr.: DE354112119</Line>
                            <Line>Amtsgericht München</Line>
                            <Line>vertreten durch den Geschäftsführer Josua Ovari</Line>
                            <Line>Datenschutzbeauftragte: <a href="mailto:privacy@atlat.de">privacy@atlat.de</a></Line>
                        </TextContent>
                    </TextColumn>
                </TwoColumn>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
