import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/ispo2022/josua-and-hauke-from-atlat-at-ispo-2022.jpeg",
            title: "The Red Thread between sports and textiles 🧶",
            description: <>
                After a two-year break due to the COVID-19 pandemic, the ISPO (the abbreviation for “Internationale
                Fachmesse für Sportartikel und Sportmode” 🙂) took place in Messestadt Riem in Munich. 🏂
                <br/> Generally business isn't looking bad. In January, McKinsey predicted Sport & Outdoor sales in
                Europe with approx. 11.95 billion euros.
                <br/> Although there are no longer any Corona requirements, the number of exhibitors has fallen sharply.
                According to the Munich trade fair company, it has almost halved compared to the record year 2019, there
                are around 1500 exhibitors from 55 countries (incl. “therapie München”) this year. The industry fears
                that the sport and fitness trend boosted by the corona pandemic could falter due to high inflation.
            </>
        }, {
            imageSrc: "/images/blog/ispo2022/deuter-promise-at-ispo-2022.jpeg",
            title: "Hiking boots stepping into a more sustainable future 🥾",
            description: <>
                Winter sports are traditionally the focus of ISPO, but many different kinds of exhibitors have shown
                their latest trends and developments, especially in the area of sustainability and circularity. Durable
                materials with as little impact on the environment as possible, and repair shops for clothes, backpacks,
                and shoes have shown big advancements in the area.
                <br/> When visiting the Deuter booth and we were impressed by the wide range of employees from all
                different departments, and we were even more impressed with the detailed information on the Deuter
                Promise for Planet and People.💚
                <br/> We had the chance to gather a lot of information on the topic of sustainability in the sports
                industry at the Sustainability Hub. At the hub, it was shown how large companies and suppliers such as
                Icebug, Globetrotter, or American Wool implement sustainability. The hub was focusing on three main
                points: traceability, transparency, and the effects of climate protection measures on manufacturers.
                Moreover, the most innovative outdoor products of the future are on display in the ISPO Award Exhibition
                Area. Yet, according to ISPO, finding truly sustainable sports textiles is still a challenge for
                consumers: There are quite a few who would practice greenwashing.
            </>
        }, {
            imageSrc: "/images/blog/ispo2022/panel-discussion-at-ispo-2022.jpeg",
            title: "Panels, Talks, and Workshops 💬",
            description: <>
                We had the chance, to listen to great panel discussions, and talks with practical case studies, and to
                participate in interactive workshops.
                <br/>David Ekelund from Icebug gave a practical insight into a pilot project of building solar panel
                roofs in textile factories. He explained how Icebug CEIA teamed up with other brands to make the whole
                organization process easier and cheaper for everyone with the result of nine factories in Vietnam in the
                direction of solar panels. ☀️
                <br/>We also had the opportunity to listen to an impressive panel discussion about “Who comes? Who
                stays? What goes?” with Antje von Dewitz from VAUDE, Reiner Gerstner from Schöffel Sportbekleidung GmbH,
                Irina Andorfer from Oberalp Group, Ingo Jost from L-Fashion Group GmbH moderated by Mara Javorovic from
                TextilWirtschaft.
                <br/>Especially, the inspiring and outspoken words from Antje von Dewitz, CEO of VAUDE, stuck with us.
                She talked about closing the loop at VAUDE and strengthening sustainable efforts in the whole industry
                to truly become sustainable. The manufacturer VAUDE has been a pioneer in climate neutrality and
                sustainability in the outdoor industry for over a decade.
                <br/>Another insight that gives hope for the future from the panel discussion was given by Reiner
                Gerstner, responsible for marketing at Schöffel. He explained how Schöffel takes the views of
                “Generation alpha” (people born after 2010, so at most 12 years old) into account. The strong and
                outspoken opinions of this generation, which grew up with Fridays for Future, can pressure their parents
                into making more sustainable consumption choices. Let us hope that their influence grows together with
                them.💚
                <br/>The topic of social sustainability has not been the biggest focus of this year’s ISPO. Some brands
                had elaborate information on their relations with factory workers. Other brands didn’t provide any or
                little information at their booths on working conditions or grievance systems in their supply chains. We
                hope our impulses and questions triggered some efforts to have a closer look at working conditions and
                we’re more than happy to help in the process
                <br/>All in all: We’re glad we had the opportunity to visit ISPO. See you next year!
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Visiting the ISPO</HeadingTitle>
                        <HeadingDescription>
                            We had the chance to visit the ISPO, learn a lot about industry innovations, and meet a lot
                            of great people passionate about sports, textiles, and the outdoors. 🎿
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
