import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

export default () => {
    const Subheading = tw.span`tracking-wider text-sm font-medium`;
    const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
    const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
    const Description = tw.span`inline-block mt-8`;
    const imageCss = tw`rounded-4xl`;
    return (
        <AnimationRevealPage>
            <Hero
                heading={<>Where to start will all the <HighlightedText>HRDD regulations?</HighlightedText></>}
                description={<div>
                    Human rights due diligence regulation is crucial in ensuring that businesses operate ethically and
                    responsibly.
                    This webinar aims to compare the CSDDD, LkSG, and UNGP from a legal perspective to provide actionable
                    legal
                    insights for businesses.
                    <br/><br/>
                    The speaker <a href="https://www.linkedin.com/in/ACoAAAhMgxEBVdFHD1D2OiOko9A4_LtWSH4Y9W4">Holger
                    Hembach</a> is an attorney specializing in human rights law. For more than a decade he gained
                    experience in the field of human rights in various international organizations. He advises companies on
                    business and human rights.
                </div>}
                imageSrc={"/images/webinars/where-to-start-with-eu-human-rights-regulations.jpeg"}
                imageCss={imageCss}
                imageDecoratorBlob={true}
                primaryButtonText="Order Now"
                watchVideoButtonText="Watch the recording"
                watchVideoYoutubeUrl="https://www.youtube.com/embed/py3_BWpSPVk"
            />
            <Footer/>
        </AnimationRevealPage>
    );
}
