import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/deuter_visit/atlat_at_deuter.jpeg",
            title: "Visit at deuter Sport GmbH in Gersthofen",
            description: <>
                🎒🌟 We had the opportunity to experience the development of backpacks and sleeping bags up close and
                learn a lot about it. Additionally, we saw the Repair Service, met the Repair experts, and learned how
                easy it is to get a backpack repaired as a Deuter customer. We also discussed how we can cooperate in
                the future. After an inspiring meeting with the CEO, Robert Schieferle, we planned the next steps for
                our collaboration in Vietnam with the CSR team. During the visit, we also had the chance to try on the
                biggest backpack that we ever saw.
            </>
        }, {
            imageSrc: "/images/blog/deuter_visit/atlat-ceos-visit-deuter.jpeg",
            title: "Cooperation in the Textilbündnis-Project Proposal Approved",
            description: <>
                🏆 🧵 Project Proposal Approved: The joint project proposal "Digital Complaint Management and Capacity
                Building" by deuter Sport GmbH, Ortovox Sportartikel GmbH, CARE Deutschland e.V., and CARE in Vietnam
                has been approved by the Partnership for Sustainable Textiles by GIZ and BMZ. The project aims to
                improve access to complaint mechanisms for workers in the deeper supply chain, ensuring compliance with
                workers' rights, giving workers a voice, and providing remedies in case of violations. The project in
                Vietnam builds on an existing pilot project by deuter Sport GmbH and atlat GmbH, a deuter production
                partner in Vietnam. We look forward to taking the next steps toward the implementation of the project.
                🌟
            </>
        }, {
            imageSrc: "/images/blog/deuter_visit/atlat-ceo-visits-deuter-logistics-hub.jpeg",
            title: "The collaboration of deuter and atlat: It’s a match.",
            description: <>
                🤝🌱 We are incredibly proud and honored to work alongside deuter Sport GmbH as our cooperation partner in
                this joint project. deuter brings a wealth of knowledge and expertise in sustainable supply chains,
                which has been invaluable to the success of this project. We are especially grateful for the support of
                the CSR team led by Marco Hühn, Natalie Birke, and Anna Steffen who have been instrumental in ensuring
                that the project is aligned with our shared values and goals. With deuter's unwavering commitment to
                social and environmental responsibility, we could not ask for a better partner to work with. Together,
                we are making a difference in the lives of workers in the supply chain and contributing to a more just
                and sustainable future.
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>PROJECT APPROVAL AND VISITING THE DEUTER HQ IN GERSTHOFEN</HeadingTitle>
                        <HeadingDescription>
                            We had the opportunity to visit the headquarters of deuter in Gersthofen. Our joint project
                            proposal titled "Digital Complaint Management and Capacity Building" by deuter Sport GmbH,
                            Ortovox Sportartikel GmbH, CARE Deutschland e.V., and CARE in Vietnam has been approved by
                            the Partnership for Sustainable Textiles by GIZ and BMZ. What a great opportunity!
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
