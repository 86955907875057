import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "../components/headers/light.js";
import Footer from "../components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "../components/features/TwoColWithButton.js";
import TeamCardGrid from "../components/cards/ProfileThreeColGrid.js";
import {useTranslation} from "react-i18next";
import ThreeColSlider from "../components/cards/ThreeColSlider";
import PortfolioTwoCardsWithImage from "../components/cards/PortfolioTwoCardsWithImage";
import ProfileThreeColGrid from "../components/cards/ProfileThreeColGrid.js";
import TabCardGrid from "../components/cards/TabCardGrid";
import ThreeColContactDetails from "../components/cards/ThreeColContactDetails";
import TwoTrendingPreviewCardsWithImage from "../components/cards/TwoTrendingPreviewCardsWithImage";
import TwoColumnPrimaryBackground from "../components/faqs/TwoColumnPrimaryBackground";
import TwoColumnWithImageAndProfilePictureReview
    from "../components/testimonials/TwoColumnWithImageAndProfilePictureReview";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {

    const {t} = useTranslation(['aboutUs']);

    return (
        <AnimationRevealPage>
            <Header/>
            <MainFeature1
                subheading={<Subheading>{t('aboutUs:missionHeading', 'atlat\'s Mission')}</Subheading>}
                heading={t('aboutUs:missionSubheading', 'We digitize supply chain sustainability')}
                description={t('aboutUs:missionText', 'Making your supply chain more transparent is diffcult but it' +
                    ' is necessary to find opportunities to increase its sustainability. atlat accelerates the' +
                    ' process digitally.')}
                buttonRounded={false}
                primaryButtonText={t('aboutUs:makeAppointment', 'Let\'s talk')}
                primaryButtonUrl="https://atlat.de/contact-us"
                imageSrc="https://images.unsplash.com/photo-1573205485246-ee99bb898ff6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
            />
            <MainFeature1
                subheading={<Subheading>{t('aboutUs:visionHeading', 'atlat\'s Vision')}</Subheading>}
                heading={t('aboutUs:visionSubheading', 'We want to make business more sustainable')}
                description={t('aboutUs:visionText', 'As global warming accelerates, it becomes more and more urgent to act. As companies have the biggest opportunities to create change, this is where we want to start to make a change for a better future.')}
                buttonRounded={false}
                primaryButtonText={t('aboutUs:contactUs', 'Contact Us')}
                primaryButtonUrl="https://atlat.de/contact-us"
                imageSrc="https://images.unsplash.com/photo-1493612276216-ee3925520721?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
                textOnLeft={false}
            />
            <MainFeature1
                subheading={<Subheading>{t('aboutUs:impactHeading', 'impactHeading')}</Subheading>}
                heading={t('aboutUs:impactSubheading', 'impactSubheading')}
                description={t('aboutUs:impactText', 'impactText')}
                itemHeading1={t('aboutUs:impactItemHeading1', 'impactItemHeading1')}
                itemText1={t('aboutUs:impactItemText1', 'impactItemText1')}
                itemHeading2={t('aboutUs:impactItemHeading2', 'impactItemHeading2')}
                itemText2={t('aboutUs:impactItemText2', 'impactItemText2')}
                itemHeading3={t('aboutUs:impactItemHeading3', 'impactItemHeading3')}
                itemText3={t('aboutUs:impactItemText3', 'impactItemText3')}
                buttonRounded={false}
                primaryButtonText={t('aboutUs:makeAppointment', 'Let\'s talk')}
                primaryButtonUrl="https://atlat.de/contact-us"
                imageSrc="images/webinars/atlat_mission_sdgs.jpeg"
            />
            <TeamCardGrid
                subheading={<Subheading>{t('aboutUs:team.ourTeam', 'Contact Us')}</Subheading>}
                heading={t('aboutUs:team.meetThePeople', 'Meet the people behind atlat')}
                description={t('aboutUs:team.description', 'atlat is built by individuals with strong' +
                    ' expertise in IT and deep passion for the environment')}
            />
            <TwoColumnWithImageAndProfilePictureReview/>
            <MainFeature1
                subheading={<Subheading>{t('aboutUs:press.subtitle', 'News')}</Subheading>}
                heading={t('aboutUs:press.headline', 'Press')}
                description={t('aboutUs:press.text', 'Here you will find news from the world of atlat, current press releases, pictures and background information. We make all relevant content available to representatives of the press in our press room.')}
                buttonRounded={false}
                primaryButtonText={t('aboutUs:press.toPressroom', 'To Pressroom')}
                primaryButtonUrl="https://drive.google.com/drive/folders/1r17bf3wgqx-FDQKt1rVGnZHtNSzsYBDC?usp=sharing"
                imageSrc="https://images.unsplash.com/photo-1573812195421-50a396d17893?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
            />
            <Footer/>
        </AnimationRevealPage>
    );
};
