import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/fashion_for_change_vilnius/text-tracer-and-atlat-pitch-in-vilnius-for-fashion-for-change.jpeg",
            title: "FASHION FOR CHANGE",
            description: <>
                💚 Looking back on our learning journey, we can proudly say that we've come a long way! We've had so many
                fun and valuable experiences along the way. Let's highlight a few of them!
                <br/> 🙌 One of the most memorable moments of our journey was the online speed dating event with fair
                fashion experts where we met our cooperation partner tex.tracer and Nikki Admiraal and Jolanda Koi. It
                was the very start of our journey towards social compliance for the whole fashion supply chain. We were
                thrilled to have found a partner who shared our values and commitment to sustainability.
                <br/> 🥳 Another exciting moment was when we participated in the Hackathon and were selected as part of
                the 25 selected teams. We felt honored to have been chosen among so many talented individuals and teams.
                <br/> 🇪🇪 The kick-off event was an amazing experience that took place during Tallinn Design Week with
                the motto “GREEN BEING WTF”. We had the privilege of visiting the studios of Reet Aus and Stella
                Soomlais Studio, who are both committed to a circular and sustainable fashion industry. We were truly
                inspired by their work and dedication. Moreover, we had an interesting workshop from Silke Lieser to
                create our sustainable brand identity. It was a great opportunity to learn from an expert in the field
                and to develop our own sustainable brand identity.
                <br/> 👏 We also had the pleasure of going on a second study trip to Düsseldorf, where we visited the
                Neonyt Fashion Fair. It was amazing to see brands with new sustainable and circular solutions. We left
                feeling inspired and motivated to continue our journey towards a more sustainable and circular fashion
                industry.
                <br/> 🌍Overall, we are so grateful for all the amazing experiences we've had on our learning journey.
                Each one has taught us something valuable and has helped us grow as individuals and as a team. We are
                excited to see where our journey will take us next!

            </>
        }, {
            imageSrc: "/images/blog/fashion_for_change_vilnius/text-tracer-and-atlat-collaboration.png",
            title: "COOPERATION WITH TEX.TRACER",
            description: <>
                🤝 We're more than proud to announce that even though the accelerator program has come to an end, our
                partnership with tex.tracer will continue! We're committed to working together on pilot projects in
                multiple countries and utilizing the power of digital connection to promote sustainable fashion.
                <br/> 🚀 During our time in the FashionForChange Accelerator, we had the incredible learning opportunity
                to get feedback from industry and research experts and witness firsthand the amazing work being done of
                other sustainable fashion startups. It was truly inspiring to see so many like-minded individuals and
                companies coming together to work towards a common goal.
                <br/> 💚As atlat, we're motivated by making every complaint in the supply chain heard. We believe that by
                fostering cooperation and promoting transparency, we can create a more sustainable and equitable fashion
                industry for all. That's why we're so proud to be partnering with tex.tracer. Together, our combined
                vision is to move from complaints to compliance - traced for the whole supply chain. We want to enable
                textile companies to ensure a better working environment at their suppliers all the way up to the cotton
                field. By utilizing the power of technology and digital connection, we believe we can make a real
                difference in promoting sustainable and ethical practices in the fashion industry. We're excited to
                continue our journey together beyond the accelerator, and we can't wait to see the progress we can make
                towards creating a more sustainable, more transparent and fairer fashion industry for all.
                <br/> Watch the recording of the event: <a target="_blank"
                                                           href="https://www.youtube.com/watch?v=ElDzL7aYEgA">Youtube</a>
            </>
        }, {
            imageSrc: "/images/blog/fashion_for_change_vilnius/tex-tracer-x-atlat.png",
            title: "GRACIAS, DANKE, AITÄH, MERCI, KIITOS, KÖSÖNÖM, Ačiū & THX!",
            description: <>
                🧵 It has been an incredible privilege to be a part of such a fantastic program as the Fashion For Change
                EU Accelerator. We've had the opportunity to learn so much from each other, to engage in meaningful
                discussions, and to work towards a common goal of promoting sustainable fashion.
                <br/>🙏We want to take a moment to express our gratitude to all the amazing people we met during the
                program. Thank you to Fela Buyi, Haiko Huvenaars, Nikki Admiraal, Niki de Schryver, Riikka Olli, Ornella
                de la Campa, Lydia Maurer, Anna-Maria Demes, Silke Lieser, Annelies Tolenaar, Saba Alvi, Laura
                Suijkerbuijk, Monique Drent, Reet Aus PhD, Aleksander Callebat, Markus Vihma, Raphaela Maria Fritz,
                Anila Preston, and Kate Tymochouk for your contributions and for sharing your knowledge and expertise
                with us. We would also like to extend a special thanks to the ROCKIT for hosting us in Vilnius and for
                providing us with valuable insights into their programs. And, of course, we can't forget to thank the
                supporters of the Fashion For Change EU Accelerator, including CIVITTA, Estonian Academy of Arts,
                Ecopreneur.eu European Sustainable Business Federation, Katalista Ventures, and Singleton, as well as
                ROCKIT Vilnius for organizing the program.
                <br/> 🌱Biggest thank you to Arvydas Plėta and Markus Vihma for the amazing mentoring and all the
                feedback. And also a big thanks to Silke Lieser and Reet Aus PhD for the great ideas and advice during
                the program.
                <br/> 🏆 Congratulations to the five winning teams who received the award for most circular textile
                business: Fix That Shirt, Manufy, The Rewear Company, Menddie, and Lebiudesign. We are confident that
                these teams will continue to redefine what circular fashion means and will have a positive impact on the
                fashion industry.
                <br/> 🙌 As we reflect on our time in the FFC accelerator- despite all the exploitation of garment
                workers, the intransparency in the fashion supply chains, and the mindless fashion overconsumption: we
                are filled with a sense of hope for the future of sustainable fashion when thinking about these
                motivated young fashion impact professional. We're grateful for the connections we've made and the
                knowledge we've gained, and we're eager to continue our journey towards a more sustainable and equitable
                fashion industry for all.
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>FASHION FOR CHANGE PITCH IN VILNIUS</HeadingTitle>
                        <HeadingDescription>
                            Last week was the final Demo Day Pitch of the <a
                            href="https://www.linkedin.com/in/fashionforchangeeu/">Fashion For Change EU</a> accelerator
                            program where we had the chance to pitch our collaboration with tex.tracer and meet exciting
                            textile startups, fair fashion experts and investors.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
