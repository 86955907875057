import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";
import {useTranslation} from "react-i18next";
import * as ROUTES from "../../constants/routes";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-80 w-80 bg-contain bg-no-repeat bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const {t} = useTranslation(['complaintMechanism']);

    const cards = [
        {
            imageSrc: "/images/products/brand_complaint.png",
            title: t('complaintMechanism:transparencyTitle', 'Know problems to solve problems'),
            description: t('complaintMechanism:transparecny', 'It is not possible to solve problems'),
        },
        {
            imageSrc: "/images/products/World Connection _Monochromatic.png",
            title: t('complaintMechanism:translationTitle', 'No More Language Barrier'),
            description: t('complaintMechanism:translation', 'The atlat Complaint Mechanism is using automatic translation to provide all communication to all participants of the complaint process in their native language. This eliminates the language barrier which used to be one of the biggest obstacles towards effective complaint mechanisms.'),
        },
        {
            imageSrc: "/images/products/chat_hand_atlat.png",
            title: t('complaintMechanism:digitizationTitle', 'Efficient Complaint Handling'),
            description: t('complaintMechanism:digitization', 'The intuitive dashboard makes it easy to efficiently handle and resolve complaints. Furthermore you get valuable insights into your operations.'),
        },
        {
            imageSrc: "/images/products/macwireframe.png",
            title: t('complaintMechanism:dashboardTitle', 'Digitizing Complaint Mechanisms'),
            description: t('complaintMechanism:dashboard', 'The intuitive dashboard makes it easy to efficiently' +
                ' handle and resolve complaints. Furthermore you get valuable insights into your operations.M'),
        },
        {
            imageSrc: "/images/products/Data analyzing _Monochromatic.png",
            title: t('complaintMechanism:advantagesTitle', 'advantagesTitle'),
            description: t('complaintMechanism:advantages', 'advantages'),
        }
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle
                            style={{hyphens: "auto"}}>{t('complaintMechanism:title', 'Complaint Mechanism')}</HeadingTitle>
                        <HeadingDescription>
                            {t('complaintMechanism:description', 'Complaint Mechanism description')}
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{card.description}</Description>

                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href={ROUTES.BLOG_POST_22}>
                            {t('complaintMechanism:learnMore', 'Erfahren Sie mehr')}
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
