import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/eu supply chain law/european-commission-headquarter-berlaymont-in-brussels-belgium.jpg",
            title: "EU Commission published proposal",
            description: <>
                On February 23 2022 a major step towards a “European due diligence law” was taken. The EU commission
                published a proposal for the Corporate Sustainability Due Diligence Directive. The member states of the
                European Union will pass national legislation based on the EU Directive. The current document can be
                found <a
                href="https://ec.europa.eu/info/sites/default/files/1_1_183885_prop_dir_susta_en.pdf">here</a>. As the
                next step this
                proposal is now the basis for the further legislation process involving other EU institutions. In this
                article we want to take a look at this proposal, focussing on two questions:
                Which companies will be affected by the directive? What requirements will there be for companies?

            </>
        }, {
            imageSrc: "/images/blog/eu supply chain law/which-companies-are-affected-by-the-european-supply-chain-law-explained-by-atlat.png",
            title: "Which companies will be affected by the directive?\n    ",
            description: <>
                The scope of the new directive proposal targets companies based on four factors: Number of employees,
                annual turnover, location of the registered office, and the industry sector. An important idea is the
                differentiation in the threshold for companies based on the industry they are operating in. Article 2 of
                the new directive defines three sectors, which have lower thresholds due to their high-impact: textile &
                garment, food & agriculture and minerals & metals.
                These 4 types of companies are affected by the proposed EU Corporate Sustainability Due Diligence
                Directive:
                <br/>
                <br/>
                <ul style={{listStyleType: "disc"}}>
                    <li> Companies registered in the EU, not active in high-impact sectors with more than 500 employees
                        and more
                        than 150 m€ worldwide annual turnover.
                    </li>
                    <li>Companies registered in the EU, active in high-impact sectors with more than 250 employees and
                        more than
                        40 m€ worldwide annual turnover.
                    </li>
                    <li>Companies not registered in the EU, not active in high-impact sectors with more than 150 m€
                        annual
                        turnover in the EU.
                    </li>
                    <li>Companies not registered in the EU, active in high-impact sectors with more than 40 m€ annual
                        turnover
                        in the EU.
                    </li>
                </ul>
                <br/>
                We summarized these 4 cases also in the infographic.
            </>
        }, {
            imageSrc: "/images/blog/eu supply chain law/what-is-the-european-supply-chain-law-explained-by-atlat.png",
            title: "What requirements will there be for companies?",
            description: <>
                In Article 4 the proposal for the directive names 6 due diligence actions, that companies will have to
                carry out:
                <br/>
                <br/>
                <ul style={{listStyleType: "disc"}}>
                    <li>Integrating due diligence into company policies</li>
                    <li>Identifying actual or potential adverse impacts</li>
                    <li>Preventing and mitigating potential adverse impacts, and bringing actual adverse impacts to an
                        end and minimizing their extent
                    </li>
                    <li>Establishing and maintaining a complaints procedure</li>
                    <li>Monitoring the effectiveness of their due diligence policy and measures</li>
                    <li>Publicly communicating on due diligence</li>

                </ul>
                <br/>
                The requirements for each of these 6 actions are described in separate articles of the proposal.
                Several of these requirements are also in line with the German supply chain law (‘Lieferkettengesetz’)
                that will be effective as of 01. January 2023.
                <br/>
                We at atlat focus on building the most intuitive solution for workers in the supply chain as well as for
                CSR teams for a complaints procedure.
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Proposal for European Due Diligence Regulation published: Which companies will be
                            affected?</HeadingTitle>
                        <HeadingDescription>
                            The EU Commission published a proposal for a “European due diligence law”. Which companies
                            are in scope will also depend on their industry sector.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
