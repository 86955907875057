import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
            {
                imageSrc: "/images/blog/gruendungspreis/bmwi-gruendungspreisglogo.png",
                title: "The founding price of the BMWi",
                description: <>
                    Thank you for this great award! Of 388 ideas submitted, our idea was recognized as one of the best with
                    the founding prize of the <b>BMWi, Federal Ministry for Economic Affairs and Energy</b>
                    (<a href="https://bit.ly/3CUczn1">bit.ly/3CUczn1</a>), worth 7,000 euros. In addition, there is also
                    the
                    opportunity to take part in
                    various coaching sessions and events with potential customers and investors.
                    <br/>
                    <br/>
                    Due to the COVID-19 pandemic, the event took place digitally and could be followed via LinkedIn
                    and YouTube. The pleasant moderation by Alexa von Busse and Dr. Alexander Tettenborn, as well as
                    the encouraging foreword by Thomas Jarzombek, representative for the digital economy and
                    startups, will be positively remembered. Mr. Jarzombek impressed us with the fact that startups
                    that have been awarded the BMWi Founding Prize in the past are <b>90% still on the market after 3
                    years</b>. Furthermore, the mentioned successful examples such as Ecosia, Komoot, MyMuesli, and
                    Signavio, which only recently succeeded to close an Exit to SAP, motivated us. This made it
                    clear that with the BMWi founding award, the prize money is often not even the greatest benefit,
                    but that the network and the signaling effect to VCs, business angels and potential customers
                    are of immense importance. Even if we would have gladly received the award in person in Berlin,
                    we can look back on an all-round successful event.
                </>
            }, {
                imageSrc: "/images/blog/gruendungspreis/josua-from-atlat-presenting-during-bmwi-gruendungspreis.jpeg",
                title: "1-line pitch: Presentation of the startup in 60 seconds",
                description: <>
                    The winning teams had the opportunity to present themselves and their innovations in the form of
                    one-minute pitches. In less than 60 seconds, our co-founder Josua Ovari explained the problems of
                    working conditions in global supply chains and the advantages of atlat's digital complaint system in a
                    nutshell.
                    <br/>
                    <br/>
                    We are pleased that the jury particularly emphasized the added value of <b>the compatibility of digital
                    software solutions with the sustainable development goals of the United Nations when evaluating
                    atlat</b>. Other innovative ideas from a wide variety of industries such as medicine, mechanical
                    engineering, and tourism were recognized. This year's special prize "Digital Cities and Regions'' was
                    awarded to the startup Cityscaper from Aachen. The stream of the event and thus also the 1-line pitch
                    for atlat can be accessed using the following link: <a href="https://bit.ly/3COIgy4">bit.ly/3COIgy4</a>
                    <br/>
                    <br/>
                    Further information on the founding award can also be found on the BMWi website: <a
                    href="https://bit.ly/3DYV1Y4">bit.ly/3DYV1Y4</a>
                </>
            }, {
                imageSrc: "/images/blog/gruendungspreis/atlat-wins-at-BMWi-high-five.png",
                title: "Thanks and high 5 to this great achievement! 💚",
                description: <p>
                    We congratulate all other award winners and wish you all the best for your continued business success!
                    And we thank the BMWi and VDI / VDE Innovation + Technik GmbH ( <a
                    href="https://bit.ly/3D7dnoR">bit.ly/3D7dnoR</a>) for organizing
                    this award.
                    <br/>
                    <br/>
                    This award confirms our founding project and motivates us to put even more energy into our work in order
                    to make every complaint heard!
                    <br/>
                    <br/>
                    Get in contact with us:&nbsp;<a href="mailto:team@atlat.de">team@atlat.de</a>
                </p>
            }
            ,
        ]
    ;

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Founding price of the BMWi</HeadingTitle>
                        <HeadingDescription>
                            Of 388 start-up ideas for digital innovations from Germany that were submitted,
                            our startup
                            was rated as one of the best.🤩
                            <br/>
                            <br/>
                            We are very happy about this founding award from the BMWi! 🥳
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
