import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/rana_place_remembrance/woman-waeving.jpg",
            title: "The Need for Regulations",
            description: <>
                In response to the Rana Plaza collapse, the Accord on Fire and Building Safety in Bangladesh was created
                to improve working conditions in the garment industry. With the Accord the industry has made significant
                progress in improving worker safety by conducting more than 35,000 factory inspections, resulting in the
                elimination of more than 100,000 safety hazards. Over 175 international brands have signed the Accord,
                but some major brands have not. More brands especially in North America, need to sign the Accord.
                Without the Accord, there is no regulation, no safety, and no accountability.
                <br/> <br/> 🏛️Three organizations, the National Garment Workers Federation (NGWF), Femnet, and the
                European Center for Constitutional and Human Rights (ECCHR), have filed a complaint with the German
                Federal Office for Economic Affairs and Export Control (Bafa) four months after the German Supply Chain
                Act came into force. The complaint is based on NGWF's investigation in Bangladesh, which revealed safety
                deficiencies and other labour rights violations such as the lack of union freedom.
                <br/> <br/> 📜Generally, concerns remain about the progress made about working conditions in the garment
                industry in Bangladesh. In an interview with Professor Rashedur Chowdhury from the University of Essex,
                who grew up in Bangladesh and has conducted over 400 interviews with those impacted by the disaster, he
                suggests that if avoiding vast compensation payouts was the goal of companies sourcing from Bangladesh,
                this has been frequently achieved. Many brands were eager to become signatories of safety agreements in
                the wake of the tragedy to "cool down the outcry about compensation," he claims. Nazma Akter, of the
                Awaj Foundation who started her career as a worker in the garment sector, reports that progress has been
                made on issues such as building safety and unionization over the last 10 years in the Bangladesh garment
                industry. "Before Rana Plaza there were hardly any unions, and factory owners were not aware of building
                safety issues. Since then, we have seen safety increase in every factory and buildings are checked for
                problems," she said. However, Akter notes that right after the incident, there was still an unwritten
                direction from the government not to allow union registration. This suggests that while some progress
                has been made, there is still work to be done to improve worker rights and safety in the Bangladesh
                garment industry.
                <br/> <br/> Plus, Regulations in the field of HRDD such as the Lieferkettensorgfaltspflichtengesetz
                guide companies to a level playing field where taking responsibility for their social and their
                environmental impact ideally over time becomes the standard.
            </>
        }, {
            imageSrc: "/images/blog/rana_place_remembrance/good-clothes-fair-pay.png",
            title: "How to take action?",
            description: <>
                💸 Donate money: If you have the resources, donate money. For example to Awaj Foundation, which was
                established in 2003 to protect workers' rights in Bangladesh's Ready-Made Garment sector. They raise
                awareness of the issues facing garment workers, train them on their rights, and build their capacity to
                negotiate for better working conditions. Gender equity and addressing gender-based violence are one of
                the main priorities of the organization. Awaj Foundation's work has expanded to address the diverse
                range of needs of workers, including health services, education for their children, and support for
                those migrating for work abroad. They provide support to over 740,000 workers in 12 major industrial
                clusters in Dhaka and Chittagong Divisions. ➡️Donate here:
                <a href="http://awajfoundation.org/get-involved/donate/">http://awajfoundation.org/get-involved/donate/</a>
                <br/> <br/> 📝 Sign petitions: It couldn’t be easier in nowadays day and age you just have to click a few
                buttons to sign a petition that can have an impact. You can take action by signing these two petitions:
                <br/> <br/> Good Clothes Fair Pay is a European Citizens’ Initiative that aims to require brands and
                retailers in the garment sector to conduct specific due diligence in their supply chain to ensure
                workers are paid living wages. The initiative calls for a time-bound and target-bound plan to close the
                gap between actual and living wages, with a particular emphasis on identifying risk groups such as women
                and migrant workers. The campaign must collect at least 1 million signatures from EU citizens to call
                directly on the European Commission to propose legislation in this area. If successful, this legislation
                would be the first living wage legislation for garment workers worldwide at the EU level. ➡️ Sign here:
                <a href="www.goodclothesfairpay.eu/">www.goodclothesfairpay.eu/</a>
                <br/> <br/> The European Union has proposed a law to turn corporate accountability into a reality, but
                the campaign "Justice is Everybody's Business" is asking for help to stand up against corporate lobbyist
                attempts to weaken the proposal. The goal is to create a fairer world where businesses respect the
                rights of workers, communities, and the planet, and where people can get justice if they have been
                harmed by bad business. The campaign aims to defend rules that make business more just and that work for
                everyone. ➡️Sign here: <a href="https://justice-business.org/">https://justice-business.org/</a>
                <br/> <br/> 🎙️Listen and learn:
                <br/> The podcast episode of Fashion revolution Germany features Lavinia Muth, (un)sustainability
                consultant and advocate for a more ethical and social textile industry. The episode commemorates the
                10th anniversary of the Rana Plaza building collapse in Bangladesh that killed over 1,100 textile
                workers and injured thousands more. Lavinia discusses the progress made in the textile industry since
                the tragedy, and the work that still needs to be done to ensure a sustainable and just future for
                textile workers worldwide. <a
                href="https://open.spotify.com/episode/03kZpxgDhKESyLXhtMCG9b?si=d4b870be28e4431b">https://open.spotify.com/episode/03kZpxgDhKESyLXhtMCG9b?si=d4b870be28e4431b</a>
            </>
        }, {
            imageSrc: "/images/blog/rana_place_remembrance/10-years-rana-plaza-never-again.png",
            title: "Thoughts about our work at atlat",
            description: <>
                The Rana Plaza tragedy was a wake-up call for the garment industry and consumers alike. In the years
                since the disaster, efforts have been made to improve safety and working conditions in the Bangladesh
                garment industry. This tragedy brought to light the dangerous working conditions and lack of workers'
                rights in the fashion industry. It is time for the fashion industry to prioritize sustainability,
                safety, and social responsibility, and actually listen to workers and unions in doing so.
                <br/> <br/> Our ambition at atlat is to use our digital skills, our privileges, and our connections, and
                offer a digital grievance system that allows workers to report grievances, problems, and worries they
                may have, for example regarding unsafe working conditions, harassment, or unpaid wages. By identifying
                these issues early, and working with local stakeholders, NGOs, and workers' unions to resolve them
                before they escalate into larger problems or catastrophes. We recognize that we are building a platform
                and connection, yet have no lived insights into factory life on the other side of the planet.
                <br/> <br/> ➡️That is why we rely on stakeholder involvement while doing research, giving training, or
                remediating grievances to ensure that our work actually benefits workers in the garment industry. That
                is why we aim to give our best to test our approach with workers, to train workers on their rights and
                on grievance channels, to include involved parties in remediation processes. That is why we understand
                that it takes the collective efforts of everyone involved to make real progress toward a safer and
                fairer industry.
                <br/> <br/> Let's work together to create a world where the clothes we wear are made with dignity and
                respect for the people who make them.
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Rana Plaza Remembrance</HeadingTitle>
                        <HeadingDescription>
                            Ten years ago, on April 24, 2013, the Rana Plaza factory in Bangladesh collapsed, killing
                            over 1137 people and injuring thousands more. In the decade since the tragedy, efforts have
                            been made to improve safety in the garment industry, including the Accord on Fire and
                            Building Safety in Bangladesh. Just a few months earlier, the Tazreen Fashion factory had
                            caught fire, killing over 100 workers. These tragedies put a spotlight on the unsafe working
                            conditions and low wages in the garment industry. It surely served as a turning point for
                            the textile and garment industry.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
