import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-80 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/trees_and_lake.jpg",
            title: "Why sustainability?",
            description: <>
                <p> A sustainable supply chain is not merely about planting more trees or reducing
                    air pollution. It covers every aspect of the supply chain, end-to-end. This
                    includes the source of the raw materials, the carbon footprint of the company,
                    wastage from internal processes, the amount of recyclable material, etc.
                    Many companies still hesitate to switch to a sustainable supply chain,
                    considering it a mere PR exercise with no real value. This couldn't be more
                    wrong.</p>
                <br/>
                <p> Discussed below are the four factors that will justify how companies benefit
                    from sustainable supply chains:</p></>
        },

        {
            imageSrc:
                "https://images.unsplash.com/photo-1532726635173-491f83dcce3c?ixlib==rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
            title: "1. Boost in reputation",
            description: "The brand reputation of any company is linked to how the general public\n" +
                "perceives it. Sustainability has emerged as an important factor in that\n" +
                "perception. Companies with unsustainable practices like deforestation or\n" +
                "water pollution almost always see a hit to their reputation. On the other hand,\n" +
                "businesses with a sustainable supply chain receive better coverage than any\n" +
                "PR campaign."
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1524435974157-2014a7e8a61c?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "2. Increased customer loyalty",
            description: "Selling the cheapest product is not the only criteria anymore. Building brand\n" +
                "loyalty is a painstaking process, and sustainable supply chain has proved to\n" +
                "be invaluable in that process. When businesses exhibit how their operations\n" +
                "are sustainable, it is always received positively by their customers. As\n" +
                "mentioned in the previous point, sustainability is linked directly to brand\n" +
                "reputation; it is also linked to customer loyalty in the same way."
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1459257831348-f0cdd359235f?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "3. Reduced costs",
            description: <> <p>Cost has almost been one of the primary driving factors in any business. For
                the longest time, sustainable practices were rejected because they apparently
                cost more. However, this is now known to be untrue. Of course, the initial
                transition to a sustainable supply chain will be a costly affair. However, in the
                long run, sustainable practices tend to lower the overall cost of operations. Not
                to mention, it will also save money in the carbon tax and other similar taxes
                placed by governments in most countries.</p></>
        }, {
            imageSrc:
                "https://images.unsplash.com/photo-1593377201811-4516986cbe41?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "4. Drives innovation",
            description: <> <p>Necessity inspires us to do innovation. Though the above-discussed factors
                justify enough benefits for the transition of companies towards sustainable
                supply chains, what if the efficiency suffers, the costs increases or speed
                decreases? To tackle such problems, we need innovation. Many businesses
                that switched towards a sustainable supply chain found innovative solutions to
                deal with the new challenges. It only proves that adopting a sustainable supply
                chain will also make your business more innovative.</p></>
        }, {
            imageSrc:
                "https://images.unsplash.com/photo-1465343161283-c1959138ddaa?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "Bottom Line:",
            description: <> <p>A sustainable supply chain might not be the money-spinning idea that most
                companies desire. But it offers real benefits to companies while also
                massively helping the environment.</p></>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Why Sustainable Supply Chain is Good for Companies?</HeadingTitle>
                        <HeadingDescription>
                            <p> In recent years, there has been a renewed focus on businesses turning
                                toward a sustainable supply chain. The emphasis on sustainable practices is
                                not new, with many governments and industry compliances already in place.
                                However, today the consumers themselves are demanding companies to be
                                more sustainable in their approach.</p>
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>

                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Erfahren Sie mehr
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
