import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/human_rights_day/human-rights-day-by-the-united-nations.png",
            title: "The Universal Declaration of Human Rights",
            description: <>
                “All human beings are born free and equal in dignity and rights.” This is the first article of the
                Universal Declaration of Human Rights (UDHR).
                <br/> <br/>
                Under the impressions of the Second World War and the atrocities of National Socialism, it was adopted
                by the United Nations (UN) on December 10, 1948. At the General Assembly in Paris, 48 states voted in
                favor of the declaration, while eight countries abstained.
                <br/> <br/>
                The document defines the rights that every single person should be entitled to, regardless of factors
                such as origin, gender, religion or political conviction. The 30 articles include the right to life, the
                right to the absence of slavery and torture, and the right to freedom of religion. Equality before the
                law and freedom of assembly are also demanded for all people.
                <br/> <br/>
                The Universal Declaration of Human Rights has no binding status under international law. To this day,
                however, it can be seen as the most important basis of modern human rights legislation globally.

            </>
        }, {
            imageSrc: "/images/blog/human_rights_day/article-25-declaration-of-human-rights.jpg",
            title: "Status quo",
            description: <>
                Fire disasters, or environmental catastrophes, or persecution of journalists or trade unionists: despite
                the many international agreements, serious human rights violations continue to occur around the world.
                Even 71 years after the Universal Declaration of Human Rights was passed, human rights are still being
                grossly violated in many countries.
                <br/>
                <br/> People who are forced to leave their homeland because of war or other disasters are often affected
                by human rights violations. But the consequences of climate change also violate human rights.
            </>
        }, {
            imageSrc: "/images/blog/human_rights_day/atlat-post-about-human-rights-day.png",
            title: "Human rights violations in global supply chains",
            description: <p>
                Human rights violations in the global supply chain have long been recognized as a major problem.
                According to a 2018 report by the International Labor Organization (ILO) and the Walk Free Foundation,
                nearly 40.3 million people are employed in what can be described as modern slavery. Trade policy must
                ensure good work and fair and sustainable globalization. That is why we are convinced that global supply
                chains must be provided with clear rules, fair competition and with holistic protection measures for
                human rights - not only in Germany, but at the European level.
            </p>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Human Rights Day</HeadingTitle>
                        <HeadingDescription>
                            On December 10, 1948, the Universal Declaration of Human Rights was promulgated by the
                            General Assembly of the United Nations. Human rights organizations all over the world
                            dedicate this day annually to remind that these rights are violated again and again around
                            the globe. ⚖️
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
