import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-144 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "https://images.unsplash.com/photo-1584962119638-92a71b026dee?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "What’s the GREENSTYLE munich responsible fashion summit?",
            description: <>
                <p>GREENSTYLE munich is a fair fashion fair & conference for consumers, trade visitors and the media.
                    This format is aimed not only at the specialist audience but also at the end consumer. Change must
                    come from all directions.The GREENSTYLE munich fair and conference looks into a common future with
                    creative approaches, innovative materials and the change of consciousness.
                </p><p> We want to share with you some of our insights and summarize the most interesting talks for our
                project.
            </p></>
        },

        {
            imageSrc: "https://images.unsplash.com/photo-1598432440412-06a75b3e737b?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "The amazing work of femnet",
            description: <>
                <p>We learnt a lot during the talk by Aika-Mares Fischbeck from FEMNET. Their work in the areas of
                    labour and human rights is really important, impactful and really impressive. It is divided in the
                    three pillars: Campaigns and political participation, education and information, and on site work
                    for women’s solidarity.</p>
                <p>The story of Angelica Manola from Romania especially impressed us, who was paid below Romania's
                    minimum wage of 278€ per month. She protested against it and has been fired as a result of that. But
                    she wasn’t put down by that and bravely posted her salary check on social media. The post went
                    viral, the Clean Clothes Campaign and also the brands that were producing in the factory got aware
                    of it. This public awareness created enough pressure on the factory to employ Angelica Manola again
                    and to pay her and her colleagues the rightful minimum wage. This example shows how everyone can
                    create change connected, and how impactful it can be to speak up and create awareness, when people
                    are treated unrightfully.</p>
                <p> Support FEMNET and find out more on their website: <a href="https://femnet.de/en/">femnet.de</a>
                </p>
            </>
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1517840545241-b491010a8af4?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "What’s the Green Button for?",
            description: <p>
                The discussion between Ulrich Plein, program lead for the Green Button at the GIZ, and Andri Stocker,
                co-founder of sustainable fashion brand Phyne, moderated by Thimo Schwenzfeier from Neonyt was really
                interesting. The Green Button seal of approval is an opportunity for companies and consumers, which has
                been initiated by the German Federal Ministry for economic Cooperation and Development. It’s the first
                state initiative of a meta-seal to offer consumers clarity in the jungle of seals and labels. The Green
                Button combines social and environmental topics and differentiates between certifications for products
                and companies. One of the first members was PHYNE. The founder explained that for PHYNE joining the
                Green Button was also a strategic decision to support change in the fashion industry.
                <br/>
                Also the criticism regarding the Green Button has been discussed. Currently a “Grüner Knopf 2.0” is
                developed, which will go even beyond the standards of the current Green Button.
                <br/>
                Have a look at the modern and sustainable fashion from PHYNE: <a href="https://phyne.com/">phyne.com</a>
                <br/>
                Check out the NEONYT at Messe Frankfurt: <a
                href="https://neonyt.messefrankfurt.com/frankfurt/de.html">neonyt.messefrankfurt.com/frankfurt/de.html</a>
                <br/>
                Find out more about the Green Button: <a
                href="https://www.gruener-knopf.de/en">www.gruener-knopf.de/en</a>

            </p>
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1568675392503-1cf97bb81c23?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "Tchibo, women empowerment, & the WE program",
            description: <p>
                Lastly, we felt really inspired by the talk of Nanda Bergstein, Director Corporate Responsibility at
                Tchibo, about women empowerment and #metoo. She gave insights in workplaces / textile factories, and the
                methods Tchibo uses to improve the situation of local seamstresses and what we women (and men) in
                Germany can learn from them. She talked about her personal relation to the topic and the really
                impressive WE programm by Tchibo, that has been developed in cooperation with the GIZ and the BMZ. The
                WE Program’s purpose is to contribute to lasting improvement of working conditions in our supply chain.
                It is a dialogue based program that runs in factories all over the world. Key is that all parties in the
                business relationship are of value and are given the opportunity to speak up. In the course of the
                program workers and managers come together on a regular basis. Together they build mutual understanding,
                they hold courageous conversations on human rights in their factories and they co-create solutions to
                improve working conditions. She shared three tools that are used in the WE program and explained that
                change has to be derived through language, structures and relationships in factories. The story of one
                woman that Nanda Bergstein shared impressed us a lot: The woman was rather quiet and shy in the first
                workshops of the WE program. But over the time her behavior changed. She spoke up, she participated, she
                came alive. Her fiance was really not in favour of this development, so he wanted her to quit her job.
                Which is why, she went to the factory lead and told that she needs to leave because her fiance told her
                to do so. The factory lead, who has also changed in the course of the WE program, simply asked her, what
                she would do, if she has a choice. She promptly answered that she’d leave her fiance and keep her job.
                The factory lead answered it’s her choice, what she wants to do, and that the factory would support
                whatever decision she makes. Eventually she did leave her husband. She continued working at the factory,
                and today she’s an HR Manager at this factory. This story really depicts how much impact initiatives,
                such as the WE program have, and that’s why this work is so important.
                Nanda Bergstein made it clear that the system has to change and not the women. Moreover, she also stated
                that working on women empowerment is not a fight against men. Men are also often forcefully pushed into
                gender roles, which is why she ended her talk with a quote by Emma Watson: “Both men and women should
                feel free to be sensitive. Both men and women should feel free to be strong.”

                <br/>
                Events such as the GREENSTYLE munich responsible fashion summit are really important. They offer a
                platform to exchange learnings, insights and stories, but they can also give
                Yet, first and foremost events like the GREENSTYLE munich create a feeling of community and show you
                aren’t alone in the fight for a more sustainable, greener and fairer fashion world. Check out the
                website of GREENSTYLE munich, with useful tips, inspiring brands, and insightful articles:
                <a href="https://greenstyle-muc.com/">greenstyle-muc.com/</a>
            </p>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>True colors at GREENSTYLE munich</HeadingTitle>
                        <HeadingDescription>
                            Last week True Colors participated at an amazing remote event: The GREENSTYLE munich
                            responsible fashion summit, supported by Wildling barefoot shoes.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>

                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
