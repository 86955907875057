import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";
import {Follow} from 'react-twitter-widgets'

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-80 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/true_colors_team_working_agile_and_hacking.png",
            title: "Hackathon?! What’s that?",
            description: <>
                <p>The neologism of „Hack” and „Marathon” describes an event for soft- and hardware-enthusiastic
                    Individuals. Interdisciplinary teams met to work on innovative solutions for the digital future of
                    Tchibo. Goal of this cool event by Tchibo was not only technical hacking, but especially creative
                    thinking, experimenting and exploring ideas.</p></>
        },

        {
            imageSrc: "/images/true_colors_gewinnt_tchibo_hackathon.jpg",
            title: "Tchibo & True Colors: Match made in heaven",
            description: "We are happy that we could convince the jury of our digital solution to ensure human rights, sustainability, and fair working conditions along the supply chain.\n" +
                "Also, Tchibo is truly inspiring to us because the company is at the forefront of supporting the ‘Lieferkettengesetz’, a German law to ensure human rights along supply chains.\n" +
                "Teamwork makes the dream work. \n" +
                "We are proud of this great achievement and use the spirit to do our part in creating a world with fairer working conditions and sustainable supply chains.💚\n"
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1487611459768-bd414656ea10?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "Unser Devpost",
            description: <p>
                Sieh dir unsere Devpost-Seite an, wo der Hackathon organisiert wurde: <a
                href="https://devpost.com/software/true-colors-complaint-mechanism">devpost.com/software/true-colors-complaint-mechanism</a>
                <br/><br/>
                Kontaktiere uns unter: <a
                href="mailto:team@atlat.de">team@atlat.de</a>
                <br/><br/>
                <Follow username="truecolorsscm"/>
                #fairbylaw #thinkbig #tchibo #humanrights #sustainability #lieferkettengesetz #duediligence #truecolors
            </p>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>True Colors wins Tchibo Hackathon</HeadingTitle>
                        <HeadingDescription>
                            True Colors is very proud to announce: We are the winner of the Tchibo “THE NEXT DIGITAL
                            LEVEL HACKATHON 2021” in the category “THINK BIG SOLUTION”.🥳<br/><br/>
                            At the Tchibo Hackathon developers, creative idea generators, and designers met online to
                            create fresh and innovative ideas for Tchibo. The three best teams were awarded in three
                            different categories.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>

                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
