import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/overview-kendoka-bottom.png",
            title: "What’s “THE WORTHY WEB APP CHALLENGE” ?",
            description: <>
                The neologism of „Hack” and „Marathon” describes an event for soft- and hardware-enthusiastic
                Individuals. The purpose of the challenge was formulated pretty clearly by Progress: “Build a web app
                that will make the world a better place. It can be an app that impacts the world or a local community.
                Goodness no matter the scope is good.” And the rules were explained just as straightforward by saying
                the “app must include either a Telerik UI for Blazor, Kendo UI for Angular or KendoReact UI component.
                And it must do or promote goodness.”

            </>
        },

        {
            imageSrc: "/images/blog/worker-conditions-check-dashboard-screenshot.png",
            title: "Our Project",
            description: <>
                In a meeting with our pilot customer, the CSR manager said, “Your complaint mechanism is great, but we
                need to measure our impact.” If we can prove the positive impact on the workers' condition, we have a
                strong case to bring our tool to more companies, more suppliers and hence more workers. We took this
                challenge from our pilot partner to this hackathon: Let's measure our impact and make the results
                visible.
            </>
        },
        {
            imageSrc: "/images/blog/kendo-react-logo.png",
            title: "KendoReact",
            description: <p>
                For the automated messenger chat, we used the Conversational UI component of the KendoReact library. It
                was easy to set-up and we were even able without prior knowledge of the library to use a slider as a
                custom element in the toolbar. As a disclaimer, we did not build the database connection and the
                language adaption to our core product yet, since we first need the feedback and approval from our pilot
                project partners. However, since the Conversational UI component is easily connectable to Google
                Dialogflow, this will be no hurdle for us as soon as we get the final approval.
                <br/>
                For the dashboard, we used several of the charts components to visualize the data. Additionally, we
                applied the data query components to make a live selection of subsets available. This allows deep-dives
                into the data, e.g. the comparison of factories or the difference in the answers between genders. All
                data results can also be exported to Excel or as a PDF via the export and generator components. It all
                felt natural for us and was also well documented. Hence, besides achieving what we wanted to build, we
                even had the time to properly style it with our custom colors and fonts. We had no prior experience with
                KendoReact and hence learned how good the library is.
            </p>
        }, {
            imageSrc: "/images/blog/the-atlat-team-being-happy-about-winning.png",
            title: "Teamwork makes the dream work",
            description: <p>
                We are proud of this team achievement. We hope this tool for the measurement and the visualization of
                our impact can help us to roll out our main product, the complaint management mechanism, to more
                factories and workers. This brings us one step closer to our vision of fair and sustainable supply chains,
                where every complaint is heard. 💚
                <br/>  <br/>
                Check out our devpost, where the hackathon was organized:&nbsp;
                <a href="https://devpost.com/software/working-conditions-improvement-check">
                    devpost.com/software/working-conditions-improvement-check
                </a>
                <br/>
                Get in contact with us:&nbsp;<a
                href="mailto:team@atlat.de">team@atlat.de</a>

            </p>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>THE WORTHY WEB APP CHALLENGE</HeadingTitle>
                        <HeadingDescription>
                            Proud to announce: We are the winner of “THE WORTHY WEB APP CHALLENGE” by Progress in the
                            main category “Best Use of KendoReact” and by that won 7.500 USD.🥳
                            <br/>
                            <br/>
                            The hackathon took place from Apr 7 – May 24, 2021 and counted 1174 participants.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
