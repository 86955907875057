import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/research_portugal/user_research_atlat_portugal.png",
            title: "Idea, Methodology, Gratitude 🙏",
            description: <>
                We at atlat aimed to assess our complaints chatbot's acceptance in a Portuguese garment factory. Our
                further objectives included understanding worker interactions, gaining usability feedback, learning
                about alternative grievance awareness, digital literacy, and workers' rights familiarity. In our quest
                for understanding, we engaged in 115-minute semi-structured in-depth interviews with six dedicated
                workers from a garment factory in Portugal. These interviews not only allowed us to put the innovative
                atlat chat to the test but also enabled us to gain valuable insights into the grievance mechanism
                landscape, digital literacy, and workers' rights awareness. A heartfelt thanks to the research agency
                Tangivel, the outdoor fashion brand MONTREET, and the textile factory Campelo for their support in
                making this exploration possible.
            </>
        }, {
            imageSrc: "/images/blog/research_portugal/user_interview_portugal.png",
            title: "Feedback on the Complaints Chat 💬",
            description: <>
                Surprisingly, most workers admitted to skimming through the flyer provided by atlat, focusing primarily
                on eye-catching headlines and engaging illustrations. Yet, a unanimous appreciation was shown for the
                instructional video option, providing a clear and accessible guide for using the chatbot. Privacy
                concerns emerged as a key theme, with some participants hesitant to share ongoing conflicts with
                external entities. However, the allure of anonymous complaints managed to sway them. While most
                participants knew how to scan QR codes, a few opted for the URL input method. Interestingly,
                participants exhibited varied levels of familiarity with workers' rights. The promise of the complaints
                chat to amplify their workplace concerns was met with open arms, as reflected in Participant 3's
                sentiment: "If a person is revolted by things not happening, this platform might be useful to understand
                how to act or proceed accordingly to the law in that situation." The atlat chatbot received commendable
                feedback, as most participants comprehended its responses and expressed a readiness to utilize it again
                for raising concerns.
            </>
        }, {
            imageSrc: "/images/blog/research_portugal/user-research-portugal-wordcloud.png",
            title: "Other Complaints Mechanisms 🔄",
            description: <>
                Our study unveiled a diverse landscape of alternative complaint mechanisms among the participating
                garment workers. While some engaged in direct communication with superiors, others sought legal
                guidance. Intriguingly, two workers were aware of labor unions they could approach. The memories of
                negative experiences from previous workplaces, sometimes with help sought by unions, motivated these
                individuals to see potential value in the anonymously offered atlat chat for issue resolution.
                <br/>
                More comprehensive details can be accessed through the atlat member platform.
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Research in Portugal with Workers in a Garment Factory: Exploring a Digital
                            Grievance Mechanism</HeadingTitle>
                        <HeadingDescription>
                            We summarized our insights from a research study conducted in a Portuguese garment factory,
                            uncovering insights into the efficacy of our complaints chatbot, workers' feedback, and
                            other grievance possibilities.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
