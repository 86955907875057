import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

export default () => {
    const Subheading = tw.span`tracking-wider text-sm font-medium`;
    const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
    const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
    const Description = tw.span`inline-block mt-8`;
    const imageCss = tw`rounded-4xl`;
    return (
        <AnimationRevealPage>
            <Hero
                heading={<>Ensuring Health <HighlightedText>& Safety at Work</HighlightedText></>}
                description={<div>
                    According to the ILO, there are approximately 340 million occupational accidents every year worldwide,
                    leading to an estimated 2.3 million deaths annually. Operational Health and Safety (OHS) refers to the set
                    of practices and policies aimed at protecting workers from hazards and ensuring their well-being in the
                    workplace. It is a crucial aspect of compliance as failure to adhere to OHS standards can result in serious
                    accidents, injuries, and even fatalities. Digital solutions can help to streamline audits, increase
                    compliance, and ultimately save lives.
                    <br/>
                    <br/>
                    Jaanika Jelistratov worked as a health & safety specialist for 10+ years before becoming an OHS trainer and
                    CEO. She developed VITS, an online platform to manage health & safety processes efficiently.
                    <br/>
                    <br/>
                    Ahti Salumäe, a HSE manager with 15+ years of experience, aims to improve Estonian safety culture and
                    prevent incidents through proactive workplace improvements.
                </div>}
                imageSrc={"/images/webinars/ensuring-health-and-safety-at-work.png"}
                imageCss={imageCss}
                imageDecoratorBlob={true}
                primaryButtonText="Order Now"
                watchVideoButtonText="Watch the recording"
                watchVideoYoutubeUrl="https://www.youtube.com/embed/2qSpdvdt_cU"
            />
            <Footer/>
        </AnimationRevealPage>
    );
}
