import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {css} from "styled-components/macro"; //eslint-disable-line
import {SectionHeading, Subheading} from "../../components/misc/Headings.js";
import {PrimaryLink as PrimaryLinkBase} from "../../components/misc/Links.js";
import {PrimaryButton as PrimaryButtonBase} from "../../components/misc/Buttons.js";
import {ReactComponent as LocationIcon} from "feather-icons/dist/icons/map-pin.svg";
import {ReactComponent as TimeIcon} from "feather-icons/dist/icons/clock.svg";
import {ReactComponent as ArrowRightIcon} from "../../images/arrow-right-icon.svg";
import {useTranslation} from "react-i18next";
import Header from "../../components/headers/light";
import AnimationRevealPage from "../../components/helpers/AnimationRevealPage";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)(props => [
    tw`w-full xl:w-5/12`,
    props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0 xl:last:ml-auto`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`mt-4 xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardCompany = tw.div`text-primary-500 font-bold text-lg`;
const CardType = tw.div`font-semibold text-sm text-gray-600`;

const CardTitle = tw.h5`text-xl mt-4 mb-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-center sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4 mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;

const CardAction = tw.a`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 w-full mt-6`;

export default () => {
    const {t} = useTranslation(['products',]);
    const cards = [
        {
            imageSrc:
                "https://images.unsplash.com/photo-1589793463357-5fb813435467?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
            company: t('products:workingConditions', 'Working Conditions'),
            title: t('products:supplierCommunication', 'Communication and training for the workers down your supply chain'),
            url: "https://atlat.de/communication-and-training-for-suppliers-workers",
        },
        {
            imageSrc:
                "https://images.unsplash.com/photo-1593444285830-996c36463fe5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
            company: t('products:humanRights', 'Human Rights'),
            title: t('products:humanRightsPolicyGenerator', 'Software to create Human Rights Policies'),
            url: "https://atlat.de/human-rights-policy-generator",
        }
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Content>
                <ThreeColumn>
                    <HeadingColumn textOnLeft={false}>
                        <HeadingInfoContainer>
                            <Subheading>{t('products:ourPortfolio', 'Our Portfolio')}</Subheading>
                            <HeadingTitle><>
                                {t('products:sustainabilityTech', 'Sustainability Tech')} <span
                                tw="text-primary-500"> {t('products:toCoverAllYourNeeds', 'To Cover All Your Needs')}.</span>
                            </>
                            </HeadingTitle>
                            <HeadingDescription>{t('products:productsDescription', 'We at atlat are working on delivering a product suite to cover all your needs when it comes to sustainability tech.')}</HeadingDescription>
                            {/*<PrimaryLink>*/}
                            {/*    {t('products:viewAllProducts', 'View All Products')} <ArrowRightIcon/>*/}
                            {/*</PrimaryLink>*/}
                        </HeadingInfoContainer>
                    </HeadingColumn>
                    {cards.map((card, index) => (
                        <CardColumn key={index}>
                            <Card>
                                <CardImage imageSrc={card.imageSrc}/>
                                <CardText>
                                    <CardHeader>
                                        <CardCompany>{card.company}</CardCompany>
                                        {/*<CardType>{card.type}</CardType>*/}
                                    </CardHeader>
                                    <CardTitle>{card.title}</CardTitle>
                                    {/*<CardMeta>*/}
                                    {/*    <CardMetaFeature>*/}
                                    {/*        <TimeIcon/> {card.durationText}*/}
                                    {/*    </CardMetaFeature>*/}
                                    {/*    <CardMetaFeature>*/}
                                    {/*        <LocationIcon/> {card.locationText}*/}
                                    {/*    </CardMetaFeature>*/}
                                    {/*</CardMeta>*/}
                                    <CardAction as="a" href={card.url}>{t('products:learnMore', 'Learn More')}
                                    </CardAction>
                                </CardText>
                            </Card>
                        </CardColumn>
                    ))}
                </ThreeColumn>
            </Content>
        </AnimationRevealPage>
    );
};
