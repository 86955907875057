import React, {useEffect} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

export default () => {

    useEffect(()=>{
        window.location.href="https://bit.ly/3suWHI7"
    },[]);
    return (
        <AnimationRevealPage>
            <Footer/>
        </AnimationRevealPage>
    );
};
