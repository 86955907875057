import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import {Line, XAxis, YAxis, ResponsiveContainer} from 'recharts';
import Title from './Title';
import Bar from "recharts/lib/cartesian/Bar";
import ComposedChart from "recharts/lib/chart/ComposedChart";
import Legend from "recharts/lib/component/Legend";
import Tooltip from "recharts/lib/component/Tooltip";

// Generate Sales Data
function createData(time, amount, resolved) {
    return {"name": time, "Number of Interactions": amount, "Resolved Interactions": resolved};
}

const numberOfCommunications = [
    createData('31 Oct', 1, 3),
    createData('1 Nov', 2, 11),
    createData('2 Nov', 11, 3),
    createData('3 Nov', 8, 4),
    createData('4 Nov', 7, 6),
    createData('5 Nov', 9, 8),
    createData('6 Nov', 12, 7),
    createData('7 Nov', 2, 12),
    createData('8 Nov', 5, 3),
    createData('9 Nov', 0, 6),
    createData('10 Nov', 4, 5),
];


export default function Chart() {
    const theme = useTheme();

    return (
        <React.Fragment>
            <Title>Recent Interactions</Title>
            <ResponsiveContainer>
                <ComposedChart

                    data={numberOfCommunications}
                    margin={{
                        top: 16,
                        right: 16,
                        bottom: 0,
                        left: 24,
                    }}
                >
                    <XAxis dataKey="name" stroke={theme.palette.text.secondary}/>
                    <YAxis stroke={theme.palette.text.secondary}>
                        {/*<Label*/}
                        {/*    angle={270}*/}
                        {/*    position="left"*/}
                        {/*    style={{textAnchor: 'middle', fill: theme.palette.text.primary}}*/}
                        {/*>*/}
                        {/*    Number of Issues*/}
                        {/*</Label>*/}
                    </YAxis>
                    <Tooltip/>
                    <Legend/>
                    <Line type="monotone" dataKey="Number of Interactions" stroke={theme.palette.primary.main}
                          dot={false}/>
                    <Bar dataKey="Resolved Interactions" barSize={20} fill={theme.palette.secondary.main}/>
                </ComposedChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
