import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

export default () => {
    const Subheading = tw.span`tracking-wider text-sm font-medium`;
    const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
    const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
    const Description = tw.span`inline-block mt-8`;
    const imageCss = tw`rounded-4xl`;
    return (
        <AnimationRevealPage>
            <Hero
                heading={<>A practitioner's insight into <HighlightedText>handling grievances</HighlightedText></>}
                description={<div>
                    <p>
                        With the spreading of mandatory human rights due diligence, companies see themselves increasingly
                        challenged to establish or adapt grievance mechanisms (GM) for human rights adverse impacts along their
                        supply chains. In this scenario, adopting electronic-based channels - online or not – has become a
                        favored approach, as it allows for 24h accessibility and facilitated translation, among other benefits.
                        Simultaneously, this path can add challenges from a rightsholder-centered perspective, as access to and
                        trust in technology varies greatly in the different territories where suppliers are located. Considering
                        these challenges, what are strategies forward for companies in effective grievances management? How can
                        the relationship between possibly affected persons and technology be an asset for both companies and
                        rightsholders? Building on her professional experience as a complaints handler in Brazil, Mariana will
                        share insights on grievance management, accessibility, and technology.
                        <br/>
                        <br/>
                        Mariana is a lawyer specializing in human rights and alternative dispute resolution. She is currently
                        working with Löning – Human Rights & Responsible Business under the German Chancellor Fellowship, funded
                        by the Alexander von Humboldt Stiftung. She focuses on the establishment and improvement of grievance
                        mechanisms for human rights adverse impacts along value chains, building on her experience working as an
                        alternative dispute resolution professional in a company-based remediation program with international
                        repercussions.
                    </p>
                </div>}
                imageSrc={"/images/webinars/practitioners-insights-into-grievance-handling.png"}
                imageCss={imageCss}
                imageDecoratorBlob={true}
                primaryButtonText="Order Now"
                watchVideoButtonText="Watch the recording"
                watchVideoYoutubeUrl="https://www.youtube.com/embed/h4_UdIqg8SU"
            />
            <Footer/>
        </AnimationRevealPage>
    );
}
