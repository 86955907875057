import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/fashion_changers/snapshot-of-fashion-changers-speakers.png",
            title: "What’s that?",
            description: <>
                So, the founders of Fashion Changers are Nina Lorenzen, Vreni Jäckle, and Jana Braumüller. On
                fashionchangers.de they tell about the dark side of the textile industry and also show how sustainable
                and fair fashion can work, with the aim not only to enlighten but also to inspire. But Fashion Changers
                isn't just an online magazine, it's a movement. The three pillars of the online platform are the
                magazine, events, and political involvement.
                <br/>On the opening evening of the Fashion Changers Conference, we met with other conference
                participants in Platte Berlin to think about cooperation instead of competition and to expand our
                networks. There were fashion designers, label founders, and content creators as well as representatives
                of NGOs and other startups.
                <br/>The following conference days really continued in the same spirit: For example, we were really
                impressed by all the things we learned at the workshop about Greenwashing by Lavinia Muth. She not only
                gave practical examples of the harm Greenwashing can do, but also invited everyone to reflect and
                unlearn myths that are commonly spread in the fashion industry.🧶
                <br/>This amazing opportunity to connect and meet fair fashion thought leaders without the thought of
                competition but with the aim to exchange ideas or even collaborate together on a shared vision gives a
                unique potential for change.
                <br/>So we couldn’t agree more with Scarlett Faißt, Feminism Ambassador at Wildling Shoes: “Collective
                movements have the potential to provide viable alternatives to the status quo. They show that there is
                another way.”
                <br/>The following two discussions are summarized, which had a big impact because we pondered a lot on
                them and discussed the thoughts and the stories that have been shared.
            </>
        }, {
            imageSrc: "/images/blog/fashion_changers/screenshot-fashion-changers-conference.png",
            title: "Everything (un)fair?",
            description: <>
                We were really happy that the conference started with a focus on fair production in the textile sector.
                The panel “Everything (un)fair? About the importance of fair production in the fair fashion industry”
                was a really important discussion with Anna Cavazzini, a member of the EU parliament for the Green
                Party, Miguel Maximo from TMR Fashion Clothing, and Kalpona Akter, from the Bangladesh Center for
                Workers Solidarity & Garment Association. The event was moderated by Anna Zeitler. Kalpona Akter really
                highlighted the fact that workers need the freedom to organize so they can negotiate with their
                factories. The fact that the factory Rana Plaza was certified by 5 parties shows how little some
                certificates can be trusted. For the possibility of change, she pointed out that workers need access to
                save grievance systems and in order for them to have a real impact, workers need access to remedy.
                Kalpona Akter continued by criticizing the fast fashion system that can only be successful because
                people are buying fast fashion. So she asked all consumers to reflect on their shopping behavior: “How
                many winter jackets did your grandma have?”. Miguel reflected on ethical production in Portugal.
                Whereas, Anna Cavazzini argued for the importance of a legal framework to achieve systemic change. She
                share her political perspective on the matter and explained that the current economic situation can
                absolutely be no reason for her to discontinue her efforts in Supply Chain Due Diligence policies. On
                the contrary, Anna Cavazzini said that these policies are even more needed in times like these because
                due diligence makes supply chains more resilient. Moreover, she pointed out that workers in global
                supply chains, for instance in the garment industry, are heavily influenced by inflation and oftentimes
                can't afford meals for themselves and their families anymore. Anna Cavazini suggested that a legislative
                piece missing in the current proposal for the EU Supply Chain Due Diligence Directive is an initiative
                against unfair business practices, as there are already well-working rules established for the
                agriculture sector. Kalpona Akter summarized her request to the attendees of the conference with the
                words: “Whatever your position is: please speak about the problems from your position and act
                accordingly! Things will not change if we don’t talk about it. That will make a difference.”
            </>
        }, {
            imageSrc: "/images/blog/fashion_changers/fashion-changers-conference-banner.png",
            title: "The end as a beginning",
            description: <>
                We also really appreciate the discussion about “The end as a beginning: How re-commerce concepts can be
                socially and economically successful within the framework of the circular economy”. Janis Künkler from
                reverse.supply, Yayra Abgofah from THE REVIVAL, and Ivana Perbi-Ohlheiser from WardROBE Affair discussed
                the importance of circular fashion and the horrible consequences of the current way of consuming and
                producing clothes. The session was moderated by Svenja Gräfen. The WardROBE Affair is a Berlin-based
                peer-to-peer fashion platform with the motto “RENT. REUSE. 'ROBE”. Whereas THE REVIVAL is a non-profit
                and community-led design initiative creating awareness and education through the upcycling of textile
                waste coming from the so-called Global North to Ghana, which is based in Accra, Ghana. The Berlin-based
                startup reverse.supply helps brands & retailers become more sustainable by enabling them to own the
                resale of their products. Yayra Abgofah explained the negative environmental impact of textile waste on
                Ghana. He shared the story of fishermen that find labels from fast fashion brands on the coast and in
                the sea, although those fast fashion brands don’t even have retail stores in Ghana. This really depicted
                how irresponsible it is to ship tons and tons of post-consumer waste from the so-called Global North to
                Ghana. Yayra Abgofah expressed “Recommerce and rental are one of the ways to address the problem.” - yet
                he added that “The way that fashion is consumed is the problem.” Janis Künker acknowledged that he
                cannot tackle with reverse.supply the totality of the fashion industry mess, but re-commerce is a step
                that also creates a business case for the brands. There is so much more to be done. Yet, technical
                solutions like reverse.supply, alternative offers like WardROBE Affair, and initiatives like THE REVIVAL
                are so important and showcase how to change the fashion industry can be changed for the better.
                <br/>Thanks to all the speakers for sharing their learnings and their stories with the Fashion Changers
                community. We were so impressed with the event that we became members at Fashion Changers right away and
                we look forward to the next events full of #FashionChangersVibes. 💛
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Fashion Changers Conference 2022</HeadingTitle>
                        <HeadingDescription>
                            We had the chance to take part in the Fashion Changers Conference 2022.💛
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
