import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/deuter_vietnam/Poster_Workers_Discussion_Vietnam.png",
            title: "Our software connects companies and workers around the world 🌍🇻🇳",
            description: <>
                In many supply chains, the human rights of employees are not protected.
                <br/> The pandemic has exacerbated the economic situation for many employees in the textile sector.
                <br/> In Vietnam, for example, wage inequality has increased sharply as a result of the corona
                lockdowns. Even before the COVID pandemic, factory workers in Vietnam often worked more than 50 hours of
                overtime per month to make a living, despite increases in minimum wages. Vietnam ranks well above the
                global average for overtime violations.
                <br/> We want to help companies like deuter to monitor compliance with employee rights in the deeper
                supply chain, and to improve working conditions in supplier companies beyond legal requirements. We are
                proud of the cooperation with deuter, which has been strongly characterized by a common understanding of
                values since the first exchange.
            </>
        }, {
            imageSrc: "/images/blog/deuter_vietnam/Discussion_Training_Vietnam.png",
            title: "By cooperating with atlat, deuter wants to take a closer look at the places where others look the other way.🧶",
            description: <>
                deuter Sport GmbH is a manufacturer of backpacks, sleeping bags, and accessories from Gersthofen near
                Augsburg in Germany. deuter already has many years of experience in responsible procurement and was
                awarded FairWear Leader status for the tenth time in 2021 and the German Sustainability Award in 2022.
                <br/> But especially in times of COVID lockdowns, it has become apparent that factory visits are not
                always possible without restrictions. atlat's digital complaints system enables communication with the
                factory workforce to be maintained - not only with direct but also with indirect suppliers.
                <br/> Marco Hühn, Head of Quality Management, Corporate Social Responsibility, and Repair Service at
                deuter Sport GmbH, says about the cooperation with atlat: “Since deuter can already show good results in
                monitoring employee rights at its direct production partners, it is now our aim is to check indirect
                suppliers even better in order to meet our responsibility as players in global supply chains. By
                efficiently entering complaints, we can respond quickly to take corrective actions. With atlat, this can
                certainly succeed and we are excited to see how the first pilot projects get off to a good start.”
            </>
        }, {
            imageSrc: "/images/blog/deuter_vietnam/Flyer&Phone_Vietnam.png",
            title: "Research Study: The workers come first at atlat. 💬",
            description: <>
                You wouldn’t believe how hard it is to contact factory workers in global supply chains! That’s why we
                are glad that we had the chance to talk about the protection of workers’ rights and about grievance
                systems to the people who know it best: Factory workers. In order to test the acceptance of the chat
                tool among workers in advance, a study was carried out with workers in cooperation with the social
                innovation agency Schoolab Vietnam.
                <br/>The aim of this research was to listen to the factory workers to understand their needs and
                problems better and test our grievance chat service and the corresponding informational material.
                Besides gaining a lot of actionable detail feedback and a generally more holistic understanding of the
                factory workers’ perspective, we also learned that:
                <br/>💬 Workers prefer digital grievance systems: The interviewed workers reported that when reporting
                grievances digitally, they are not so much afraid of their identity being disclosed compared to other
                grievance methods such as hotlines.
                <br/>💬 Workers prefer to stay anonymous: The interviewed workers reported that they really appreciate
                the possibility of not disclosing their names and prefer to stay anonymous if it is possible and makes
                sense.
                <br/>💬 Workers want to be briefly and transparently informed: The interviewed workers want to be
                informed about the whole complaint procedure, yet they prefer the information to be in a short and
                easily understandable manner.
                <br/>Furthermore, for example, workers expressed during the test a need for additional visual help with
                important steps in the chat. As a result, the communication of topics that require explanation in the
                chat is now also supported with animated illustrations. In the future, the atlat team will continue to
                ask for concerns and feedback from workers and align the design of the software accordingly.

            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Cooperation with deuter in Vietnam</HeadingTitle>
                        <HeadingDescription>
                            We are more than proud to announce: Our software is live in Vietnam! The backpack and
                            sleeping bag manufacturer deuter uses the digital complaints system by atlat in factories in
                            Vietnam. 🎉
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                        <ResponsiveVideoEmbed url="https://www.youtube.com/embed/tGkp6uLHhNs" tw="w-full"/>
                        <br/>
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
