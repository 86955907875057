import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import LogoImage from "../../images/atlat_full_logo.png";
import {Instagram, LinkedIn, Twitter, YouTube} from "@material-ui/icons";
import {ReactComponent as SvgDecoratorBlob1} from "../../images/svg-decorator-blob-9.svg";
import {useTranslation} from "react-i18next";
import MailchimpSubscribe from "../MailChimpSubscribe";
import {Link} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import {compose} from "recompose";
import {withFirebase} from "../Firebase";

const Container = tw.div`relative bg-primary-500 text-gray-100 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;

const SubscribeNewsletterColumn = tw(Column)`text-center lg:text-left w-full! lg:w-auto! mt-20 lg:mt-12`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto lg:mx-0 `;
const SubscribeText = tw.p`mt-2 lg:mt-6 text-sm font-medium text-gray-600`;

const Divider = tw.div`my-16 border-b-2 border-primary-400 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-24`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-400`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-4 last:mr-0`}
`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`;
const DecoratorBlob1 = tw(
    SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`;
const DecoratorBlob2 = tw(
    SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`;


const FiveColumnWithInputFormBase = ({firebase}) => {
    const {t} = useTranslation(['footer']);

    return (
        <Container>
            <Content>
                <SixColumns>
                    <Column>
                        <ColumnHeading>{t('footer:main', 'Main')}</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link to={ROUTES.BLOG} onClick={() => firebase.doLog("footer_blog")}
                                      tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">
                                    {t('footer:blog', 'Blog')}
                                </Link>
                            </LinkListItem>
                            <LinkListItem>
                                {/*TODO more precise link*/}
                                <Link to={ROUTES.CONTACT_US} onClick={() => firebase.doLog("footer_faqs")}
                                      tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">
                                    {t('footer:faqs', 'FAQs')}
                                </Link>
                            </LinkListItem>
                            <LinkListItem>
                                <Link to={ROUTES.ABOUT_US} onClick={() => firebase.doLog("footer_aboutUs")}
                                      tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">
                                    {t('footer:aboutUs', 'About Us')}
                                </Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <Column>
                        <ColumnHeading>{t('footer:product', 'Product')}</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link to={ROUTES.COMPLAINT_MECHANISM}
                                      tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">
                                    {t('footer:complaintMechanism', 'Beschwerdemechanism')}
                                </Link>
                            </LinkListItem>
                            <LinkListItem>
                                <a href="https://drive.google.com/drive/folders/1r17bf3wgqx-FDQKt1rVGnZHtNSzsYBDC"
                                   tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">
                                    {t('footer:press', 'Press')}
                                </a>
                            </LinkListItem>
                            <LinkListItem>
                                <Link to={ROUTES.ABOUT_US} onClick={() => firebase.doLog("footer_team")}
                                      tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">
                                    {t('footer:team', 'Team')}
                                </Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    {/*<Column>*/}
                    {/*    <ColumnHeading>{t('footer:press', 'Press')}</ColumnHeading>*/}
                    {/*    <LinkList>*/}
                    {/*        <LinkListItem>*/}
                    {/*            <Link to={ROUTES.CONTACT_US}*/}
                    {/*                  tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">*/}
                    {/*                {t('footer:logos', 'Logos')}*/}
                    {/*            </Link>*/}
                    {/*        </LinkListItem>*/}
                    {/*        <LinkListItem>*/}
                    {/*            <Link to={ROUTES.CONTACT_US}*/}
                    {/*                  tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">*/}
                    {/*                {t('footer:events', 'Events')}*/}
                    {/*            </Link>*/}
                    {/*        </LinkListItem>*/}
                    {/*        <LinkListItem>*/}
                    {/*            <Link to={ROUTES.CONTACT_US}*/}
                    {/*                  tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">*/}
                    {/*                {t('footer:stories', 'Stories')}*/}
                    {/*            </Link>*/}
                    {/*        </LinkListItem>*/}
                    {/*        <LinkListItem>*/}
                    {/*            <Link to={ROUTES.CONTACT_US}*/}
                    {/*                  tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">*/}
                    {/*                {t('footer:office', 'Office')}*/}
                    {/*            </Link>*/}
                    {/*        </LinkListItem>*/}
                    {/*    </LinkList>*/}
                    {/*</Column>*/}
                    <Column>
                        <ColumnHeading>{t('footer:legal', 'Legal')}</ColumnHeading>
                        <LinkList>
                            <LinkListItem>
                                <Link to={ROUTES.PRIVACY_POLICY} onClick={() => firebase.doLog("footer_gdpr")}
                                      tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">
                                    {t('footer:gdpr', 'GDPR')}
                                </Link>
                            </LinkListItem>
                            {/*<LinkListItem>*/}
                            {/*    <Link to={ROUTES.PRIVACY_POLICY} onClick={() => firebase.doLog("footer_privacyPolicy")}*/}
                            {/*          tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">*/}
                            {/*        {t('footer:privacyPolicy', 'Privacy Policy')}*/}
                            {/*    </Link>*/}
                            {/*</LinkListItem>*/}
                            {/*<LinkListItem>*/}
                            {/*    <Link to={ROUTES.TERM_OF_SERVICE}*/}
                            {/*          onClick={() => firebase.doLog("footer_termsOfService")}*/}
                            {/*          tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">*/}
                            {/*        {t('footer:termsOfService', 'Terms of Service')}*/}
                            {/*    </Link>*/}
                            {/*</LinkListItem>*/}
                            <LinkListItem>
                                <Link to={ROUTES.IMPRINT} onClick={() => firebase.doLog("footer_imprint")}
                                      tw="border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300">
                                    {t('footer:imprint', 'Imprint')}
                                </Link>
                            </LinkListItem>
                        </LinkList>
                    </Column>
                    <SubscribeNewsletterColumn>
                        <SubscribeNewsletterContainer>
                            <ColumnHeading>{t('footer:subscribeHeader', 'Subscribe to our Newsletter')}</ColumnHeading>
                            <SubscribeText>
                                {t('footer:subscribeText', 'Sign up to stay up to date with the latest news on how atlat can help your company to build sustainable supply chains.')}
                            </SubscribeText>
                            <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL} type="type2"/>
                        </SubscribeNewsletterContainer>
                    </SubscribeNewsletterColumn>
                </SixColumns>
                <Divider/>
                <ThreeColRow>
                    <LogoContainer>
                        <LogoImg src={LogoImage}/>
                        {/*<LogoText>atlat</LogoText>*/}
                    </LogoContainer>
                    <CopywrightNotice>&copy; {new Date().getFullYear()}&nbsp;atlat. {t('footer:allRightsReserved', 'All Rights Reserved')}.</CopywrightNotice>
                    <SocialLinksContainer>
                        <SocialLink href="https://www.linkedin.com/company/atlat/">
                            <LinkedIn/>
                        </SocialLink>
                        <SocialLink href="https://www.instagram.com/atlat_software/">
                            <Instagram/>
                        </SocialLink>
                        <SocialLink href="https://twitter.com/atlat_software">
                            <Twitter/>
                        </SocialLink>
                        <SocialLink href="https://www.youtube.com/@atlat6089">
                            <YouTube/>
                        </SocialLink>
                    </SocialLinksContainer>
                </ThreeColRow>
            </Content>
            <DecoratorBlobContainer>
                <DecoratorBlob1/>
                <DecoratorBlob2/>
            </DecoratorBlobContainer>
        </Container>
    );
};

const FiveColumnWithInputForm = compose(withFirebase)(FiveColumnWithInputFormBase);

export default FiveColumnWithInputForm;
