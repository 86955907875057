import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-5 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
    ${props => css`background-image: url("${props.imageSrc}");`}
    ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/csddd_in_danger/open_letter.png",
            title: "Das Rätsel der deutschen Enthaltung entwirren",
            description: <>
                Die kürzliche Ankündigung Deutschlands, sich beim EU-Lieferkettengesetz zu enthalten, hat eine
                Schockwelle durch die Europäische Union geschickt. Die Entscheidung, die am 7. Februar 2024 bekannt
                gegeben wurde, hat viele verwirrt und Sorgen über die Zuverlässigkeit der deutschen Bundesregierung und
                über die Auswirkungen auf Menschenrechte und Umweltschutz geweckt. Lassen Sie uns zum Kern der Sache
                vordringen und die wichtigsten Argumente rund um diese unerwartete Wendung erkunden.
            </>
        }, {
            imageSrc: "/images/blog/csddd_in_danger/lorenzen_quote.jpg",
            title: "Die Argumente - Ein genauerer Blick auf diese unerwartete Kontroverse",
            description: <>
                <p><i>3 Jahre Verhandlungen gegen de facto Stillstand</i></p>
                Drei Jahre intensiver politischer Verhandlungen führten zu einer nahezu finalisierten Entscheidung, die
                von der Kommission und dem Parlament genehmigt wurde, nur um jetzt potentiell am Widerstand einzelner
                Mitgliedsstaaten zu scheitern. Die deutsche Regierung, die bereits ein eigenes
                Lieferkettensorgfaltspflichtengesetz umgesetzt hat, steht als Beweis für die Machbarkeit einer solchen
                Gesetzgebung. Der Widerstand der FDP wirft jedoch Fragen zur Verhältnismäßigkeit ihrer Haltung und des
                Einflusses, den sie innerhalb der Bundesregierung ausüben, auf.
                <p><i>Öffentliche Meinung gegen politisches Taktieren</i></p>
                Überwältigende 75% der Deutschen unterstützen ein umfassendes Lieferkettengesetz, wie eine Studie des
                Bundesministeriums für wirtschaftliche Zusammenarbeit und Entwicklung (BMZ) von 2020 zeigt. Dies
                widerspricht der Position der FDP und verdeutlicht eine mögliche Diskrepanz zwischen politischer
                Vertretung und öffentlicher Meinung. Die Diskrepanz wirft Fragen zur Zuverlässigkeit Deutschlands als
                Partner bei der Wahrung von Menschenrechten und Umweltstandards auf.
                <p><i>EU-Lieferkettengesetz: Ein Spielwechsel für die globale Ethik</i></p>
                Während Deutschlands bestehendes Lieferkettengesetz ein Schritt in die richtige Richtung ist, erweitert
                das vorgeschlagene EU-Lieferkettengesetz den Anwendungsbereich, indem es für alle in der EU ansässigen
                Unternehmen einen harmonisierten und fairen Wettbewerb sicherstellt. Die globale Auswirkung einer
                solchen Gesetzgebung kann nicht überbetont werden, insbesondere angesichts der durch die
                COVID-19-Pandemie aufgedeckten Verwundbarkeiten von Beschäftigten in globalen Lieferketten. Das
                EU-Gesetz zielt darauf ab, die Verantwortung von einzelnen Verbrauchern auf Unternehmen zu verlagern und
                einen nachhaltigeren und ethischeren Ansatz im globalen Handel zu schaffen.
            </>
        }, {
            imageSrc: "/images/blog/csddd_in_danger/jaeckle_quote.jpg",
            title: "Der Aufruf zum Handeln - Vereint für eine verantwortungsbewusste Zukunft",
            description: <>
                <p><i>Wer wir sind und was wir fordern</i></p>
                Wir sind Bürger, zivilgesellschaftliche Organisationen und Unternehmen, die sich für
                verantwortungsbewusstes Unternehmertum einsetzen. Unser Appell richtet sich an Bundeskanzler Olaf
                Scholz, die SPD und die Grünen und drängt sie, ihr Versprechen aus dem Koalitionsvertrag zu erfüllen und
                am 9. Februar für das EU-Lieferkettengesetz zu stimmen. Die bevorstehende Abstimmung am 9. Februar 2024
                ist der Schlüssel zur Gestaltung der Zukunft der unternehmerischen Verantwortung für Menschenrechte und
                Umweltschutz.
                <p><i>CSDDD: Einst eigentlich ein Hoffnungsschimmer für die sozial-ökologische und zukunftsorientierte
                    Transformation der europäischen Wirtschaft</i></p>
                Das vorgeschlagene EU-Lieferkettengesetz bietet die Möglichkeit, bedeutende Fortschritte in Richtung
                einer ethischeren und nachhaltigeren Geschäftslandschaft zu machen. Im Gegensatz zu irreführenden
                Behauptungen steht das CSDDD im Einklang mit internationalen Standards, schafft Klarheit für Sektoren
                und Risiken und stellt ein faires Spielfeld für Unternehmen in der gesamten EU sicher. Die Annahme
                dieser transformative Gesetzgebung ist keine kurzfristige Modeerscheinung, sondern eine Notwendigkeit,
                um den vielfältigen Herausforderungen der globalen Wirtschaft zu begegnen.
                <p><i>Opposition mit Fakten und Logik entgegentreten</i></p>
                Die FDP-Spitze, scheint darauf abzuzielen, ideologisch motiviert den Status quo zu bewahren, und sich
                als “schützende Stimme der deutschen Wirtschaft” inszenieren zu wollen, anstatt notwendige
                Transformationen in Geschäftspraktiken in globalen Lieferketten zu akzeptieren. Die CSDDD verlangt
                nichts Unmögliches; es fordert Unternehmen auf, Gerechtigkeit und Umweltschutz aufrechtzuerhalten. Die
                Mehrheit (ca. 93%)der deutschen Unternehmen, wie in einer Handelsblatt-Studie festgestellt, steht hinter
                dieser Gesetzgebung und widerlegt die Vorstellung, dass sie den Interessen der Wirtschaft entgegensteht.

            </>
        }, {
            imageSrc: "/images/blog/csddd_in_danger/cta.png",
            title: " Ein entscheidender Moment für die ethisches Wirtschaften in Europa",
            description: <>
                Die bevorstehende Abstimmung über das EU-Lieferkettengesetz ist mehr als eine legislative Entscheidung;
                es ist ein entscheidender Moment für die ethischen Grundlagen europäischer Unternehmen. Als Bürger,
                Organisationen und Unternehmen müssen wir uns vereinen, um sicherzustellen, dass unsere Stimme gehört
                wird, und Regierungen dazu drängen, verantwortungsbewusste Richtlinien zu fördern, die zu einer
                nachhaltigen und gerechten globalen Wirtschaft beizutragen. Die Zeit zum Handeln ist jetzt, und die
                Zukunft der unternehmerischen Verantwortung hängt in der Schwebe.
            </>
        },


    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Die Schockierende Wendung in Deutschlands Haltung zum EU-Lieferkettengesetz
                        </HeadingTitle>
                        <HeadingDescription>
                            Die kürzliche Ankündigung Deutschlands, sich beim EU-Lieferkettengesetz zu enthalten, hat
                            eine Schockwelle durch die Europäische Union geschickt. Die Entscheidung, die am 7. Februar
                            2024 bekannt gegeben wurde, hat viele verwirrt und Sorgen über die Zuverlässigkeit der
                            deutschen Bundesregierung und über die Auswirkungen auf Menschenrechte und Umweltschutz
                            geweckt. Lassen Sie uns zum Kern der Sache vordringen und die wichtigsten Argumente rund um
                            diese unerwartete Wendung erkunden.
                            <br/>
                            <br/>

                            <b>Schreibe dem Bundeskanzler und fordere ihn auf, sich für das EU-Lieferkettengesetz
                                einzusetzen!</b>
                            <br/> <br/>
                            Schicke dem Kanzler eine E-Mail, da unsere Botschaft offensichtlich nicht angekommen ist.

                            Was du tun kannst?
                            Du kannst in nur wenigen Schritten eine E-Mail an den Kanzler verfassen und unseren offenen
                            Brief weiterleiten. Das Ziel ist, den Druck zu erhöhen, damit Olaf Scholz seine
                            Richtlinienkompetenz einsetzt und den Irrweg der FDP stoppt. Es ist jetzt entscheidend,
                            gemeinsam ein Signal für eine verantwortungsvolle europäische Wirtschaft zu setzen, die
                            klare Regeln für den Schutz von Menschenrechten und der Umwelt in Lieferketten etabliert.
                            Wie funktionierts?
                            Klicke auf “E-Mail an den Kanzler senden”, um dein E-Mail-Programm zu öffnen. Die E-Mail ist
                            schon bereit; füge nur deinen Namen hinzu und sende sie ab.
                            <div>
                                <br/>
                                <br/>
                                <PrimaryLink
                                    href="mailto:poststelle@bundesregierung.de-mail.de?subject=Dringende%20Bitte%3A%20Setzen%20Sie%20sich%20f%C3%BCr%20das%20EU%20Lieferkettengesetz%20(CSDDD)%20ein.&body=Sehr%20geehrter%20Herr%20Bundeskanzler%20Olaf%20Scholz%2C%0D%0A%0D%0Awir%2C%20eine%20Gruppe%20von%20B%C3%BCrger*innen%2C%20Organisationen%20und%20Unternehmen%2C%20appellieren%20dringend%20an%20Sie%2C%20Bundeskanzler%20Olaf%20Scholz%2C%20die%20deutsche%20Enthaltung%20zur%20EU%20Corporate%20Sustainability%20Due%20Diligence%20Directive%20zu%20beenden%20und%20den%20Koalitionsvertrag%20einzuhalten.%20Eine%20breite%20Mehrheit%20der%20Wirtschaft%20(93%25%20laut%20Handelsblatt)%20bef%C3%BCrwortet%20verbindliche%20Regeln%20auf%20EU-Ebene%20und%20laut%20dem%20Spiegel%20Magazin%20haben%2095%25%20der%20deutschen%20Unternehmen%20bereits%20Ma%C3%9Fnahmen%20zur%20Umsetzung%20der%20CSDDD%20ergriffen.%0D%0A%0D%0AEin%20JA%20zum%20EU-Lieferkettengesetz%20st%C3%A4rkt%20eine%20nachhaltige%20europ%C3%A4ische%20Wirtschaft%20%E2%80%93%20und%20sch%C3%BCtzt%20das%20Recht%20auf%20menschenw%C3%BCrdige%20Arbeit.%0D%0A%0D%0ABitte%20lesen%20Sie%20unseren%20offenen%20Brief%20an%20die%20Bundesregierung%2C%20den%20nach%20wenigen%20Stunden%20bereits%20%C3%BCber%20400%20Personen%20aus%20Zivilgesellschaft%2C%20Wirtschaft%2C%20NGOs%2C%20und%20Kunst%20unseren%20Appell%20unterzeichnet%20haben.%0D%0ABitte%20lesen%20Sie%20unseren%20offenen%20Brief%20unter%3A%20https%3A%2F%2Ffashionchangers.de%2Fdringender-offener-brief-eu-lieferkettengesetz%2F%0D%0A%0D%0AEin%20JA%20zum%20EU-Lieferkettengesetz%20st%C3%A4rkt%20eine%20nachhaltige%20europ%C3%A4ische%20Wirtschaft.%0D%0AMit%20freundlichen%20Gr%C3%BC%C3%9Fen%2C%0D%0A"
                                >E-Mail an den Kanzler senden</PrimaryLink></div>
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                        <div>
                            <iframe src="/images/blog/csddd_in_danger/Offener Brief.pdf" width="100%" height="2000px"/>
                        </div>
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
