import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import {SectionHeading, Subheading as SubheadingBase} from "../misc/Headings.js";
import {PrimaryButton as PrimaryButtonBase} from "../misc/Buttons.js";
import EmailIllustrationSrc from "../../images/email-illustration.svg";
import {useTranslation} from "react-i18next";
import {compose} from "recompose";
import {withFirebase} from "../Firebase";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.div`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

const ContactUsFormBase = ({firebase}) => {
    // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const {t} = useTranslation(['forms']);

    function send() {
        firebase.firestore.collection('mail').add({
            to: "team@atlat.de",
            message: {
                subject: "Message from Contact Us Form: " + subject + " from " + email,
                text: name + " sent the following message using the contact us form on our website: " + message,
                html: name + '<br>sent the following message using the contact us form on our website: <br>' + message,
            }
        }).then(() => {
            setEmail("");
            setMessage("");
            setSubject("");
            setName("");
            setOpen(true);
        });
        firebase.doLog("contact_form_used")
    }

    return (
        <Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {t('forms:contactUs.messageSent', 'Message was sent successfully.')}
                </Alert>
            </Snackbar>
            <TwoColumn>
                <ImageColumn>
                    <Image imageSrc={EmailIllustrationSrc}/>
                </ImageColumn>
                <TextColumn textOnLeft={true}>
                    <TextContent>
                        <Subheading>{t('forms:contactUs.subheading', 'Contact Us')}</Subheading>
                        <Heading>{t('forms:contactUs.heading1', 'Feel free to')} <span
                            tw="text-primary-500">{t('forms:contactUs.heading2', 'get in touch')}</span>&nbsp;
                            <wbr/>
                            {t('forms:contactUs.heading3', 'with us')}.</Heading>
                        <Description>{t('forms:contactUs.description', 'We are always happy to talk about how we can help your company to become more sustainable. Don\'t hesitate to reach out!')}</Description>
                        <Form>
                            <Input type="email" name="email" value={email}
                                   onChange={(event) => setEmail(event.target.value)}
                                   placeholder={t('forms:yourEmailAddress', 'Your E-mail Address')}/>
                            <Input type="text" name="name" value={name}
                                   onChange={(event) => setName(event.target.value)}
                                   placeholder={t('forms:fullName', 'Full Name')}/>
                            <Input type="text" name="subject" value={subject}
                                   onChange={(event) => setSubject(event.target.value)}
                                   placeholder={t('forms:subject', 'Subject')}/>
                            <Textarea name="message" value={message}
                                      onChange={(event) => setMessage(event.target.value)}
                                      placeholder={t('forms:yourMessageHere', 'Your Message Here')}/>
                            <SubmitButton type="submit" onClick={send}>{t('forms:send', 'Send')}</SubmitButton>
                        </Form>
                    </TextContent>
                </TextColumn>
            </TwoColumn>
        </Container>
    );
};

const ContactUsForm = compose(withFirebase)(ContactUsFormBase);

export default ContactUsForm;