import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import {css} from "styled-components/macro";
import {useTranslation} from "react-i18next";
import { useHistory } from "react-router-dom";

const SimpleForm = ({status, message, onSubmitted, tag, forward}) => {
    const {t} = useTranslation(['forms']);
    const history = useHistory();

    let input;

    function getTag() {
        if (!tag) return
        switch (tag) {
            case "webinar_1" :
                return 6344568;
            case "webinar_2" :
                return 6344572;
            case "webinar_hembach_1" :
                return 6450692;
            case "watch_webinar_recording" :
                return 6465454;
        }
    }

    const submit = () =>
        input &&
        input.value.indexOf("@") > -1 &&
        onSubmitted({
            EMAIL: input.value,
            tags: getTag()
        });

    if (!!forward && status === "success") {
        history.push(forward);
        return;
    }

    return (
        <>
            {status === "sending" && <div tw="text-blue-300">{t('forms:sendingInProgress', 'Sending...')}</div>}
            {status === "error" && (
                <div tw="text-red-300" dangerouslySetInnerHTML={{__html: message}}
                />
            )}
            {status === "success" && (
                <div tw="text-green-300" dangerouslySetInnerHTML={{__html: message}}
                />
            )}
            <div tw="relative max-w-md text-center mx-auto lg:mx-0" key={getTag()}>
                <input
                    ref={node => (input = node)}
                    type="email"
                    placeholder={t('forms:yourEmailAddress', 'Your E-mail Address')}
                    tw="sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500"
                />
                <button
                    tw="w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300"
                    onClick={submit}>{t('forms:subscribeToNewsletter', 'Subscribe')}
                </button>
            </div>
        </>
    );
};

export default SimpleForm;
