import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-144 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "https://images.unsplash.com/photo-1574871790477-55b0318b599b?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "Rana Plaza",
            description: <>
                On 24 April 2013 in the collapse of the Rana Plaza factory in Sabhar 1,138 human beings lost their lives
                and 2438 were injured. This is the worst factory collapse in the history of Bangladesh.
                <br/>
                The Rana Plaza building employed separate textile factories that manufactured for brands such as
                Benetton, Bonmaraché, Prada, Gucci, Versace, Moncler, the Children’s Place, El Corte Inglés, Joe Fresh,
                Mango, Matalan, Primark, and Walmart. Most of the victims were women, alongside with a number of
                children.
                <br/>
                One week after the horrible tragedy NGOs and retailers created a new Accord on Factory and Building
                Safety in Bangladesh. The previously created Accord has only been signed by Tchibo and PVH (Calvin
                Klein).
            </>
        },

        {
            imageSrc: "https://images.unsplash.com/photo-1470309864661-68328b2cd0a5?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "What has happened since then in the German Fashion Industry?",
            description: <>
                8 years after this awful tragedy, a lot of consumers, activists, NGO’s, and fashion companies have been
                demanding and creating change. In Germany the Textilbündnis of more than 80 fashion companies have been
                formed together with work councils and NGO’s. Through the Textilbündnis industry-wide standards and
                changes have been created.
                <br/>
                2019 the first state-owned seal was introduced: The Green Button. This meta-seal is an opportunity
                for companies and consumers, which has been initiated by the German Federal Ministry for economic
                Cooperation and Development. It’s the first state initiative of a meta-seal to offer consumers clarity
                in the jungle of seals and labels. The Green Button combines social and environmental topics and
                differentiates between certifications for products and companies. Moreover, the German Supply Chain Act,
                or Lieferkettengesetz, has been introduced, and it demands from companies to take responsibility. Last
                Thursday the Bundestag debated about the Supply Chain Act, and while there many points that might be
                changed about this law, it can also be seen as a great milestone for a more responsible, fairer and more
                sustainable globalized economy.

            </>
        },
        {
            imageSrc: "/images/blog/who-made-my-clothes.jpeg",
            title: "#WhoMadeMyClothes?",
            description: <p>
                We value every step towards that has been taken so far. But still, also in 2021 a lot of clothes are
                produced in unfair, irresponsible, and inhuman conditions.
                <br/> In March a textile factory in Egypt burned down. As a cause of the pandemic many workers in the
                garment industry not only lost their jobs, but also their livelihood. Many more examples could be listed
                here. The movement of Fashion Revolution Day asks: #WhoMadeMyClothes? Because the clothes we wear are
                only then fair, if human rights are protected, if no children have to work, and if the people who
                actually made our clothes have decent working conditions.
                <br/> We believe that digital grievance mechanisms that give all workers the chance to complain are a
                catalyst for a fairer and more sustainable fashion industry.
                <br/> That’s why we work on this project.
                <br/> And that’s why we ask: Who made my clothes?
            </p>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Fashion Revolution</HeadingTitle>
                        <HeadingDescription>
                            On the anniversary of the Rana Plaza tragedy, the Fashion Revolution Day marks the time when
                            people from all over the world come together to contribute to a better fashion industry.
                            Today marks its 8th anniversary. The goal of the movement: A global fashion industry that
                            values people over growth and profit and protects the environment.The world cannot allow
                            that this tragedy repeats itself.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
