import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-5 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
    ${props => css`background-image: url("${props.imageSrc}");`}
    ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/igep/igep_worker_polishing_stone.png",
            title: "There is a story behind every gravestone",
            description: <>
                Protecting human rights across textile supply chains creates significant challenges for many companies.
                Most gravestones sold in Germany come from Indian quarries, one might say. This is a sector that is
                often associated with harsh working conditions. Despite well-functioning control and audit systems in
                the industry, processes in the area of human rights due diligence can always be improved. A central
                element of human rights risk management can be a grievance mechanism. Their purpose is to enable
                employees to raise concerns about a company's activities at suppliers, for example, and offer remedial
                measures for those affected. The
                German Supply Chain Due Diligence Act (LkSG) requires companies to manage
                the impact of their supply chains on human rights and the environment and, among
                other things, to set up complaint procedures for their supply chains. However,
                effective implementation poses challenges for many companies. This is where we from atlat come in with
                our digital complaint system.
            </>
        }, {
            imageSrc: "/images/blog/igep/atlat_ceo_josua_ovari_presents_project_with_igep.png",
            title: "More efficient processes through a digital tool",
            description: <>
                While some companies find the Supply Chain Act too burdensome due to
                bureaucratic challenges and neglect the complaints procedure, atlat offers a
                pragmatic digital approach to gradually improve complaints processes along the
                supply chain.
                The atlat software enables people in global supply chains who are affected by
                grievances to submit complaints easily, anonymously and securely. The appropriate
                communication material is written in simple language and reaches the often young
                workforce in global production where they are most easily reached: on their
                smartphones. The associated platform enables companies to more easily provide
                redress, improve collaboration with a wide range of stakeholders, create automatic
                complaint reports and carry out targeted data analyses. Insights from the complaint
                data can be used for strategic planning to identify areas of the supply chain that
                require more monitoring or support.
                Ou co-founder and CEO Josua Ovari adds: “At atlat, we combine our expertise in digital software
                development with social impact. This project offers a great
                opportunity to make an impact in the tombstone sector.”
            </>
        }, {
            imageSrc: "/images/blog/igep/atlat_ceo_josua_ovari_signs_contract_with_igep.png",
            title: "First step pilot project in the gravestone industry",
            description: <>
                IGEP carries out audits and certifications in the gravestone industry, covering the entire value chain
                from quarrying to processing to shipping. IGEP also supports social projects to support disadvantaged
                children and young people. The IGEP audits are carried out by experienced IGEP employees.
                The first introductory visit is coordinated with manufacturers and buyers, and all subsequent inspection
                visits are unannounced. Learn more about IGEP: <a
                href="http://www.igeptrust.in/index.html">www.igeptrust.in</a>
                <br/>
                Complaints procedures are an ideal complement to audits, as they enable additional monitoring of working
                conditions that goes beyond insight into a specific point in time. In cooperation with atlat, the
                complaints procedure at IGEP is to be made more accessible and digitized from the ground up in order to
                identify human rights risks earlier.
                <br/>
                <a href="https://www.linkedin.com/in/ACoAAATv7_UBNPOdrvzC5n86fky6vZFZV4djzMo?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAAATv7_UBNPOdrvzC5n86fky6vZFZV4djzMo"
                   target="_blank">Dr. Dietrich Kebschull</a> from IGEP highlights the importance of the digital aspect:
                "We are happy to present this collaboration at the Stone+tec trade fair in Nuremberg. The ability to
                digitize complaint
                processes is a turning point in ensuring compliance with new regulations."
                <br/>
                The pilot project will be launched at five production sites in India and will include Hindi,
                English,
                and Tamil. In the next phase, additional languages will be integrated, additional production sites will
                be incorporated and the internal mechanisms on-site will be further strengthened to enable broader
                implementation in companies in the long term. atlat and IGEP are also in the process of planning
                collaborations in additional sectors.
                <br/>Sharda Subramaniam from IGEP adds: "This project offers a great opportunity to use digital software
                to
                have a real impact on employees and to benefit as a company from the data and feedback collected beyond
                compliance."
                <br/>
                <a href="https://drive.google.com/drive/u/0/folders/1_MKndbernbh9da_Oud6Dz0gLlSGgPJxx" target="_blank"> <b>Read the full
                    press release</b></a>


            </>
        },


    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Rock Solid Compliance:
                            IGEP improves standards for working
                            conditions in the Indian gravestone sector
                        </HeadingTitle>
                        <HeadingDescription>
                            The pilot project with IGEP and atlat aims to improve working conditions in India's
                            tombstone industry by using digital tools to amplify employees' voices. The cooperation has
                            been presented at the Stone+tec trade fair in Nuremberg.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
