import React, {Component} from 'react';

import {AuthUserContext} from '../Session';
import {withFirebase} from '../Firebase';
import MessageList from './MessageList';

class Messages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: '',
            loading: false,
            messages: [],
            limit: 5,
        };
    }

    componentDidMount() {
        this.onListenForMessages();
    }

    onListenForMessages = () => {
        this.setState({loading: true});

        this.unsubscribe = this.props.firebase
            .messages()
            .orderBy('createdAt', 'desc')
            .limit(this.state.limit)
            .onSnapshot(snapshot => {
                if (snapshot.size) {
                    let messages = [];
                    snapshot.forEach(doc =>
                        messages.push({...doc.data(), uid: doc.id}),
                    );

                    this.setState({
                        messages: messages.reverse(),
                        loading: false,
                    });
                } else {
                    this.setState({messages: null, loading: false});
                }
            });
    };

    componentWillUnmount() {
        this.unsubscribe();
    }

    onChangeText = event => {
        this.setState({text: event.target.value});
    };

    onCreateMessage = (event, authUser) => {
        this.props.firebase.messages().push({
            text: this.state.text,
            userId: authUser.uid,
            createdAt: this.props.firebase.serverValue.TIMESTAMP,
        });

        this.setState({text: ''});

        event.preventDefault();
    };

    onEditMessage = (message, text) => {
        this.props.firebase.message(message.uid).set({
            ...message,
            text,
            editedAt: this.props.firebase.serverValue.TIMESTAMP,
        });
    };

    onRemoveMessage = uid => {
        this.props.firebase.message(uid).remove();
    };

    onNextPage = () => {
        this.setState(
            state => ({limit: state.limit + 5}),
            this.onListenForMessages,
        );
    };

    render() {
        const {users} = this.props;
        const {text, messages, loading} = this.state;

        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <div>
                        {!loading && messages && (
                            <button type="button" onClick={this.onNextPage}>
                                More
                            </button>
                        )}

                        {loading && <div>Loading ...</div>}

                        {messages && (
                            <MessageList
                                messages={messages.map(message => ({
                                    ...message,
                                    user: users
                                        ? users[message.userId]
                                        : {userId: message.userId},
                                }))}
                                onEditMessage={this.onEditMessage}
                                onRemoveMessage={this.onRemoveMessage}
                            />
                        )}

                        {!messages && <div>There are no messages ...</div>}

                        <form
                            onSubmit={event =>
                                this.onCreateMessage(event, authUser)
                            }
                        >
                            <input
                                type="text"
                                value={text}
                                onChange={this.onChangeText}
                            />
                            <button type="submit">Send</button>
                        </form>
                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

export default withFirebase(Messages);
