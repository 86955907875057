import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {css} from "styled-components/macro"; //eslint-disable-line
import {ContentWithPaddingXl, Container} from "components/misc/Layouts.js";
import {SectionHeading as Heading, Subheading as SubheadingBase} from "components/misc/Headings.js";
import {ReactComponent as SvgDecoratorBlob1} from "images/svg-decorator-blob-7.svg";
import {ReactComponent as SvgDecoratorBlob2} from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
                    subheading = "Testimonials",
                    heading = "Customer's Review",
                    testimonials = [
                        {
                            imageSrc:
                                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
                            quote:
                                "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
                            customerName: "Charlotte Hale"
                        },
                        {
                            imageSrc:
                                "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
                            quote:
                                "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
                            customerName: "Adam Cuppy"
                        },
                        {
                            imageSrc:
                                "https://images.unsplash.com/photo-1580852300654-03c803a14e24?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=4.25&w=512&h=512&q=80",
                            quote:
                                "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.",
                            customerName: "Steven Marcetti"
                        }
                    ]
                }) => {
    return (
        <Container>
            <ContentWithPaddingXl>
                {subheading && <Subheading>{subheading}</Subheading>}
                <Heading>{heading}</Heading>
                <Testimonials>
                    {testimonials.map((testimonial, index) => (
                        <TestimonialContainer key={index}>
                            <Testimonial>
                                <Image src={testimonial.imageSrc}/>
                                <Quote>"{testimonial.quote}"</Quote>
                                <CustomerName>- {testimonial.customerName}</CustomerName>
                            </Testimonial>
                        </TestimonialContainer>
                    ))}
                </Testimonials>
            </ContentWithPaddingXl>

            <DecoratorBlob1/>
            <DecoratorBlob2/>
        </Container>
    );
};
