import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-80 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "https://images.unsplash.com/photo-1534055570715-46bdf089997d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=632&q=80",
            title: "So können Sie mit atlat die Kommunikation steuern:",
            description:
                "Sie können einfach auf Deutsch oder Englisch Trainings oder Formulare erstellen\n" +
                "Diese Inhalte werden von uns in die Landessprache Ihrer Zulieferer übertragen.\n",
        },
        {
            imageSrc: "/images/products/Truecolors_illustrationen-01.png",
            title: "Mitarbeiter der Zulieferer erreichbar machen",
            description:
                "Wir bringen Ihre Inhalte in ein Format, dass den Angestellten Ihrer Zulieferer eigenständig " +
                "zugänglich ist. Dies kann eine Smartphone-basierte Lösung sein, eine Website oder eine" +
                " Sprachlösung über Telefon."
        },
        {
            imageSrc: "/images/products/Truecolors_illustrationen-02.png",
            title: "Zugeschnitten auf ihre Zulieferer",
            description:
                "Ihr Zulieferer erhält je nach gewählter Lösung einen Link oder eine Telefonnummer, die er seinen " +
                "Angestellten zugänglich macht, beispielsweise durch einen Aushang in der Herstellungsstätte.\n"
        },
        {
            imageSrc: "/images/products/Truecolors_illustrationen-03.png",
            title: "Effektives interaktives Training",
            description:
                "Die Angestellte werden automatisch und interaktiv durch ein Training geführt, um zum Beispiel über " +
                "ihre Rechte und Möglichkeiten zur Verbesserung aufgeklärt zu werden."
        },
        {
            imageSrc: "/images/products/Truecolors_illustrationen-04.png",
            title: "Neue Insights aus Feedback und Reporting",
            description:
                "Sie erhalten aggregiertes Feedback darüber, ob und wie Ihre Inhalte bei den Angestellten vor" +
                " Ort ankommen. Unser automatisierter Ansatz überwindet hierbei Hürden wie Sprachunterschiede" +
                " oder Zeitzone.\n" +
                "Über Exportfunktionen können Sie auch transparent Bericht erstatten.\n"
        }
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Kommunikation und Training mit den Beschäftigten Ihrer Zulieferer</HeadingTitle>
                        <HeadingDescription>
                            Wir bieten Ihnen einen effizienten Kommunikationskanal zu den Angestellten Ihrer Zulieferer.
                            Dadurch können Sie positiven Einfluss auf die realen Herstellungsbedingungen Ihrer Produkte
                            nehmen und erfüllen bereits jetzt die Anforderungen des sich abzeichnenden
                            Lieferkettengesetzes. Dieses auch Sorgfaltspflichtgesetz genannte Vorhaben sieht die
                            Einführung eines Beschwerdemechanismus für Arbeiterinnen und Arbeiter von Zulieferern
                            deutscher Unternehmen im Ausland vor.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{card.description}</Description>

                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Erfahren Sie mehr
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
