import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/european-impact-hero-logo.png",
            title: "Impact Hero Finale",
            description: <>
                The ‘Impact Hero Finale’ took place on <b>Thursday 11 November 2021</b>. After the journey of the Impact
                Hero
                Lab we finally had the chance to pitch our progress. In the finale we competed with ten amazing final
                startups from the areas of Climate Neutrality, Sustainable Supply Chains, and Circular Economy. We are
                amongst the winners and made the third place. We congratulate the winner BEF! BEF is offering an
                outstanding online marketplace for carbon-offsetting and for fundraising green projects. The Impact Hero
                Finale was organized by Social Entrepreneurship BW and by COSI Center for Open Social Innovation. It was
                supported by corporate experts from VW, Audi, SAP and Microsoft, and funded by <a
                href="https://ecopreneur.eu">ecopreneur.eu</a>. Thanks to the organizers and supporters for making
                this amazing event possible! Check out the website: <a href="https://impact-hero.eu/">
                impact-hero.eu</a>.
            </>
        }, {
            imageSrc: "/images/blog/atlat-working-in-miro-during-impact-hero-lab.png",
            title: "Impact Hero Lab",
            description: <>
                We look back on the <b>six-month digital journey of the Impact Hero Lab</b>. It provided us an
                opportunity to learn from corporate experts, scientists, social entrepreneurs and get in contact with
                potential customers as well as impact investors. In this six-months acceleration programme participants
                gain real
                industry insights, work with corporate experts from VW, Audi, SAP and Microsoft in the field of supply
                chain, circular economy, climate neutrality. We attended several remote workshops with top notch
                industry keynote-speakers, and supportive and thought-provoking exercises about topics such as Need
                Analysis, Sustainable Procurement, Circular Economy, Communication and Storytelling, Impact Measurement,
                and Supply Chain Management. Throughout this journey we were mentored by our coach Stephan Jung from SAP
                who’s an Expert in Design Thinking. We want to thank Stephan a lot for his support. He consulted us
                during the six months in terms of strategy, gave hands-on feedback, and helped us to bring our social
                startup to the next step.
            </>
        },
        {
            imageSrc: "/images/blog/atlat-team-celebrates-third-place-in-european-impact-hero-finale.png",
            title: "Great Teamwork, because: Together Everyone Achieves More",
            description: <p>
                We are proud of this team achievement. We learned a lot during the past six month, developed our
                strategy, refined our brand and grew our business. Our ambition is to make software for fair supply
                chains with fair working conditions and this brings us one step closer to our vision of fair working
                conditions and sustainable supply chains. 💚

                <br/>
                Get in contact with us:&nbsp;<a href="mailto:team@atlat.de">team@atlat.de</a>

            </p>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>European Impact Hero Finale</HeadingTitle>
                        <HeadingDescription>
                            We are happy to announce that we are amongst the winners of the European Impact Hero Day.🥳
                            <br/>
                            <br/>
                            We made third place at the competition after a six-month journey of learning and growing.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
