import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-80 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "https://images.unsplash.com/photo-1495131292899-bc096577e8f5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=632&q=80",
            title: "Die menschenrechtliche Grundsatzerklärung ist der erste Schritt",
            description: "Der erste Schritt für ein Unternehmen, um zu zeigen, dass es seine menschenrechtliche Sorgfaltspflicht ernst nimmt, ist die Erstellung einer Grundsatzerklärung zur Achtung der Menschenrechte. Sie ist das erste von fünf Kernelementen der menschenrechtlichen Sorgfalt, wie sie vom Nationalen Aktionsplan vorgesehen sind.",
        },

        {
            imageSrc: "https://images.unsplash.com/photo-1559056961-a6b61993c0f9?ixlib==rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
            title: "Anerkannte Standards und Regularien",
            description: "In einer menschenrechtlichen Sorgfaltserklärung legt ein Unternehmen dar, dass es seine Verantwortung zur Erfüllung international anerkannter Menschenrechtsstandard anerkennt. Das sollten zumindest die Internationale Menschenrechtscharta und die ILO Kernarbeitsnormen sein. Dabei sollte man sich klar machen, dass die Erstellung einer menschenrechtlichen Sorgfaltserklärung ein dynamischer und kontinuierlicher Prozess ist. Sobald klar ist, welche Führungskräfte im Unternehmen die Verantwortung für die Erstellung einer menschenrechtlichen Sorgfaltserklärung tragen und Verantwortliche aus anderen Abteilungen des Unternehmens einbezogen wurden, kann mit der Erstellung begonnen werden. "
        },

        {
            imageSrc: "https://images.unsplash.com/photo-1509453721491-c3af5961df76?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "Minimaler Inhalt einer menschenrechtlichen Grundsatzerklärung",
            description: "Eine menschenrechtliche Sorgfaltserklärung sollte mindestens Folgendes umfassen:\n" +
                "Eine ausdrückliche Verpflichtung zur Einhaltung aller international anerkannten Menschenrechtsstandards - mindestens die\n" +
                "Internationale Menschenrechtscharta und die ILO Kernarbeitsnormen,\n " +
                "Ausdrücklich formulierte Erwartungen des Unternehmens an Personal, Geschäftspartner und andere relevante Parteien\n" +
                "Informationen darüber, wie das Unternehmen seine Verpflichtung umsetzen wird\n"

        }
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Generator für menschenrechtliche Sorgfaltserklärungen</HeadingTitle>
                        <HeadingDescription>
                            Der Brand in der Tazreen-Textilfabrik und der Einsturz des Rana-Plaza-Gebäudes haben ein
                            großes Medienecho und große Anteilnahme in der Bevölkerung hervorgerufen. Es wurde mehr als
                            deutlich, dass ein proaktiver Menschenrechtsansatz dringend nötig ist, um als Marke auf dem
                            Markt zu bestehen. Das zeigt sich am Beispiel Benetton, das Modeunternehmen wies zuerst jede
                            Verantwortung an dem Tod von über 1100 MitarbeiterInnen im Rana-Plaza-Gebäude von sich,
                            entschied sich dann aber nach massivem öffentlichen Druck, seine Strategie grundlegend zu
                            ändern. Die Frage nach der menschenrechtlichen Sorgfalt ist auf Seiten der Verbraucher
                            längst im Mainstream angekommen. Konsumenten achten mittlerweile explizit auf die
                            menschenrechtliche Sorgfalt die ein Unternehmen zur Schau stellt. </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{card.description}</Description>

                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Erfahren Sie mehr
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
