import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";
import video from "../../videos/atlat-happy-new-year-2022-video.mp4";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/happynewyear/atlat-team-working-together.jpg",
            title: "Rewind: The backstory ⏮",
            description: <>
                The four of us got to know each other personally in January 2020 in Berlin at the digital competition
                “Digital Shapers” organized by McKinsey, Bertelsmann, TUI, eventim, Handelsblatt and ZEISS. Our great
                collaboration, our shared social and entrepreneurial mentality and our shared commitment to
                sustainability were decisive for the fact that we would continue to work as a team after the
                competition.
                <br/> After a few weeks of research, we were accepted into the four-month “Climate Founders Incubator”,
                and we found the topics we want to work on workers rights and human rights in global supply chains.
                <br/> In the course of the discussion about the Supply Chain Act, we became aware of the enormous
                leverage effect of supply chains for sustainability. Child labor, forced labor, but also the basic
                exploitation of workers: inside and the environment are problems that have been known for a long time
                and can only be solved with a systematic approach. The great opportunity to make a positive contribution
                through an adjustment screw in a complex system motivated us to explore this area.
                <br/> We recognized an extraordinary potential for digitization in the complaint mechanism and an
                important catalyst for more transparency and fairness in global supply chains, so we developed our first
                prototypes.
            </>
        }, {
            imageSrc: "/images/blog/happynewyear/atlat-team-winning-a-prize.jpg",
            title: "Review: What happened in 2021 ⏮",
            description: <>
                2021 was a really successful year for us!<br/><br/>
                The supply chain law (Lieferkettensorgfaltspflichtengesetz) in Germany was passed.<br/>
                We iterated on our software by taking feedback from companies and workers into account and we developed
                our brand: atlat. So we convinced 2 major German Fashion brands to become our first pilot customers.
                What’s more: We won 4 prizes this year…
                1️⃣ <a
                href="https://tchibo-hackathon.devpost.com/?ref_content=default&ref_feature=challenge&ref_medium=portfolio">The
                NEXT BIG THING from Tchibo.</a>
                <br/><br/>

                2️⃣ <a
                href="https://progress-worthyweb.devpost.com/?ref_content=default&ref_feature=challenge&ref_medium=portfolio">The
                Worthy Web App Challenge by Progress.</a>
                <br/>
                3️⃣ <a href="https://impact-hero.eu/">The third place in the European Impact Hero Challenge.</a>
                <br/>
                3️⃣ <a
                href="https://www.de.digital/DIGITAL/Redaktion/DE/Gruenderwettbewerb/Artikel/Preistraeger/preistraeger21-1-atlat.html">The
                Gründungspreis by the Bundeswirtschaftsministerium.</a>
                <br/>
                Moreover, we also were part of the RWTH Ideation Program and the European Impact Hero Lab, and with that
                gained valuable entrepreneurial knowledge and practical insights.

                <br/>
                <br/>
            </>
        }, {
            imageSrc: "/images/blog/happynewyear/atlat-team-on-the-board.png",
            title: "Next: Our plan for 2022 ▶",
            description: <>
                Our first goal is to found our company as a legal entity. Afterwards, we will roll out our software with
                our pilot customers in factories in Pakistan and in Vietnam. With that we want to learn and enhance our
                software and our services even more, so that our service can have more impact: Supporting more
                customers, in more countries, and in more industries to ensure workers rights and to transform their
                supply chains from complaints to compliance.
                We look forward to creating an impact for better working conditions in supply chains all around the
                globe!
                <br/><b>We wish you a happy new year!</b> 🎉
            </>
        }, {
            imageSrc: "/images/blog/happynewyear/atlat-rewind-2021.jpeg",
            title: "Happy 2022!",
            description: <>
                <video width="750" height="500" controls>
                    <source src={video} type="video/mp4"/>
                </video>
            </>
        },


    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>HAPPY NEW YEAR!</HeadingTitle>
                        <HeadingDescription>
                            Thank you for accompanying us on our journey so far. We look forward to creating an impact
                            for better working conditions in supply chains all around the globe! <br/><b>We wish you a
                            happy
                            new year!</b> 🎉
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
