import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import Header from "../components/headers/light.js";
import Footer from "../components/footers/FiveColumnWithInputForm.js";

export default () => {
    return (
        <AnimationRevealPage>
            <Header/>
            <div className="calendly-inline-widget" data-url="https://calendly.com/kalindifonda/30min"
                 style={{"minWidth": "320px", "height": "630px"}}
            />

            <Footer/>
        </AnimationRevealPage>
    );
};
