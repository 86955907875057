import React from "react";
import tw from "twin.macro";
// eslint-disable-next-line
import {css} from "styled-components/macro";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import Hero from "../hero/TwoColumnWithInput";
import Features from "../features/ThreeColWithSideImage";
import MainFeature from "../features/TwoColWithButton";
import heroScreenshotImageSrc from "../../images/hero-screenshot-1.png";
import FeatureWithSteps from "../features/TwoColWithSteps";
import macHeroScreenshotImageSrc from "../../images/hero-screenshot-2.png";
import MainFeature2 from "../features/TwoColWithTwoHorizontalFeaturesAndButton";
import professionalIllustrationImageSrc from "../../images/professional-illustration.svg";
// import Pricing from "../pricing/ThreePlans";
import Testimonial from "../testimonials/TwoColumnWithImageAndRating";
import FAQ from "../faqs/SingleCol";
import GetStarted from "../cta/GetStarted";
import Footer from "../footers/FiveColumnWithInputForm";
import {ReactComponent as BriefcaseIcon} from "feather-icons/dist/icons/briefcase.svg";
import {ReactComponent as DropletIcon} from "feather-icons/dist/icons/droplet.svg";
import {useTranslation} from "react-i18next";
import TeamCardGrid from "../cards/ProfileThreeColGrid";
import ThreeColSlider from "../cards/ThreeColSlider";

const Landing = () => {
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-primary-500`;
    const {t} = useTranslation(['features', 'mainFeature1', 'steps', 'mainFeature2', 'faqs', 'getStarted', 'testimonials']);

    return (
        <AnimationRevealPage>
            <Hero/>
            {/*<Features*/}
            {/*    subheading={<Subheading>{t('features:features', 'Features')}</Subheading>}*/}
            {/*    description={t('features:description', 'The atlat software is here to guide through all the' +*/}
            {/*        ' steps to ensure your supply chain is sustainable.')}*/}
            {/*/>*/}
            {/*<MainFeature*/}
            {/*    heading={<>{t('mainFeature1:by', 'Designed & Developed by')} <span*/}
            {/*        tw="text-primary-500">{t('mainFeature1:professionals', 'Professionals')}.</span></>}*/}
            {/*    subheading={<Subheading>{t('mainFeature1:header', 'Quality Engineering')}</Subheading>}*/}
            {/*    description={t('mainFeature1:description', 'The team behind atlat consists of experienced' +*/}
            {/*        ' professionals of the IT sector who combine all skills needed to deliver top notch digital' +*/}
            {/*        ' products. The software development happens inhouse to guarantee short and precise iterations.')}*/}
            {/*    primaryButtonText={t('mainFeature1:learnMore', 'LearnMore')}*/}
            {/*    // TODO use router*/}
            {/*    primaryButtonUrl="https://atlat.de/about-us"*/}
            {/*    imageSrc={heroScreenshotImageSrc}*/}
            {/*    imageBorder={true}*/}
            {/*    imageDecoratorBlob={true}*/}
            {/*/>*/}
            {/*<FeatureWithSteps*/}
            {/*    subheading={<Subheading>{t('steps:header', 'Steps')}</Subheading>}*/}
            {/*    heading={*/}
            {/*        <>{t('steps:easyTo', 'Easy To')}&nbsp;*/}
            {/*            <HighlightedText>{t('steps:getStarted', 'Get Started')}.</HighlightedText>*/}
            {/*        </>*/}
            {/*    }*/}
            {/*    textOnLeft={false}*/}
            {/*    imageSrc={macHeroScreenshotImageSrc}*/}
            {/*    imageDecoratorBlob={true}*/}
            {/*    decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}*/}
            {/*/>*/}
            {/*<MainFeature2*/}
            {/*    subheading={<Subheading>{t('mainFeature2:header', 'Our Values')}</Subheading>}*/}
            {/*    heading={*/}
            {/*        <>{t('mainFeature2:weAlwaysAbideByOur', 'We Always Abide by Our')}&nbsp;*/}
            {/*            <HighlightedText>{t('mainFeature2:principles', 'Principles')}.</HighlightedText>*/}
            {/*        </>*/}
            {/*    }*/}
            {/*    description={t('mainFeature2:description', 'Our Goal is to help companies be more sustainable. In' +*/}
            {/*        ' the first step, we want to achieve that goal by providing exceptional IT services that enable' +*/}
            {/*        ' companies to regain control over their supply chain and to make sustainable decisions that are' +*/}
            {/*        ' good for their business as well as our planet.')}*/}
            {/*    imageSrc={professionalIllustrationImageSrc}*/}
            {/*    showDecoratorBlob={false}*/}
            {/*    features={[*/}
            {/*        {*/}
            {/*            Icon: DropletIcon,*/}
            {/*            title: t('mainFeature2:sustainabilityHeader', 'Sustainability'),*/}
            {/*            description: t('mainFeature2:sustainabilityText', 'We deeply care about our planet and the lives living on it.'),*/}
            {/*            iconContainerCss: tw`bg-green-300 text-green-800`*/}
            {/*        },*/}
            {/*        {*/}
            {/*            Icon: BriefcaseIcon,*/}
            {/*            title: t('mainFeature2:expertiseHeader', 'Expertise'),*/}
            {/*            description: t('mainFeature2:expertiseText', 'We know how to support the processes to improve your supply chain with digital products.'),*/}
            {/*            iconContainerCss: tw`bg-blue-300 text-blue-800`*/}
            {/*        }*/}
            {/*    ]}*/}
            {/*    primaryButtonText={t('mainFeature2:learnMore', 'LearnMore')}*/}
            {/*    primaryButtonUrl="https://atlat.de/about-us"*/}
            {/*/>*/}
            {/*<Pricing*/}
            {/*    subheading={<Subheading>Pricing</Subheading>}*/}
            {/*    heading={*/}
            {/*        <>*/}
            {/*            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>*/}
            {/*        </>*/}
            {/*    }*/}
            {/*    plans={[*/}
            {/*        {*/}
            {/*            name: "Personal",*/}
            {/*            price: "$17.99",*/}
            {/*            duration: "Monthly",*/}
            {/*            mainFeature: "For Individuals",*/}
            {/*            features: ["30 Templates", "7 Landing Pages", "12 Internal Pages", "Basic Assistance"]*/}
            {/*        },*/}
            {/*        {*/}
            {/*            name: "Business",*/}
            {/*            price: "$37.99",*/}
            {/*            duration: "Monthly",*/}
            {/*            mainFeature: "For Small Businesses",*/}
            {/*            features: ["60 Templates", "15 Landing Pages", "22 Internal Pages", "Priority Assistance"],*/}
            {/*            featured: true*/}
            {/*        },*/}
            {/*        {*/}
            {/*            name: "Enterprise",*/}
            {/*            price: "$57.99",*/}
            {/*            duration: "Monthly",*/}
            {/*            mainFeature: "For Large Companies",*/}
            {/*            features: ["90 Templates", "27 Landing Pages", "37 Internal Pages", "Personal Assistance"]*/}
            {/*        }*/}
            {/*    ]}*/}
            {/*/>*/}
            <Testimonial
                subheading={<Subheading>{t('testimonials:testimonials', 'Testiomials')}</Subheading>}
                heading={<>{t('testimonials:whatOurCustomerSay', 'What our customers say')}</>}
                description={t('testimonials:customerFromTheTextileIndustry', 'Customers from the textile industry')}
                testimonials={[
                    {
                        profileImageSrc: "/images/testimonials/deuter-Primary-Logo-Screen-Blue_sm.png",
                        quote: t('testimonials:quoteDeuter'),
                        customerName: "Marco Hühn",
                        customerTitle: "Head of Quality Management, Corporate Social Responsibility, and Repair Service at deuter Sport"
                    }
                ]}
            />
            {/*<FAQ*/}
            {/*    subheading={<Subheading>{t('faqs:header', 'FAQs')}</Subheading>}*/}
            {/*    heading={*/}
            {/*        <>{t('faqs:doYouHave', 'You have')}&nbsp;*/}
            {/*            <HighlightedText>{t('faqs:questions', 'questions')}?</HighlightedText>  </>*/}
            {/*    }*/}
            {/*    description={t('faqs:description', 'description')}*/}
            {/*    faqs={[*/}
            {/*        {*/}
            {/*            question: t('faqs:question1', 'Is atlat the right choice for my company?'),*/}
            {/*            answer: t('faqs:answer1', 'It definitively is. We are working together with you to make sure we find a fitting solution.'),*/}
            {/*        },*/}
            {/*    ]}*/}
            {/*/>*/}
            {/*<TeamCardGrid*/}
            {/*    subheading={<Subheading>{t('aboutUs:team.ourTeam', 'Contact Us')}</Subheading>}*/}
            {/*    heading={t('aboutUs:team.meetThePeople', 'Meet the people behind atlat')}*/}
            {/*    description={t('aboutUs:team.description', 'atlat is built by individuals with strong' +*/}
            {/*        ' expertise in IT and deep passion for the environment')}*/}
            {/*/>*/}
            <ThreeColSlider/>
            <GetStarted
                text={t('getStarted:text', 'atlat enables companies to shape sustainable supply chains.')}
                primaryLinkText={t('getStarted:getStarted', 'Get Started')}
                primaryLinkUrl="https://atlat.de/get-started"
                secondaryLinkText={t('getStarted:contactUs', 'Contact Us')}
                secondaryLinkUrl="https://atlat.de/contact-us"
            />
            <Footer/>
        </AnimationRevealPage>)
};

export default Landing;