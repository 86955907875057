import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/hackathon_expert_ai/atlat-uses-expert-ai-to-analyse-complaints.png",
            title: "What?",
            description: <>
                We are nerds at heart. Hence, we love to test out new technology. One playful way of doing so is
                hackathons. These events are the perfect match between “hacking” (basically coding, nothing illegal 🙂)
                and the endurance of a marathon. These hackathons are often hosted by companies, which develop
                technology and with the event want to encourage others to use their development.
                <br/> Thus, we were curious when we saw that expert.ai hosted a new hackathon called “Turn Language into
                Action: A Natural Language Hackathon for Good”. They provided various API-endpoints that revolved around
                the analysis of written text, e.g. finding information on certain topics like ESG or geolocation, but
                also about understanding the emotions transported within a text or also detecting hate speech.
            </>
        }, {
            imageSrc: "/images/blog/hackathon_expert_ai/atlat-wins-second-place-at-expert-ai-hackathon.png",
            title: "Our contribution",
            description: <>
                Our contribution to this hackathon is a prototype that shows what an automated understanding of what
                human rights complaints can look like. The input texts are example complaints. We use not just one but
                three AI-powered functions of expert.ai software: 1. The ESG analyzer helps to understand to what
                category the complaint belongs, e.g. if it is rather an environmental or a social issue. Categorization
                of complaints is helpful for companies to get a bigger picture of the risk profile in their supplier
                landscape. 2. Emotional and sentiment analysis make the emotions transferred in the text explicit for
                the handler. This could be useful to lower the language barrier even more. 3. The API can detect PII
                (personally identifiable information) in a text. We can filter these PII out to technically ensure the
                anonymity of the complainant. 🔍
                <dev/>Read more about our contribution on Devpost: <a
                href="https://devpost.com/software/understand-human-worker-rights-complaints">https://devpost.com/software/understand-human-worker-rights-complaints</a>

            </>
        }, {
            imageSrc: "/images/blog/hackathon_expert_ai/screenshot-expert-ai-turn-language-into-action-winner-announcement.png",
            title: "What now?",
            description: <>
                We are very proud that our effort was acknowledged by the jury. Thank you to Brian Munz and the team of
                expert.ai for granting us second place! 🏆
                <br/> Overall, there have been 520 participants, grouped into 46 projects for this hackathon, so quite
                the competition. We are by the way very happy for the group that finished ahead of us: PagePal is a
                project that uses emotional text analysis to support children with an autism spectrum disorder to
                understand the emotions transferred in books and stories. Such an amazing and creative idea. 🎉
                <br/> Next to the prize money, a part of the award is an exchange with expert.ai. We are curious to see
                if we can boost the fidelity of our prototype to make it an actual feature of our platform going
                forward. 👩‍💻
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Winner: Turn Language into Action</HeadingTitle>
                        <HeadingDescription>
                            Great news: Team atlat got awarded the second prize out of 520 participants at the “NLP
                            hackathon for Good”! 🎉
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
