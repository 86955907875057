import React from 'react';
import map from "./europewithlocationmarkers.png";

import Title from './Title';

export default function Deposits() {
    return (
        <React.Fragment>
            <Title>Locations</Title>
            <img width={300} height={300} src={map} alt={"Locations"}/>
        </React.Fragment>
    );
}
