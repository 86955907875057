import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/ispo_outdoor/ispo_outdoor_keynote.jpeg",
            title: "Embracing Collaboration 🤝",
            description: <>
                The OutDoor Fair in Munich emphasized the significance of collaboration and sustainability in the
                outdoor industry, as perfectly demonstrated by the Sustainability Hub and workshops organized by
                GreenroomVoice. The exhibiting projects indicate a shift in mindset among industry players, showing
                their commitment to sharing sustainability approaches. The growing recognition of the urgent need to
                address the climate crisis together makes this collaborative trend a promising opportunity for scalable
                positive change.
            </>
        }, {
            imageSrc: "/images/blog/ispo_outdoor/social_impact_at_ispo_outdoor.jpeg",
            title: "Sustainable Projects and Initiatives 💚",
            description: <>
                The Sustainability Hub showcased various projects and initiatives aimed at reducing environmental
                impact, improving working conditions, creating more circular outdoor products, and overall promoting
                sustainability.
                <br/>
                For instance, Sympatex presented recyclable membranes without PFAS, which positions them advantageously
                amid a possible European ban on PFAS and growing pressure for recyclability. Furthermore, VAUDE,
                showcased measures for the transition to a circular economy by redesigning outdoor gear for waste
                prevention and recycling. With the textile industry currently recycling only one percent of used
                clothing, VAUDE's engagement in showcasing new practices from cradle to gate is welcomed for promoting
                circularity and sustainable design. Additionally, Globetrotter's Re:Think store in Bon has been
                presented. The store built on the basis of circularity exemplifies a commitment to environmental
                consciousness and showcases the potential of sustainable retail practices.
                <br/>
                Moreover, myclimate as a trusted partner in global and local climate protection presented its advisory
                services, educational programs, and impactful climate protection projects. The Grüner Knopf, or "Green
                Button," presented their approach to ending the “label jungle for consumers” and assuring rigorous
                social and environmental standards that companies must meet to receive the certification. While the
                Partnership for Sustainable Textiles or Textilbündnis presented their goal is to improve social and
                environmental conditions throughout the textile sector through joint efforts of different kinds of
                stakeholders. Regarding social sustainability, the stand of the Social & Labor Convergence (SLC) was a
                clear highlight, where the representative of the SLC explained their approach to harmonizing social and
                labor standards in the apparel and footwear industry by bringing together brands, manufacturers, and
                other stakeholders.
                <br/>
                Lastly, the "Climate Fresque" has been present at the Sustainability Hub. Visitors had the chance
                to understand the climate crisis and the IPCC report better through the educational puzzle tool. It
                encourages people to learn about the interconnectedness of various climate-related factors and the
                impact of human actions on the environment.
            </>
        }, {
            imageSrc: "/images/blog/ispo_outdoor/atlat_ceo_networks_at_ispo_outdoor.jpeg",
            title: "Finding a balance ⚖️",
            description: <>
                While celebrating collaboration and sustainability, it's crucial to examine the balance between these
                values and sales-driven motives. The outdoor industry must not only focus on introducing
                climate-friendly and circular products but also address social sustainability more. That means equally
                communicating on supply chain transparency, working conditions, and their impact on workers’ rights.
                <br/>
                Thanks, Outdoor by ISPO for connecting different stakeholders in the outdoor industry, and thanks for
                the good discussions with the great people I met.
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Reflections on the OutDoor by ISPO</HeadingTitle>
                        <HeadingDescription>
                            The OutDoor by ISPO in Munich showcased the importance of collaboration and sustainability
                            in the outdoor industry. However, amidst the positive developments, it's crucial to
                            critically examine green claims.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
