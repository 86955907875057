import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/news_eu_due_diligence_directive/strengthened-eu-supply-chain-act.jpg",
            title: "The EU Parliament strengthens several points of the CSDDD.",
            description: <>
                With 19 votes against 3 and 3 abstentions, the Legal Affairs Committee in the European Parliament has
                agreed to strengthen the EU Supply Chain Act, paving the way for the plenary vote. This is a success for
                human rights and the climate. 🇪🇺
                <br/> <br/> These are the key points:
                <br/> <br/> 📜PRECEDENT: The proposals put forward by the committee are being viewed as a significant
                milestone that could set a crucial precedent for the forthcoming vote on the EU “Supply Chain Law” or
                Corporate Sustainability Due Diligence Directive which is scheduled to take place in on Jun 1, 2023.
                <br/> <br/> 🔄VALUE CHAIN EXTENSION: The members of parliament want to include not only suppliers but
                also activities related to sale, distribution, and transport. This means an extension from Adverse
                impact would have to be mitigated and remedied by adapting the company’s business model, providing
                support to SMEs, or seeking contractual assurances.
                <br/> <br/> 🔍DIFFERENT THRESHOLDS: The members of parliament want to extend the application of the new
                rules, compared to the Commission proposal, to include EU-based companies with more than 250 employees
                and a worldwide turnover higher than 40 million euro, as well as parent companies over 500 employees and
                a worldwide turnover higher than 150 million euro. The rules would also apply to non-EU companies with a
                turnover higher than 150 million euro if at least 40 million was generated in the EU. There are no more
                specific risk sectors mentioned in the proposal.
                <br/> <br/> 💚 CLIMATE-RELATED DUE DILIGENCE: According to the members of the parliament, All companies
                falling under the scope of the law must create and implement climate transition plans that align with a
                1.5°C world and align with disclosures required by the Corporate Sustainability Reporting Directive
                (CSRD). This can be seen as a strong commitment to the Paris Agreement and to tackling the climate
                crisis with corporate due diligence.
                <br/> <br/> 💰 FINES: The update now calls for fines of at least 5% of net worldwide turnover, which is a
                significant increase. Non-compliant companies should be liable for damages and EU governments would
                establish supervisory authorities with the power to impose sanctions. The members of parliament want
                fines to be at least 5% of the net worldwide turnover and to ban non-compliant third-country companies
                from public procurement.
                <br/> <br/> 💬GRIEVANCE MECHANISMS: Grievance mechanisms were and still are an important pillar of the
                law. Companies would have to engage with people affected by their actions, including human rights
                defenders and environmental activists, introduce a grievance mechanism, and monitor the effectiveness of
                their due diligence policy.
                <br/> <br/> 💬The committee vote, rapporteur Lara Wolters (S&D, NL) noted: "I'm delighted that a broad
                consensus has been achieved in the committee to put forward binding rules to make business respect
                people and the planet. There is a clear will to align this directive with international best practices,
                and to ensure companies must do due diligence in continuous dialogue with those affected by harm, and
                remedying it when it occurs. If companies don't comply, they should face sanctions, and if harm occurs
                that they should have avoided, then victims should be able to get justice in court."
            </>
        }, {
            imageSrc: "/images/blog/news_eu_due_diligence_directive/who-is-affected-by-the-csddd.png",
            title: "Reactions to the proposed EU Supply Chain Law",
            description: <>
                The Initiative Lieferkettengesetz, consisting of a group of stakeholder representatives and NGOs, has
                criticized the weakening of the proposed law because the committee has proposed that companies will not
                be directly liable for damages caused by their foreign subsidiaries.
                <br/> These are the key criticisms:
                <br/> 🔴Companies would not be held directly liable for damages caused by their foreign subsidiaries.
                <br/> 🔴The burden of proof would solely lie with the claimants.
                <br/> 🔴The reference to central international agreements regarding biodiversity and world heritage
                protection appears to have been removed.
                <br/> 🔴Due diligence obligations would only apply to direct large customers, excluding investments in
                smaller projects that involve human rights violations.
                <br/> 🔴The proposal to regularly review existing risks has been weakened.
                <br/> 🔴The Committee assigns an important role to auditors and certifiers, although in the past in
                certain cases they failed to identify important human rights risks.
                <br/> 💬“We welcome the fact that the legal committee is following the proposals of the OECD and is
                presenting independent environmental obligations for companies. But it is completely incomprehensible
                that the reference to central international agreements - for example with regard to the protection of
                biodiversity and the world's natural heritage - was apparently arbitrarily deleted. Objective policies
                geared to the ecological limits of the planet are different,” says Cornelia Heydenreich, Team Leader for
                Corporate Responsibility at Germanwatch.
            </>
        }, {
            imageSrc: "/images/blog/news_eu_due_diligence_directive/is-the-verdict-in.jpg",
            title: "What now?",
            description: <>
                🇪🇺In conclusion, the EU is making strides towards more sustainable and responsible global supply chains
                with the EU CSDDD.
                <br/> ⚡What are the next steps? The approval is set to be executed in the plenary of the European
                Parliament on 1 June, and we're optimistic about the continued ambition and positive momentum leading up
                to this and during trilogue later this year. The ultimate goal is to protect human rights and the
                environment while making supply chains more resilient. The proposed fines and ban on non-compliant
                third-country companies from public procurement demonstrate the EU's commitment to this cause. What is a
                quite positive outlook is that: To aid in compliance, national helpdesks will be set up, and detailed
                guidelines will be provided by the Commission, as the WIMR in Germany is doing an amazing job in
                supporting companies to implement the German Supply Chain Act or LkSG /
                Lieferkettensorgfaltspflichtengesetz.
                <br/> ⚡If you want to learn more about the CSDDD, the LkSG, the UNGDP, and how they relate from a legal
                perspective: Join our webinar with human rights lawyer Holger Hembach on 30.05.23 from 10.30 - 11.30 PM
                CET to learn more about these important developments.
                <br/> ➡️RSVP now: <a href="https://www.eventbrite.com/e/where-to-start-with-all-the-hrdd-regulations-ungp-lksg-csddd-compared-tickets-623368581207">This webinar aims to compare the CSDDD, LkSG, and UNGP from a legal perspective to
                provide actionable legal insights for businesses.</a>
                <br/> <br/> Find out more: ➡️<a
                href="https://www.europarl.europa.eu/news/en/press-room/20230424IPR82008/corporate-sustainability-firms-to-tackle-impact-on-human-rights-and-environment">https://www.europarl.europa.eu/news/en/press-room/20230424IPR82008/corporate-sustainability-firms-to-tackle-impact-on-human-rights-and-environment</a>
                <br/> ➡️<a
                href="https://www.suedwind-institut.de/aktuelles/eu-lieferkettengesetz-cdu-setzt-im-eu-parlament-verw%C3%A4sserung-durch.html">https://www.suedwind-institut.de/aktuelles/eu-lieferkettengesetz-cdu-setzt-im-eu-parlament-verw%C3%A4sserung-durch.html</a>
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>News from the EU Corporate Sustainability Due Diligence Directive</HeadingTitle>
                        <HeadingDescription>
                            The Legal Affairs Committee of the European Parliament has today adopted its position on the
                            EU Supply Chain Law. The Committee's proposals are considered groundbreaking for the EU
                            Supply Chain Law vote in the European Parliament on June 1.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
