import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/european-impact-hero-logo.png",
            title: "Impact Hero Day",
            description: <>The ‘Impact Hero Day’ took place on <b>Tuesday 25 May 2021</b>. It’s a one-day online-event
                for 30
                European ventures dedicated to <b>climate neutrality, circular economy and supply chain</b> – thereby
                making a
                sustainable contribution to responsible business practices and operations. The Impact Hero Day was
                organized by Social Entrepreneurship BW and by COSI Center for Open Social Innovation. It was supported
                by ecopreneur.eu, the University of Mannheim, the University of Heidelberg, Volkswagen Group, Audi AG,
                SAP SE, and the Microsoft Corporation.
            </>
        },

        {
            imageSrc: "/images/blog/atlat-working-and-brainstorming-remote-in-teams-and-miro.png",
            title: "The Challenge\n",
            description: <>
                Every venture applied with <b>one personal challenge</b> they were currently facing. During the ‘Impact
                Hero
                Day’, these challenges were addressed by interdisciplinary teams – composed of industry expert
                volunteers, student volunteers, one team coach and at least two venture representatives. We explored the
                topic of <b>trust</b> with the workers in the global supply chain and asked ourselves: How might we
                create
                <b>trust & willingness to contribute</b> to a complaint mechanism for workers? During that we were
                coached by
                Stephan Jung from SAP who’s an Expert in Design Thinking. We want to thank Stephan for his kind support,
                his innovative ideas, and his thought-provoking questions.
            </>
        },
        {
            imageSrc: "/images/blog/the-atlat-team-celebrates-winning-at-european-impact-day.png",
            title: "Impact Hero Lab",
            description: <p>
                The Impact Hero Lab is a <b>six-month digital programme</b> that provides individual support. It is an
                opportunity for Social Ventures to learn from corporate experts, scientists, social entrepreneurs and
                get in contact with potential customers as well as impact investors. In this six-months acceleration
                programme participants gain real industry insights, work with corporate experts from VW, Audi, SAP and
                Microsoft in the field of supply chain, circular economy, climate neutrality and are guided by a mentor.
                As winners of the Impact Hero Day we are part of the Impact Hero Lab and look forward to <b>learning and
                creating impact</b>. Check out the website: <a href="https://impact-hero.eu/">impact-hero.eu</a>
            </p>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>European Impact Hero Day</HeadingTitle>
                        <HeadingDescription>
                            We are happy to announce that we are amongst the winners of the European Impact Hero Day.🥳
                            <br/>
                            <br/>
                            This means we are part of the Impact Hero Lab, a six-month digital programme that provides
                            individual support.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
