import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/huge_thing_demo_day_warsaw/huge-thing-google-for-startups-people-demo-day.jpg",
            title: "The Demo Day: A remarkable event",
            description: <>
                After over 12 weeks of remote workshops, our Co-Founder Josua traveled to Warsaw to pitch, to exchange
                with mentors, VCs, and with other startups. (And of course, we tried to travel as eco-friendly as
                possible by train. 🚆) What a privilege to have been one of twenty startups selected for the program out
                of 250 applications! And what a great chance to raise awareness for our social startup atlat and for the
                topics that move us: <b>#SDG8</b>, <b>#WorkingConditions</b>, <b>#HumanRights</b>,
                and <b>#DueDiligence</b> in global <b>#SupplyChains</b>!
            </>
        }, {
            imageSrc: "/images/blog/huge_thing_demo_day_warsaw/huge-thing-pre-acceleration-program-presentation.jpg",
            title: "The Program: Small steps lead to huge things!",
            description: <>
                The Huge Thing Pre-acceleration Online Program – which is being organized in partnership with Google for
                Startups – is designed for pre-seed and seed-stage startups with a draft version of their MVP. The
                Program is targeted at startups from Europe which do business in fields such as education, medicine,
                gaming, sustainability, and other industries with global potential.
                <br/>
                The Program’s objective is to validate business ideas and prepare startups for their first investment
                round. It provides startup founders with tools and knowledge which make it possible for them to build
                strong and profitable businesses. The Program is non-grant and equity-free.
            </>
        }, {
            imageSrc: "/images/blog/huge_thing_demo_day_warsaw/josua-from-atlat-with-mentor-giselle-frederick.jpg",
            title: "Thank you.",
            description: <>
                Thanks so much to the organizers of the event and of the whole program from Huge Thing, SpeedUp Venture
                Capital Group, and Google for Startups EU! Especially thanks to Agnieszka Cichocka and Mateusz Szymański
                for the amazing organization throughout the whole program! 🙏
                <br/>
                The biggest of the big thanks to Giselle Frederick for the mentoring during the last months, for the
                feedback, the ideas, the tough questions, and the moral support. Your mentorship really made a huge
                impact! We could not have wished for better mentoring during the program! 💚
                <br/>
                Also thank you to all the people sharing their knowledge and expertise in all the workshops about topics
                such as Sales Strategy, UVP, Pricing, or Pitching! Moreover, it was such a pleasure to exchange with the
                other startups and to meet most of the founders after all this time in person in Warsaw! We wish all the
                best to all of you and success for your startups!🤞
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Pitching at Google in Warsaw</HeadingTitle>
                        <HeadingDescription>
                            We presented atlat at Google Campus in Warsaw at the Huge Thing & Google for Startups
                            Pre-Acceleration Demo-Day! 🚀
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
