import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-80 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc:
                "https://images.unsplash.com/photo-1595111668829-9780aa1f1cab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=632&q=80",
            title: "Koalitionsvertrag sieht Lieferkettengestz vor",
            description:
                "Es wird im Bundestag heiß diskutiert. Es ist noch nicht abschließend debattiert wie" +
                " streng die Regeln im Detail werden, aber es ist klar: Das Lieferkettengesetz" +
                " kommt. Was heißt das für Sie und Ihr Unternehmen? Haben Sie Sich schon Gedanken" +
                " darüber gemacht, wie Sie den neuen Bestimmungen nachkommen können?",
        },

        {
            imageSrc:
                "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib==rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
            title: "Was bedeutet das Lieferkettengesetz für mich?",
            description:
                "Das Lieferkettengesetz wird kommen, das ist mittlerweile klar. Es beinhaltet die Pflicht, einen Sorgfaltsplan zu erstellen. Damit sollen die UN-Leitprinzipien Transparenz und Berichterstattung erfüllt werden. Es soll sichergestellt werden, dass Unternehmen sich tatsächlich mit möglichen Menschenrechtsverletzungen und Umweltschäden beschäftigen, Missstände aufdecken und angemessene Maßnahmen ergreifen.\n" +
                "\n" +
                "Für Unternehmen bedeutet das ganz konkret, dass die Erstellung unternehmensinterner Dokumentation immer wichtiger wird. Sie kann im Klagefall entlasten und nachvollziehbar machen, dass in Ihrem Unternehmen angemessene Maßnahmen ergriffen wurden.",
        },

        {
            imageSrc:
                "https://images.unsplash.com/flagged/photo-1558963675-94dc9c4a66a9?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
            title: "Was beinhaltet der Sorgfaltsplan?",
            description:
                "Darüber hinaus sollen Unternehmen mit dem Lieferkettengesetz dazu verpflichtet werden, einen Sorgfaltsplan zu erstellen und zu veröffentlichen. Der Sorgfaltsplan soll erläutern, wie ein Unternehmen seiner menschenrechtlichen und umweltbezogenen Sorgfaltspflicht konkret nachkommt.\n" +
                "\n" +
                "Behörden werden diesen Sorgfaltsplan, dessen Vollständigkeit und Einhaltung verfolgen und prüfen.",
        }
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Sorgfaltsplan für das Lieferkettengesetz</HeadingTitle>
                        <HeadingDescription>
                            Mit dem Lieferkettengesetz kommt die Pflicht zur Erstellung eines Sorgfaltplans. True Colors
                            kann Sie dabei unterstützen.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{card.description}</Description>

                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Erfahren Sie mehr
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
