import React from "react";
import tw from "twin.macro";
//eslint-disable-next-line
import {useTranslation} from "react-i18next";
import {PrimaryButton as PrimaryButtonBase} from "../misc/Buttons";
import {useHistory} from "react-router-dom";

const SubscribeForm = tw.div`mt-4 lg:mt-6 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`text-primary-900 bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200`;

const FooterForm = ({status, message, onSubmitted, tag, label, forward}) => {

    const {t} = useTranslation(['forms']);
    const history = useHistory();

    function getTag() {
        if (!tag) return
        switch (tag) {
            case "webinar_1" :
                return 6344568;
            case "webinar_2" :
                return 6344572;
            case "webinar_hembach_1" :
                return 6450692;
            case "watch_webinar_recording" :
                return 6465454;
        }
    }


    let input;
    const submit = () =>
        input &&
        input.value.indexOf("@") > -1 &&
        onSubmitted({
            EMAIL: input.value, tags: getTag()
        });

    if (!!forward && status === "success") {
        history.push(forward);
        return;
    }

    return (
        <>
            {status === "sending" && <div tw="text-blue-300">{t('forms:sendingInProgress', 'Sending...')}</div>}
            {status === "error" && (
                <div tw="text-red-300" dangerouslySetInnerHTML={{__html: message}}
                />
            )}
            {status === "success" && (
                <div tw="text-green-300" dangerouslySetInnerHTML={{__html: message}}
                />
            )}

            <SubscribeForm>
                <Input ref={node => (input = node)}
                       type="email"
                       placeholder={t('forms:yourEmailAddress', 'Your E-mail Address')}/>
                <SubscribeButton
                    onClick={submit}>{label ? label : t('forms:subscribeToNewsletter', 'Subscribe')}</SubscribeButton>
            </SubscribeForm>
        </>
    );
};

export default FooterForm;
