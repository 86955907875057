import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/csddd_in_danger/open_letter.png",
            title: "Unraveling the Question of Germany's Abstention",
            description: <>
                Germany's recent announcement of abstaining from the CSDDD Corporate Sustainability Due Diligence Directive, also know as EU Supply Chain Law, has sent shockwaves across the European Union. The decision, revealed on February 7, 2024, has left many puzzled and concerned about the implications for human rights and environmental protection. Let's delve into the heart of the matter and explore the key arguments surrounding this unexpected move.
            </>
        }, {
            imageSrc: "/images/blog/csddd_in_danger/lorenzen_quote.jpg",
            title: "The Weighty Arguments: A Closer Look at a unexpected Controversy",
            description: <>
                <p><i>3 Years of Negotiations vs. De Facto Stalemate</i></p>
                Three years of intense political negotiations resulted in a nearly finalized decision approved by the
                Commission and Parliament, only to be halted by the reluctance of individual member states. The German
                government, having already implemented its own Lieferkettensorgfaltspflichtengesetz, or Supply Chain
                Act, stands as a testament to the feasibility of such legislation. The FDP's, however, raises questions
                about the proportionality.
                <p><i>Public Opinion vs. Political Posturing</i></p>
                A resounding 75% of Germans support a comprehensive Lieferkettengesetz, as revealed by a 2020 study by
                the Bundesministerium für wirtschaftliche Zusammenarbeit und Entwicklung (BMZ). This contradicts the
                FDP's position, highlighting a potential misalignment between political representation and public
                sentiment. The discrepancy raises concerns about the reliability of Germany as a partner in upholding
                human rights and environmental standards.
                <p><i>EU Supply Chain Law: A Game-Changer for Global Ethics</i></p>
                While Germany's existing Lieferkettengesetz is a step in the right direction, the proposed EU Supply
                Chain Law extends its scope, offering a harmonized binding legal frame and thus a level playing field
                for all EU-based companies. The global impact of such legislation cannot be overstated, especially in
                the wake of the vulnerabilities of workers in global supply chains exposed by the COVID-19 pandemic. The
                EU law aims to shift responsibility from individual consumers to corporations, creating a more
                sustainable and ethical approach to global trade.
            </>
        }, {
            imageSrc: "/images/blog/csddd_in_danger/jaeckle_quote.jpg",
            title: "Our Call to Action: Uniting for a Responsible Future",
            description: <>
                <p><i>Who We Are and What We Demand</i></p>
                We are citizens, civil society organizations, and businesses advocating for responsible
                entrepreneurship. Our plea is directed towards Chancellor Olaf Scholz, the SPD, and the Greens, urging
                them to fulfill their commitment to support the EU-Lieferkettengesetz based on international standards.
                The upcoming vote on February 9, 2024, holds the key to shaping the future of corporate responsibility
                for human rights and environmental protection.
                <p><i> CSDDD: A Ray of Hope for Transformation</i></p>
                The proposed EU Supply Chain Law presents an opportunity to take significant strides towards a more
                ethical and sustainable business landscape. Contrary to misleading claims, the CSDDD aligns with
                international standards, provides clarity for sectors and risks, and ensures a level playing field for
                companies across the EU. Embracing this transformative legislation is not just a trend but a necessity
                to meet the diverse challenges of the global economy.
                <p><i>Countering Opposition with Facts and Logic</i></p>
                The opposition, including elements within the FDP, seems rooted in preserving the status quo rather than
                embracing necessary transformations in business models and global supply chains. The CSDDD doesn't ask
                for the impossible; it demands companies to uphold justice and environmental preservation. The majority
                of German companies, as per the Handelsblatt study, stand in favor of this progressive legislation,
                dispelling the notion that it's against the interests of the business community.
            </>
        }, {
            imageSrc: "/images/blog/csddd_in_danger/cta.png",
            title: "A Defining Moment for Europe's Ethical Business Landscape",
            description: <>
                The upcoming vote on the EU Supply Chain Law is more than a legislative decision; it is a defining moment for the ethical foundations of European businesses. As citizens, organizations, and companies, we must unite to ensure that our collective voice is heard, urging governments to embrace responsible practices and contribute to a sustainable and just global economy. The time to act is now, and the future of corporate responsibility hangs in the balance.
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>The Shocking Twist in Germany's Stand on EU Supply Chain Law</HeadingTitle>
                        <HeadingDescription>
                            Germany's recent announcement of abstaining from the CSDDD Corporate Sustainability Due Diligence Directive, also know as EU Supply Chain Law, has sent shockwaves across the European Union. The decision, revealed on February 7, 2024, has left many puzzled and concerned about the implications for human rights and environmental protection. Let's delve into the heart of the matter and explore the key arguments surrounding this unexpected move.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                        <div>
                            <iframe src="/images/blog/csddd_in_danger/Offener Brief.pdf" width="100%" height="2000px" />
                        </div>
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>

                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
