import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/push/10-year-anniversary-push-ux-conference.jpg",
            title: "10 years of PUSH in Munich🥨",
            description: <>
                Our co-founder Josua, who’s the UX designer in our team, had the chance to represent atlat in our
                first-ever conference booth… The PUSH conference is the yearly happening on User Interface trends,
                shared User Experience learnings, and Service Design best practices in Munich. Every autumn the „Alte
                Kongresshalle“ turns into the perfect place to get inspired and network with any UX-related
                professional: No matter, if a designer, researcher, PM, front-end developer, agency, in-house, or
                freelance. After a year of COVID regulations and hence a remote PUSH, the energy of the audience at this
                year’s PUSH has been more than exhilarating. 🎆
            </>
        }, {
            imageSrc: "/images/blog/push/push-ux-conference-inspiring-thought-leaders.png",
            title: "Inspiration by thought leaders💡",
            description: <>
                Whether it’s technical topics such as the pitfalls of Dark mode, life lessons on keeping that creative
                spark as a designer, or talks addressing the most pressing issues of our time: The lineup at this PUSH
                conference had everything a UX designer could wish for. 🚀
                <br/> Our highlight definitely happened at the beginning: The morning of the first conference day
                already started with a wake-up call on „Sustainable UX - or how UX can (hopefully) save the world“ by
                Thorsten Jonas from SUX - The Sustainable UX Network. The talk was referencing the 10th anniversary of
                the conference and hence asking the self-critical question of whether we’d still be talking about design
                trends and UX innovations, or if we would be facing much bigger problems. Thorsten asked the designers
                in the audience to „stop designing for the user“ and have a look at all the actors of the system. Even
                further he urged the design community to reflect the status quo of designing for growth, but rather
                designing for balance. Special thanks to Thorsten for starting the conference with this eye-opening talk
                and thus also shaping the thoughts and the conversations of the entire audience during the whole
                conference. 💚
                <br/> Thanks to all the speakers for sharing their learnings, their stories, and their research with the
                attendees of the PUSH conference!
            </>
        }, {
            imageSrc: "/images/blog/push/atlat-knows-how-to-design-for-different-cultures.png",
            title: "PUSH-ing the discourse on social innovation at the atlat booth",
            description: <>
                We had the chance to present atlat in our own booth and to gather feedback on different questions. We’ve
                been gathering feedback from all the different design experts on all the touchpoints of our software.
                Moreover, the designers at our booth shared their amazing ideas for new features and creative campaigns
                with us. Thanks for that.💜
                <br/> Another big discussion point we wanted to gather feedback about designs in different cultures: We
                had the privilege to meet and discuss with many designers with diverse backgrounds. The image of the map
                shows for which cultures or countries designers felt confident to design. This map was a great
                conversation starter to exchange thoughts on how to design for different cultures. With our additional
                online survey, we learned about the design and technology trends but also focussed on the question of
                how feedback is given and how problems are raised in different cultures. We are well aware that this
                survey can only be a glimpse into that topic and we need to analyze it carefully in terms of individual
                perception in relation to societal trends. Nevertheless, we are really happy about the findings and
                grateful to everyone who participated.
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Exhibition at PUSH UX conference 2022</HeadingTitle>
                        <HeadingDescription>
                            We had the privilege to contribute to the exhibition at PUSH UX conference 2022, the 10
                            years anniversary of the 2-days conference.💜
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
