import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/oecd_garment_forum_paris/oecd-forum-paris-conference-center.jpg",
            title: "About the Forum",
            description: <>
                👟👕 🤝 Since its adoption in 2017, the OECD Garment Guidance has become accepted as the landmark framework
                for due diligence in the sector, supported by 50 governments, aligned with OECD, UN and ILO instruments,
                and negotiated with business, trade unions and civil society. It provides ambitious but pragmatic
                guidance to all companies - large and small - operating in global garment and footwear supply chains, to
                prevent and address the negative impacts of their activities and contribute to sustainable development.
                We discussed topics from living wages, worker engagement and buyer-supplier collaboration, over
                greenwashing, assessment of risk-based due diligence, and producer perspectives on mHREDD, to circular
                textile supply chains and responsible sourcing from conflict countries. This is today more relevant than
                ever: Fashion companies are facing regulatory pressure, for example, the French (Loi de Vigilance) and
                German supply chain laws (Lieferkettensorgfaltspflichtengesetz) and future EU Corporate Sustainability
                Due Diligence Directive. What a great chance to meet industry leaders and listen to their insights!
                Overall, on the OECD Due Diligence Guidance for Responsible Supply Chains in the Garment and Footwear
                Sector Forum, it became apparent how important multi-stakeholder dialogue is, particularly in a complex
                sector like fashion.
            </>
        }, {
            imageSrc: "/images/blog/oecd_garment_forum_paris/atlat-ceo-networks-at-oecd-forum-paris.jpg",
            title: "The Importance of strong Workers’ Unions and Collective Bargaining",
            description: <>
                🌍One highlight was listening to the powerful speech of Nazma Akter highlighting and the following
                session debating the importance of Workers' Unions and Collective Bargaining! Andrei Vasiliev, Social
                Impact Manager at Bestseller, and Kenichi Tomiyoshi, Executive Vice President at Japan Textile
                Federation shared their approach how to improve worker engagement from the company's point of view.
                Nazma Akter, Executive Director at Away Foundation, highlighted the importance of Workers' Unions and
                Collective Bargaining. Overall, it became very apparent that companies need to actively engage with
                workers and trade unions in every step of the due diligence process.
            </>
        }, {
            imageSrc: "/images/blog/oecd_garment_forum_paris/presentation-at-oecd-forum-paris.jpg",
            title: "Studies on the impact of the climate crisis on garment production",
            description: <>
                ⚖️One of the really interesting Side Sessions of the forum was the discussion about two new research
                studies: “Higher Ground” by Cornell University Global Labour Institute and “Trade Realities” by Katalyst
                Initiative. “Higher Ground” presents original projections of impacts for workers, suppliers, and
                national economies of climate breakdown in four case study production zones in Asia, while “Trade
                Realities” details how trade flows are likely to impact the effectiveness of supply chain governance
                efforts like HRDD, now and into the future. These studies helped to understand how the climate crisis
                already impacts garment production and made me realize that global policies are needed for effective
                HRDD laws and systems to support a just transition in response.
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>OECD Due Diligence Guidance for Responsible Supply Chains in the Garment and
                            Footwear Sector Forum 2023 in Paris</HeadingTitle>
                        <HeadingDescription>
                            Last week our co-founder Josua had the incredible chance to participate in the 9th OECD Due
                            Diligence Guidance for Responsible Supply Chains in the Garment and Footwear Sector Forum
                            2023 in Paris, which brought together workers' representatives, trade unions, businesses,
                            and policymakers with close to 400 participants in Paris and over 1000 more joining online
                            to discuss responsible business conduct, working conditions, and supply chain due diligence
                            in the fashion sector.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
