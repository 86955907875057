import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

export default () => {
    const Subheading = tw.span`tracking-wider text-sm font-medium`;
    const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
    const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
    const Description = tw.span`inline-block mt-8`;
    const imageCss = tw`rounded-4xl`;
    return (
        <AnimationRevealPage>
            <Hero
                heading={<>Introduction to legal requirements <HighlightedText>for complaint mechanisms</HighlightedText></>}
                description= {<div>
                <p>
                    The German Due Diligence in Supply Chains Act imposes various obligations on
                    companies to secure the effective protection of human rights in their supply chains.
                    One of these obligations is to establish effective complaint mechanisms along their
                    entire supply chain. Persons affected by human rights violations must have the
                    possibility to inform companies about these violations with a view to obtaining
                    redress. The webinar focuses on legal requirements regarding such complaint
                    mechanisms.
                </p>
                <p>Holger Hembach is an attorney specializing in human rights law. For more than a
                    decade he gained experience in the field of human rights in various international
                    organizations. He advises companies on business and human rights. He is also active
                    in proceedings before the European Court of Human Rights.
                    Find out more about Holger Hembach on his website: <a target="_blank"
                                                                          href="https://rechtsanwalt-lieferkettengesetz.de/">rechtsanwalt-lieferkettengesetz.de</a>
                </p>
            </div>}
                imageSrc={"/images/webinars/first_webinar_hembach.png"}
                imageCss={imageCss}
                imageDecoratorBlob={true}
                primaryButtonText="Order Now"
                watchVideoButtonText="Watch the recording"
                watchVideoYoutubeUrl="https://www.youtube.com/embed/-eoPuORHXuY"
            />
            <Footer/>
        </AnimationRevealPage>
    );
}
