import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

export default () => {
    const Subheading = tw.span`tracking-wider text-sm font-medium`;
    const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
    const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
    const Description = tw.span`inline-block mt-8`;
    const imageCss = tw`rounded-4xl`;
    return (
        <AnimationRevealPage>
            <Hero
                heading={<>Software Design <HighlightedText>for human rights</HighlightedText></>}
                description={<div>
                    <p>
                        Join Macromedia University and atlat GmbH in the Online Event “Design in Human
                        Rights - The Role of Start-Ups in Social Sustainability”. In the event we will to
                        discuss the following questions: How can designers advocate for human rights? What
                        role do start-ups play in social sustainability? The speakers will introduce their
                        projects and in the panel, the role of start-ups in social sustainability will be
                        debated. How can start-ups design societal change?
                        The event will be held in English. Participation is free.
                    </p>
                    <p>
                        For whom: Anyone interested in Social Sustainability, Social Design, or Social
                        Start-Ups - No prior knowledge is required
                        <br/>
                        Agenda:
                        <br/>
                        18:00 - 18:10: Welcome/Introduction
                        <br/>
                        18:10 - 18:30: Impulse by atlat
                        <br/>
                        18:30 - 19:00: Impulses by Guest Speakers
                        <br/>
                        19:00 - 19:30: Panel Discussion and Q&A
                    </p>
                </div>}
                imageSrc={"/images/webinars/design-for-human-rights.png"}
                imageCss={imageCss}
                imageDecoratorBlob={true}
                primaryButtonText="Order Now"
                watchVideoButtonText="Watch the recording"
                watchVideoYoutubeUrl="https://www.youtube.com/embed/PeN-HXlZ91M"
            />
            <Footer/>
        </AnimationRevealPage>
    );
}
