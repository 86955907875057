import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-5 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
    ${props => css`background-image: url("${props.imageSrc}");`}
    ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/textracer/textracer_and_atlat_collab.png",
            title: "⚠️ DIFFICULTY OF PROTECTING HUMAN RIGHTS",
            description: <>
                Protecting human rights across textile supply chains creates significant challenges for many companies.
                That's why tex.tracer and atlat have joined forces to assist textile companies in supporting garment
                workers. tex.tracer is specialised in supply chain transparency and assists fashion companies to collect
                supply chain data from the primary source. With this data, the fashion companies are able to make
                educated decisions. atlat is specialised in social justice and helps companies to reach stakeholders
                such as factory workers.
                <br/>
                <br/>
                Cathy Chen, a CSR manager at a textile company, managed to map their supply chain partner with the user
                of tex.tracer. She wanted to gather insights into the working conditions at their supply chain partners
                without relying exclusively on factory visits and audits, recognizing that factory dynamics can
                significantly change in the presence of brand managers or auditors.
                <br/>
                <br/>
                While it's possible to obtain this data through supplier self-assessments, the information provided by
                factory managers in self-assessments isn't always entirely reliable due to their conflict of interest.
                That’s why gathering insights on working conditions directly from workers becomes crucial for meaningful
                Human Rights Due Diligence (HRDD) improvement initiatives.
                <br/>
                <br/>
                With the aim of fostering closer relationships with factory workers, Cathy and her team decided to
                conduct the atlat worker voice survey in the facility and to install an external grievance mechanism.The
                project's primary goal was to investigate human rights risks in the factory and take action on any
                identified issues.
            </>
        }, {
            imageSrc: "/images/blog/textracer/checklist.png",
            title: "🛠️ USING TECHNOLOGY TO GET IN TOUCH WITH WORKERS",
            description: <>
                <p>An on-site trainer visited the factory and engaged with workers in their native language to conduct
                    the survey, which was designed with tailored questions to identify potential risks within the
                    workforce. Using the atlat chat, we've implemented a messenger-enabled survey accessible through
                    various platforms, e.g. Viber and Telegram.
                </p>
                <p>
                    Alongside the worker voice survey, we established a grievance mechanism enabling workers to provide
                    feedback, report grievances, and suggest improvement ideas. The grievance channel for workers is
                    designed for easy accessibility, allowing workers to communicate in their local language without the
                    need for extra app downloads while having the possibility to stay anonymous.
                </p>
                <p>The grievance platform is facilitating collaboration among all stakeholders to reach corrective
                    actions jointly. In an ongoing dialogue, workers can receive status updates and respond to questions
                    from the complaints handler. The platform offers comprehensive guidance for grievance resolution
                    while automating compliance reporting. Contact with the affected individual is maintained through
                    direct dialogue facilitated by automatic translations.
                </p>
            </>
        }, {
            imageSrc: "/images/blog/textracer/feedback_good_experience_with_atlat.png",
            title: "🌀 THE OUTCOMES SO FAR\n",
            description: <>
                <p><i>
                “As tex.tracer clients, we already know our suppliers. The survey provided unique insights into production processes that would have otherwise remained undiscovered."</i> Cathy Chen, CSR Manager.
                </p>
                <p>The results of the survey provided a fresh perspective on production operations, enabling more targeted discussions with factory management. The week after conducting the survey, Cathy visited the factory. Armed with the survey findings, she had valuable insights to discuss with the factory management and knew what to inspect during her visit to the facility. The tangible data from the survey report helped guide the discussion with management. Together with the factory management, corrective actions for reported issues by workers are planned.
                </p>

            </>
        },


    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Strengthening Worker Voice: Grievance Mechanism and Worker Voice Survey with a
                            client of tex.tracer
                        </HeadingTitle>
                        <HeadingDescription>
                            In the collaboration between tex.tracer and atlat, we embarked on a project to support
                            social responsibility by including workers' voices. Methods of evaluating working
                            conditions, like self-assessments, audits, or factory visits, have their strengths, but they
                            merely offer a glimpse into a specific moment. Recognizing these limitations, we took
                            additional steps to enhance our approach. Alongside these methods, we conducted a worker
                            voice survey and established a grievance mechanism with the goal of gathering genuine
                            insights, resolving issues, and shaping long-term supplier relationships. Already, we’re
                            witnessing positive outcomes as the survey insights enabled constructive discussions between
                            factory management and CSR team. This project highlights our shared dedication with
                            tex.tracer to foster social responsibility within the textile sector.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
);
};
