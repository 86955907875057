import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/neonyt/neonyt_in_duesseldorf_2023.jpeg",
            title: "FAIR FASHION",
            description: <>
                💚 Fair fashion is a topic that has been gaining more and more attention in recent years, and one of the
                key events that highlight this movement is the Neonyt fair fashion exhibition. This annual event brings
                together fashion brands, designers, and industry professionals to showcase sustainable and ethical
                fashion options. The importance of fair fashion lies in the fact that it prioritizes the well-being of
                workers in the fashion industry, as well as the environmental impact of production. Connecting with
                other professionals and consumers who share these values is crucial in driving #change within the
                industry. The influence of fair fashion on consumers is growing, as more and more people are becoming
                conscious of the impact their clothing choices have on the world. Studies have shown that a significant
                portion of consumers are willing to pay more for clothing that is ethically and sustainably made.
            </>
        }, {
            imageSrc: "/images/blog/neonyt/josua-and-hauke-from-atlat-with-nikki-from-textracer.jpeg",
            title: "COOPERATION",
            description: <>
                🤝 We visited the NEONYT in the course of the FashionForChange Accelerator. The vision of the Fashion For
                Change EU program is to foster cooperation regarding sustainablefashion in the EU! We at atlat are
                aiming to make every complaint in the textile supply chain heard! So we couldn’t be prouder about our
                cooperation with tex.tracer taking the next steps forward. tex.tracer is a Dutch company offering a
                digital platform for radical transparency in fashion supply chains. Our combined vision is: From
                complaints to compliance - traced for the whole supply chain. We want to enable textile companies to
                ensure a better working environment at their suppliers up to the cotton field. So we look forward to the
                coming progress together in the accelerator.
            </>
        }, {
            imageSrc: "/images/blog/neonyt/fashion-for-change-sign.jpeg",
            title: "FASHION FOR CHANGE",
            description: <>
                🧵With the other participants of Fashion For Change EU, we discussed circularity, waste hierarchy, and
                European Union policies with Arthur ten Wolde from Ecopreneur.eu European Sustainable Business
                Federation. Also we learned more about the exciting programs for fashion startups by theGlobal
                Entrepreneurship Centre. Moreover, with the guidance of our Fashion for Change mentorsMarkus Vihma,Silke
                Lieser,Maria Kristiin Peterson andReet Aus PhD, we went through NEONYT with open eyes, put ourselves in
                a consumer beginners mindset, and examined the presentation thoroughly regarding sustainability,
                circularity, fairness, and supply chain transparency. Some brands surprised us positively, while other
                brands lacked some details on sustainability and fairness.
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>NEONYT Fair Fashion in Düsseldorf</HeadingTitle>
                        <HeadingDescription>
                            The Neonyt fair fashion in Düsseldorf at AREAL BÖHLER - Der Innovations- und Messestandort
                            was an outstanding event that brought together key players in the fashion industry to
                            showcase sustainable and ethical fashion options. The event highlighted the importance of
                            #fairfashion, which prioritizes the well-being of workers and the environment. The influence
                            of fair fashion on consumers is growing, as more people become conscious of the impact of
                            their clothing choices.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
