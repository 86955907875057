import React, {useState} from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import {css} from "styled-components/macro";
import jsonp from "jsonp";
import toQueryString from "to-querystring";
import SimpleForm from "./SimpleForm";
import {useTranslation} from "react-i18next";
import FooterForm from "./FooterForm";
import {compose} from "recompose";
import {withFirebase} from "../Firebase";
import {useHistory} from "react-router-dom";

const getAjaxUrl = url => url.replace("/post?", "/post-json?");

const MailchimpSubscribeBase = ({url, type = "type1", firebase, tag, label, forward}) => {

        const {t} = useTranslation(['forms']);

        const [status, setStatus] = useState(null);
        const [message, setMessage] = useState(null);
        const history = useHistory();

        const subscribe = data => {
            const params = toQueryString(data);
            const url2 = getAjaxUrl(url) + "&" + params;

            function translateMessage(message) {
                if (!message) return message;

                if (message.includes("We have sent you a confirmation email") || message.includes("Fast fertig")) {
                    return t('forms:confirmationMailSuccessfullySent', 'We have sent you a confirmation email, check your inbox.');
                }

                if (message.includes("email address must contain a single @")) {
                    return t('forms:emailContainsOnlyOneAt', 'An email address must contain a single @');
                }

                if (message.includes("domain portion of the email address is invalid")) {
                    return t('forms:domainInvalid', 'The domain portion of the email address is invalid (the portion after' +
                        ' the @-sign).');
                }

                if (message.includes("username portion of the email address is invalid")) {
                    return t('forms:emailNameInvalid', 'The username portion of the email address is invalid (the portion' +
                        ' before the @-sign).');
                }

                if (message.includes("email address looks fake or invalid")) {
                    return t('forms:fakeEmail', 'This email address looks fake or invalid. Please enter a real email address.');
                }

                return message;
            }

            setStatus("sending");
            setMessage(null);

            jsonp(url2, {param: "c"}, (err, data) => {
                if (err) {
                    setStatus("error");
                    setMessage(translateMessage(err));
                } else if (data.result !== "success") {
                    setStatus("error");
                    setMessage(translateMessage(data.msg));
                } else  if (!!forward && data.result === "success") {
                    history.push(forward);
                } else {
                    setStatus("success");
                    setMessage(translateMessage(data.msg));
                }
            });
            firebase.doLog("newsletter_signup")
        };

        if (type === "type2") {
            return <FooterForm status={status} tag={tag} label={label}
                               message={message}
                               onSubmitted={formData => subscribe(formData)}
            />
        }
        return <SimpleForm status={status} tag={tag}
                           message={message}
                           onSubmitted={formData => subscribe(formData)}
        />

    }
;

const MailchimpSubscribe = compose(withFirebase)(MailchimpSubscribeBase);

export default MailchimpSubscribe;