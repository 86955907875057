import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

export default () => {
    const Subheading = tw.span`tracking-wider text-sm font-medium`;
    const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
    const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
    const Description = tw.span`inline-block mt-8`;
    const imageCss = tw`rounded-4xl`;
    return (
        <AnimationRevealPage>
            <Hero
                heading={<>Startups for <HighlightedText>Sustainable Fashion Supply Chains</HighlightedText></>}
                description={<div>
                    <p>
                        The fashion industry is one of the largest polluters in the world. According to the UN Framework
                        Convention on Climate Change, the fashion industry is responsible for about 10% of global carbon
                        emissions. In addition, fashion production often relies on exploitative working conditions and human
                        rights violations. By understanding their supply chains, fashion companies can identify risks and make
                        changes to reduce their environmental impact and improve working conditions.
                        <br/>
                        In this webinar we want to discuss with two fellow fashion and tech startups what they do, how they
                        tackle challenges in value chains, and share best practices.
                        <br/>
                        THE ORGANIZERS
                        atlat offers companies a digital complaint system to ensure workers rights at their suppliers and comply
                        with the LkSG.
                        <br/>
                        THE SPEAKERS
                        Nikki Admiraal works for tex.tracer on client success and UX/UI. She believes when fashion brands know
                        their supply chain, they can make educated decisions.
                        <br/>
                        Ornella de la Campa is the founder of Open Studios, a platform connecting independent designers and
                        small brands to sustainable manufacturers worldwide.
                        <br/>
                        The event was be held in English.
                    </p>
                </div>}
                imageSrc={"/images/webinars/global_textile_supply_chains.png"}
                imageCss={imageCss}
                imageDecoratorBlob={true}
                primaryButtonText="Order Now"
                watchVideoButtonText="Watch the recording"
                watchVideoYoutubeUrl="https://www.youtube.com/embed/CRUlZYr_JnM"
            />
            <Footer/>
        </AnimationRevealPage>
    );
}
