import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/ffc_kickoff/Tallinn-Design-Festival-Green-Being-WTF.jpg",
            title: "Tallinn Design Festival “Green Being: WTF”",
            description: <>
                Three months ago, we announced that we were selected for the Fashion For Change EU Accelerator
                Programme. Recently we traveled to Tallinn and met with our partner tex.tracer for the kick-off of the
                program and our partnership. By the way: Of course, we tried to go to Tallin with the smallest
                CO2-Impact possible and traveled over 2 days by train and bus while stopping in Berlin, Warsaw, and
                Vilnius.
                <br/>
                The kick-off took place during Tallinn Design Week. The motto of this year’s event was “Green Being:
                WTF* (Way To the Future)” - focussing on creative ideas for a sustainable future. The exhibition,
                fashion shows, and talks took place in an old factory.
                <br/>
                Our highlight was the talk and Fashion Show by Reet Aus addressing the unresolved waste issues of the
                fast fashion industry while showcasing the completely circular or upcycled clothes by Reet Aus.
            </>
        }, {
            imageSrc: "/images/blog/ffc_kickoff/Workshops-and-Mentoring.png",
            title: "Workshops and Mentoring",
            description: <>
                Firstly, we gathered a lot of inspiration while walking through the faculties of the modern and
                futuristic building of the Estonian Academy of Arts, and got even further inspired while having a look
                behind the scenes at Stella Somalis and Reet Aus.
                <br/>
                Secondly, we discussed our partnership in terms of our business strategy with Arvydas from Katalista
                Ventures. Reet Aus depicted what’s possible in terms of circularity in fashion and present us with the
                certification concept of UPMADE, while Stella Soomlais focused on the long-term use & reuse of leather
                bags.
                <br/>
                Thirdly, Silke Lieser shared her wisdom on building Sustainable Brand Identities. We choose archetypes
                that reflect our brand and worked on a brand pyramid. The latter was especially helpful, as gave us the
                possibility to reflect on what messages and values we want to prioritize when presenting our brand.
            </>
        }, {
            imageSrc: "/images/blog/ffc_kickoff/collaboration-between-atlat-and-tex-tracer.jpg",
            title: "Collaboration with tex.tracer",
            description: <>
                The goal of this program for atlat is to focus on our collaboration with tex.tracer. tex.tracer is THE
                platform for fashion supply chain transparency, using blockchain technology the platform enables to make
                global fashion supply chains transparent from raw material to end product, and thus enables informed
                decision making. During the Kick-off in Tallinn, we developed a roadmap for the upcoming months where
                will collaborate on our shared vision: from complaints to compliance – traced through the whole supply
                chain.
                <br/>
                <br/>
                Thanks to EKA, Katalista, ecopreneur.eu and CIVITA for organizing this amazing Kick-Off!
            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>Fashion For Change EU Accelerator Kick-Off in Tallinn</HeadingTitle>
                        <HeadingDescription>
                            The Fashion4change EU accelerator program supports SME’s, fashion studios, and start-ups, to
                            work on innovation for a more sustainable, more social, and more circular fashion industry.
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}

                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
