import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

export default () => {
    const Subheading = tw.span`tracking-wider text-sm font-medium`;
    const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
    const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
    const Description = tw.span`inline-block mt-8`;
    const imageCss = tw`rounded-4xl`;
    return (
        <AnimationRevealPage>
            <Hero
                heading={<>Reducing Textile Waste means <HighlightedText> Systemic Change in Supply Chains.</HighlightedText></>}
                description={<div>
                    <p>
                        THE TOPIC
                        The textile and fashion industries consume huge quantities of resources and produce a large amount of
                        waste. On average 18% of textile materials are wasted even before reaching the customers. This
                        pre-consumer waste and wasted resources that it took to produce the material, are huge part of the
                        enormous environmental burden that fashion industry creates globally and every single day.
                        <br/>
                        THE SPEAKER
                        Designer Reet Aus PhD is dedicated to slow fashion and her upcyled collections are entirely made from
                        post-production leftovers. She has developed an industrial upcycling method that involves complete
                        lifecycle analysis of the garments.
                        The method enables to circulate the leftover metarials back to production inside the same factory.
                        <br/>
                        THE ORGANIZERS
                        atlat offers companies a digital complaint system to ensure workers rights at their suppliers and comply
                        with the LkSG.
                        <br/>
                        Guest Speaker
                        Reet Aus
                        <br/>
                        Founding Partner at Aus Design and Upmade
                        Senior researcher at EKA. Head of Sustainable Design & Material Lab - DiMa
                        <br/>
                        For whom:
                        Anyone interested in #sustainability, #fairfashion, #circulareconomy, or #textilewaste
                        <br/>
                        No prior knowledge is required
                        <br/>
                        <br/>
                        The event was be held in English.
                    </p>
                </div>}
                imageSrc={"/images/webinars/case_study_industrial_upcycling.png"}
                imageCss={imageCss}
                imageDecoratorBlob={true}
                primaryButtonText="Order Now"
                watchVideoButtonText="Watch the recording"
                watchVideoYoutubeUrl="https://www.youtube.com/embed/0MCBb8lSiRE"
            />
            <Footer/>
        </AnimationRevealPage>
    );
}
