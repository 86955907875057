import React, {useState} from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container, ContentWithPaddingXl} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading} from "../../components/misc/Headings";
import {PrimaryButton} from "../../components/misc/Buttons";

import MailchimpSubscribe from "../../components/MailChimpSubscribe";
import {compose} from "recompose";
import {withFirebase} from "../../components/Firebase";
import {
    WEBINAR_RECORDING_1,
    WEBINAR_RECORDING_2,
    WEBINAR_RECORDING_3,
    WEBINAR_RECORDING_4, WEBINAR_RECORDING_5, WEBINAR_RECORDING_6, WEBINAR_RECORDING_7
} from "../../constants/routes";


const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900`;
const Posts = tw.div`mt-6 sm:-mr-8 flex flex-wrap`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${props =>
          props.featured &&
          css`
            ${tw`w-full!`}
            ${Post} {
              ${tw`sm:flex-row! h-full sm:pr-4`}
            }

            ${Image} {
              ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/3 sm:rounded-t-none sm:rounded-l-lg`}
            }

            ${Info} {
              ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg sm:border-t-2 sm:border-l-0`}
            }

            ${Description}
            ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
          }
          `}
`;
const Post = tw.div`cursor-pointer flex flex-col bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-full bg-contain bg-no-repeat bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-bold text-2xl text-primary-900 group-hover:text-primary-500 transition duration-300`;
const Description = tw.div`text-xs`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600`;
const ButtonContainer = tw.div`flex justify-center`;
const LoadMoreButton = tw(PrimaryButton)`mt-16 mx-auto`;


const WebinarsIndexBase = ({firebase}) => {
    const posts = [
        {
            category: "Human Rights",
            imageSrc: "/images/webinars/lunchAndLearnHRDD/human_rights_lunch_and_learn.jpg",
            date: <>25.04.24 / 12.00 - 01.00 PM CET / Zoom</>,
            title: "Online LUNCH &amp; LEARN session!",
            description: <div>
                Human rights due diligence (HRDD) regulations are vital for ensuring ethical and responsibl
                business practices, preventing and addressing human rights abuses. The Corporate
                Sustainability Due Diligence Directive (CSDDD) and the Corporate Sustainability Reporting
                Directive (CSRD) in the EU exemplify such regulations.<br/>
                In our webinar, we&#39;ll delve into the legal framework for Dutch companies, the human rights
                issues in the textile sector, and explore the role of technology in addressing these challenges. <br/>
                Meet our panel:<br/>
                ● Holger Hembach, a attorney specializing in human rights law. With over a decade of
                experience in various international organizations, Holger advises companies on
                business and human rights matters. Learn more about him at www.rechtsanwalt-
                lieferkettengesetz.de.<br/>
                ● Kristy Bryars, Manager of Strategic Partnerships at International Justice Mission
                Netherlands. Kristy fosters international collaboration to combat slavery and human
                trafficking, with a special focus on the textile sector. Explore more about International
                Justice Mission Netherlands at www.ijmnl.org.<br/>
                ● Nikki Admiraal, dedicated to client success and UX/UI at tex.tracer. Nikki advocates for
                transparency in fashion supply chains, empowering brands to make informed decisions.
                You will gain insights into tex.tracer&#39;s collaboration with atlat in client projects during the
                webinar.<br/>
                Save the date for our event: 25.04.24 / 12.00 - 01.00 PM CET / Zoom <br/>
                Register via Eventbrite to secure your spot!
            </div>,
            featured: true,  tag: "watch_webinar_recording", forward: WEBINAR_RECORDING_6
        },
        {
            category: "Regulations",
            imageSrc: "/images/webinars/where-to-start-with-eu-human-rights-regulations.jpeg",
            date: <>Tuesday, May 30 · 10.30 - 11.30 pm CEST</>,
            title: "Where to start will all the HRDD regulations?",
            description: <div>
                Human rights due diligence regulation is crucial in ensuring that businesses operate ethically and
                responsibly.
                This webinar aims to compare the CSDDD, LkSG, and UNGP from a legal perspective to provide actionable
                legal
                insights for businesses.
                <br/><br/>
                The speaker <a href="https://www.linkedin.com/in/ACoAAAhMgxEBVdFHD1D2OiOko9A4_LtWSH4Y9W4">Holger
                Hembach</a> is an attorney specializing in human rights law. For more than a decade he gained
                experience in the field of human rights in various international organizations. He advises companies on
                business and human rights.
            </div>,
            featured: true, tag: "watch_webinar_recording", forward: WEBINAR_RECORDING_7
        }, {
            category: "BEST PRACTICES",
            imageSrc: "/images/webinars/ensuring-health-and-safety-at-work.png",
            date: <>Thursday, May 4 · 6 - 7pm CEST</>,
            title: "Webinar: Ensuring Health & Safety at Work",
            description: <div>
                According to the ILO, there are approximately 340 million occupational accidents every year worldwide,
                leading to an estimated 2.3 million deaths annually. Operational Health and Safety (OHS) refers to the
                set
                of practices and policies aimed at protecting workers from hazards and ensuring their well-being in the
                workplace. It is a crucial aspect of compliance as failure to adhere to OHS standards can result in
                serious
                accidents, injuries, and even fatalities. Digital solutions can help to streamline audits, increase
                compliance, and ultimately save lives.
                <br/>
                <br/>
                Jaanika Jelistratov worked as a health & safety specialist for 10+ years before becoming an OHS trainer
                and
                CEO. She developed VITS, an online platform to manage health & safety processes efficiently.
                <br/>
                <br/>
                Ahti Salumäe, a HSE manager with 15+ years of experience, aims to improve Estonian safety culture and
                prevent incidents through proactive workplace improvements.
            </div>,
            featured: true, tag: "watch_webinar_recording", forward: WEBINAR_RECORDING_6
        }, {
            imageSrc: "/images/webinars/practitioners-insights-into-grievance-handling.png",
            category: "BEST PRACTICES",
            date: <>Wed, March 22, 2023, 6:00 PM – 7:00 PM CET</>,
            title: "A practitioner's insight into handling grievances",
            description: <div>
                <p>
                    With the spreading of mandatory human rights due diligence, companies see themselves increasingly
                    challenged to establish or adapt grievance mechanisms (GM) for human rights adverse impacts along
                    their
                    supply chains. In this scenario, adopting electronic-based channels - online or not – has become a
                    favored approach, as it allows for 24h accessibility and facilitated translation, among other
                    benefits.
                    Simultaneously, this path can add challenges from a rightsholder-centered perspective, as access to
                    and
                    trust in technology varies greatly in the different territories where suppliers are located.
                    Considering
                    these challenges, what are strategies forward for companies in effective grievances management? How
                    can
                    the relationship between possibly affected persons and technology be an asset for both companies and
                    rightsholders? Building on her professional experience as a complaints handler in Brazil, Mariana
                    will
                    share insights on grievance management, accessibility, and technology.
                    <br/>
                    <br/>
                    Mariana is a lawyer specializing in human rights and alternative dispute resolution. She is
                    currently
                    working with Löning – Human Rights & Responsible Business under the German Chancellor Fellowship,
                    funded
                    by the Alexander von Humboldt Stiftung. She focuses on the establishment and improvement of
                    grievance
                    mechanisms for human rights adverse impacts along value chains, building on her experience working
                    as an
                    alternative dispute resolution professional in a company-based remediation program with
                    international
                    repercussions.
                </p>
            </div>,
            featured: true, tag: "watch_webinar_recording", forward: WEBINAR_RECORDING_5
        },
        {
            imageSrc: "/images/webinars/global_textile_supply_chains.png",
            category: "ONLINE PANEL EVENT",
            date: <>Wed., February 01, 2023, 6:00 PM – 7:00 PM CET</>,
            title: "Startups for Sustainable Fashion Supply Chains",
            description: <div>
                <p>
                    The fashion industry is one of the largest polluters in the world. According to the UN Framework
                    Convention on Climate Change, the fashion industry is responsible for about 10% of global carbon
                    emissions. In addition, fashion production often relies on exploitative working conditions and human
                    rights violations. By understanding their supply chains, fashion companies can identify risks and
                    make
                    changes to reduce their environmental impact and improve working conditions.
                    <br/>
                    In this webinar we want to discuss with two fellow fashion and tech startups what they do, how they
                    tackle challenges in value chains, and share best practices.
                    <br/>
                    THE ORGANIZERS
                    atlat offers companies a digital complaint system to ensure workers rights at their suppliers and
                    comply
                    with the LkSG.
                    <br/>
                    THE SPEAKERS
                    Nikki Admiraal works for tex.tracer on client success and UX/UI. She believes when fashion brands
                    know
                    their supply chain, they can make educated decisions.
                    <br/>
                    Ornella de la Campa is the founder of Open Studios, a platform connecting independent designers and
                    small brands to sustainable manufacturers worldwide.
                    <br/>
                    The event will be held in English.
                    Participation is free.
                </p>
            </div>,
            featured: true, tag: "watch_webinar_recording", forward: WEBINAR_RECORDING_4
        },
        {
            imageSrc: "/images/webinars/case_study_industrial_upcycling.png",
            category: "CASE STUDY: INDUSTRIAL UPCYCLING",
            date: <>Wed, January 11, 2023, 6:00 PM – 7:00 PM CET</>,
            title: "Reducing Textile Waste means Systemic Change in Supply Chains.",
            description: <div>
                <p>
                    THE TOPIC
                    The textile and fashion industries consume huge quantities of resources and produce a large amount
                    of
                    waste. On average 18% of textile materials are wasted even before reaching the customers. This
                    pre-consumer waste and wasted resources that it took to produce the material, are huge part of the
                    enormous environmental burden that fashion industry creates globally and every single day.
                    <br/>
                    THE SPEAKER
                    Designer Reet Aus PhD is dedicated to slow fashion and her upcyled collections are entirely made
                    from
                    post-production leftovers. She has developed an industrial upcycling method that involves complete
                    lifecycle analysis of the garments.
                    The method enables to circulate the leftover metarials back to production inside the same factory.
                    <br/>
                    THE ORGANIZERS
                    atlat offers companies a digital complaint system to ensure workers rights at their suppliers and
                    comply
                    with the LkSG.
                    <br/>
                    Guest Speaker
                    Reet Aus
                    <br/>
                    Founding Partner at Aus Design and Upmade
                    Senior researcher at EKA. Head of Sustainable Design & Material Lab - DiMa
                    <br/>
                    For whom:
                    Anyone interested in #sustainability, #fairfashion, #circulareconomy, or #textilewaste
                    <br/>
                    No prior knowledge is required
                    <br/>
                    Agenda:<br/>
                    18:00 - 18:15: Welcome/Introduction<br/>
                    18:15 - 18:45: Impulse by Reet Aus<br/>
                    18:45 - 19:00: Q&A<br/>
                    <br/>
                    The event will be held in English.
                    Participation is free.
                </p>
            </div>,
            featured: true, tag: "watch_webinar_recording", forward: WEBINAR_RECORDING_3
        },
        {
            imageSrc: "/images/webinars/design-for-human-rights.png",
            category: "ONLINE PANEL EVENT",
            date: <>With Prof. Oliver Szasz from Macromedia University Agnieszka Cichocka from Huge
                Thing, Service Designer Bettina Köbler, Amelie Mertin from Impact Hub
                Munich<br/> Wednesday,
                05.10.22 from 05.00 - 06.30 pm</>,
            description: <div>
                <p>
                    Join Macromedia University and atlat GmbH in the Online Event “Design in Human
                    Rights - The Role of Start-Ups in Social Sustainability”. In the event we will to
                    discuss the following questions: How can designers advocate for human rights? What
                    role do start-ups play in social sustainability? The speakers will introduce their
                    projects and in the panel, the role of start-ups in social sustainability will be
                    debated. How can start-ups design societal change?
                    The event will be held in English. Participation is free.
                </p>
                <p>
                    For whom: Anyone interested in Social Sustainability, Social Design, or Social
                    Start-Ups - No prior knowledge is required
                    <br/>
                    Agenda:
                    <br/>
                    18:00 - 18:10: Welcome/Introduction
                    <br/>
                    18:10 - 18:30: Impulse by atlat
                    <br/>
                    18:30 - 19:00: Impulses by Guest Speakers
                    <br/>
                    19:00 - 19:30: Panel Discussion and Q&A
                </p>
            </div>,
            featured: true, tag: "watch_webinar_recording", forward: WEBINAR_RECORDING_2
        },
        {
            imageSrc: "/images/webinars/first_webinar_hembach.png",
            category: "Best practices in Compliance",
            date: <>WITH HOLGER HEMBACH, HUMAN RIGHTS LAWYER<br/> Wednesday, 05.10.22 from 05.00 - 06.30
                pm</>,
            description: <div>
                <p>
                    The German Due Diligence in Supply Chains Act imposes various obligations on
                    companies to secure the effective protection of human rights in their supply chains.
                    One of these obligations is to establish effective complaint mechanisms along their
                    entire supply chain. Persons affected by human rights violations must have the
                    possibility to inform companies about these violations with a view to obtaining
                    redress. The webinar focuses on legal requirements regarding such complaint
                    mechanisms.
                </p>
                <p>Holger Hembach is an attorney specializing in human rights law. For more than a
                    decade he gained experience in the field of human rights in various international
                    organizations. He advises companies on business and human rights. He is also active
                    in proceedings before the European Court of Human Rights.
                    Find out more about Holger Hembach on his website: <a target="_blank"
                                                                          href="https://rechtsanwalt-lieferkettengesetz.de/">rechtsanwalt-lieferkettengesetz.de</a>
                </p>
            </div>,
            featured: true, tag: "watch_webinar_recording", forward: WEBINAR_RECORDING_1
        },
    ];
    const upcoming = [];
    const [visible, setVisible] = useState(7);
    const onLoadMoreClick = () => {
        setVisible(v => v + 6);
    };
    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <ContentWithPaddingXl>
                    <HeadingRow>
                        <Heading>atlat Webinar Series</Heading>
                    </HeadingRow>
                    <HeadingDescription>
                        <p>
                            How do companies maintain human rights due diligence in their supply chains and what are the
                            legal frameworks? How can companies implement practices for fairer working conditions in
                            their supply chains beyond the legal frameworks? What are the benefits for companies,
                            factories, and workers from investing in fair labor conditions and human rights due
                            diligence?
                            The atlat webinar series wants to answer these questions by inviting expert speakers to
                            share their knowledge.
                            What is more, the series will entail best practices, inspiring stories, and helpful tips for
                            CSR, compliance, and sustainability procurement professionals. Entrepreneurs, lawyers, and
                            activists will share their experiences, their stories, and their learnings. The sessions are
                            supplemented by answers to typical questions from companies.
                            <br/><br/>
                            The webinars will be held in English.<br/>
                            Participation is free.
                        </p>
                    </HeadingDescription>
                    <br/>
                    <HeadingRow>
                        <Title>Upcoming Events</Title>
                    </HeadingRow>
                    <Posts>
                        {upcoming.slice(0, visible).map((post, index) => (
                            <PostContainer key={index} featured={post.featured}>
                                <Post className="group">
                                    <Image imageSrc={post.imageSrc}/>
                                    <Info>
                                        <Category>{post.category}</Category>
                                        <CreationDate>{post.date}</CreationDate>
                                        {post.featured && post.description &&
                                            <Description>{post.description}</Description>}
                                        <PrimaryButton style={{marginRight: 20}} onClick={() => {
                                            window.open(post.eventbrite, '_blank');
                                            firebase.doLog("webinar_sign_up")
                                        }}>Sign Up</PrimaryButton>
                                    </Info>
                                </Post>
                            </PostContainer>
                        ))}
                    </Posts>
                    <br/>
                    <HeadingRow>
                        <Title>Past Events</Title>
                    </HeadingRow>
                    <Posts>
                        {posts.slice(0, visible).map((post, index) => (
                            <PostContainer key={index} featured={post.featured}>
                                <Post className="group">
                                    <Image imageSrc={post.imageSrc}/>
                                    <Info>
                                        <Category>{post.category}</Category>
                                        <CreationDate>{post.date}</CreationDate>
                                        {post.featured && post.description &&
                                            <Description>{post.description}</Description>}
                                        <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL} type="type2"
                                                            forward={post.forward}
                                                            tag={post.tag} label="Watch Recording"/>
                                    </Info>

                                </Post>
                            </PostContainer>
                        ))}
                    </Posts>
                    {visible < posts.length && (
                        <ButtonContainer>
                            <LoadMoreButton onClick={onLoadMoreClick}>Load More</LoadMoreButton>
                        </ButtonContainer>
                    )}
                </ContentWithPaddingXl>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};


const WebinarsIndex = compose(withFirebase)(WebinarsIndexBase);

export default WebinarsIndex;
