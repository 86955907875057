import React from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import {Container} from "../../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro";
import Header from "../../components/headers/light.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";
import {SectionHeading as HeadingTitle} from "../../components/misc/Headings";
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg";

const Link = tw.a`w-full sm:w-auto text-sm sm:text-base px-6 py-3 sm:px-8 sm:py-4 lg:px-10 lg:py-5 mt-4 first:mt-0 sm:mt-0 sm:mr-8 sm:last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline`;
const PrimaryLink = tw(Link)`mt-3 bg-red-500 text-gray-100 shadow-lg hocus:bg-red-600 hocus:text-gray-200 self-end`;

const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-96 w-96 bg-cover bg-center rounded-t-lg`}
`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-3 font-medium text-gray-600 text-center`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
    tw`mt-24 md:flex justify-center items-center`,
    props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;

const SvgDotPattern1 = tw(
    SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
    SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
    SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;


export default () => {

    const cards = [
        {
            imageSrc: "/images/blog/csd3/cs3d.png",
            title: "What will be the new obligations for companies under the Corporate Sustainability Due Diligence Directive according to the Parliament's position?",
            description: <>
                🌍 Identify, prevent, and mitigate negative impacts on human rights and the environment Monitor and
                assess the impact of value-chain partners Implement a transition plan to limit global warming to 1.5°C.
                <br/> 🔍The Corporate Sustainability Due Diligence Directive (CSDDD) will have a broad scope,
                encompassing various EU companies. This includes companies with over 250 employees and a worldwide
                turnover exceeding 40 million euros. Additionally, non-EU companies will be included if their turnover
                exceeds 150 million euros, with at least 40 million euros generated within the EU.
                <br/> 🇪🇺Under the CSDDD, companies will be required to conduct due diligence throughout their entire
                value chains, extending beyond their operations and direct suppliers. This means that activities such as
                the sale, distribution, transport, storage, and waste management of their products or services will be
                subject to scrutiny. This is a really big difference from the German Supply Chain Due Diligence Act or
                Lieferkettensorgfaltspflichtengesetz. Companies will need to take appropriate measures to identify,
                assess, and address any human rights harm that they have caused, contributed to, or are directly linked
                to.
                <br/> 📈 Under the Corporate Sustainability Due Diligence Directive (CSDDD), companies falling under its
                scope will be required to carry out various activities to ensure human rights and environmental due
                diligence. This includes identifying and preventing negative impacts on human rights and the
                environment, monitoring and assessing the impact of value-chain partners, and implementing a transition
                plan aligned with the Paris Agreement to limit global warming to 1.5°C. It is important to note that
                larger companies with over 1,000 employees will face additional consequences, as the plan's targets will
                impact the variable remuneration of directors.
                <br/> 💬Importantly, the CSDDD emphasizes the importance of grievance mechanisms as a key pillar of the
                law. These mechanisms will apply to the entire value chain, ensuring that individuals or communities
                affected by a company's activities have access to effective remedies. This reinforces the accountability
                and responsibility of companies throughout their entire operations and value chains.
            </>
        }, {
            imageSrc: "/images/blog/csd3/eu-votes-for-csd3.png",
            title: "When will the new obligations apply and what are the consequences of non-compliance?",
            description: <>
                ⌛The new obligations imposed by the CSDDD will come into effect 3 or 4 years after the adoption of the
                Directive, depending on the size of the company. But firstly, the so-called trilogue follows, which
                means negotiations between the European Parliament, Council, and Commission to finalize the text of the
                legislation.
                <br/> ⏰Smaller companies will have the option to delay the application of the new rules by an additional
                year. This timeframe allows companies to prepare and adapt to the requirements gradually, ensuring a
                smooth transition to the new obligations.
                <br/> 💰 Fines of up to 5% of the company's global revenues Market bans for non-compliant products
                Exclusion from public procurement for non-EU companies.
                <br/> 🚫 Companies that fail to comply with the CSDDD will face severe consequences. As described,
                non-compliant products may be banned from the market, and fines of up to 5% of the company's global
                revenues can be imposed. Additionally, non-EU companies that do not adhere to the rules may be excluded
                from public procurement in the EU. It is crucial for companies to understand and meet these obligations
                to avoid legal and financial repercussions.
            </>
        }, {
            imageSrc: "/images/blog/csd3/eu-parliament-celebrates-csd3.png",
            title: "What’s next?",
            description: <>
                🎉Congratulations to Lara Wolters MEP for successfully steering the proposed Corporate Sustainability Due
                Diligence Directive through the First Reading vote in the European Parliament. This is a significant
                milestone and a step closer to our goal of ensuring obligations for companies and rights for people,
                climate, and the environment. It is also a momentous achievement for those who deeply care about the UN
                Guiding Principles on Business and Human Rights, including their author, the late John Ruggie. While
                tough negotiations with the European Council and the Commission lie ahead, this vote marks an important
                advancement in holding companies accountable for respecting the environment and human rights.
                <br/> ❗️The European Parliament's approval showcases the recognition that voluntary codes of conduct are
                insufficient in bringing about the necessary transformation in sustainable corporate practices. The vote
                received strong support with 366 in favor and 225 against. Although an attempt to strengthen the
                obligation of company boards and directors to ensure compliance with the law did not succeed, this
                development highlights the growing acknowledgment of the need for robust measures. “The European
                Parliament's support is a turning point in the thinking about the role of corporations in society. A
                corporate responsibility law must ensure that the future lies with companies that treat people and the
                environment in a healthy way - not with companies that have made a revenue model out of environmental
                damage and exploitation…” noted rapporteur Lara Wolters (S&D, NL) following the plenary vote.
                <br/> ❌NGOs see significant weaknesses in the current draft, particularly regarding access to justice
                for those affected. Therefore, NGOs are calling on German Members of the European Parliament and the
                Federal Government to strongly advocate for closing the gaps in the law during the trilogue process.
                <br/> ➡️Moving forward, negotiations between the European Parliament, Council, and Commission will begin
                to finalize the text of the legislation. The trilogue process will involve intense discussions to
                address any differing views and reach compromises. The details of the CSDDD are crucial, as they will
                shape the obligations for companies and their impact on human rights, the climate, and the environment.
                This directive sets a major milestone in EU mandatory human rights and environmental due diligence, with
                the potential to influence similar legislation in other jurisdictions as well. It is essential for
                companies to stay informed and start preparing for the implementation of these new requirements. What
                many people do not know: if the legal text of the EU CSDDD after the trialogue accordingly, then the
                German LkSG will also be adjusted again and adapted to the additional requirements.
                <br/> 📽️If you want to find out more about the CSDDD and how it relates to the LkSG and the UNGP: Have a
                look at the recording of our webinar with Holger Hembach on “Where to start with all the HRDD
                regulations? ➡ UNGP, LkSG, CSDDD compared.”: <a
                href="https://www.youtube.com/@atlat6089">https://www.youtube.com/@atlat6089</a>
                <br/> <br/>
                🇪🇺For more information visit:
                <a href="https://www.europarl.europa.eu/news/en/press-room/20230524IPR91907/meps-push-companies-to-mitigate-their-negative-social-and-environmental-impact">https://www.europarl.europa.eu/news/en/press-room/20230524IPR91907/meps-push-companies-to-mitigate-their-negative-social-and-environmental-impact</a>

            </>
        },
    ];

    return (
        <AnimationRevealPage>
            <Header/>
            <Container>
                <SingleColumn>
                    <HeadingInfoContainer>
                        <HeadingTitle>📣 BIG MILESTONE FOR EU MANDATORY HUMAN RIGHTS AND ENVIRONMENTAL DUE DILIGENCE
                            📣</HeadingTitle>
                        <HeadingDescription>
                            Yesterday, the European Parliament approved the text for the draft Corporate Sustainability
                            Due Diligence Directive (#CSDDD), which would require certain companies to conduct due
                            diligence to identify, assess and address human rights and environmental harm. The final
                            text will now be negotiated through a trilateral process between the Parliament, Council and
                            Commission. 🗳️ Vote: 366👍 225👎 38⭕️
                        </HeadingDescription>
                    </HeadingInfoContainer>

                    <Content>
                        {cards.map((card, i) => (
                            <Card key={i} reversed={i % 2 === 1}>
                                <Image imageSrc={card.imageSrc}/>
                                <Details>
                                    <Title>{card.title}</Title>
                                    <Description>{(card.description)}</Description>
                                </Details>
                            </Card>
                        ))}
                    </Content>
                    <HeadingInfoContainer>
                        <PrimaryLink href="https://atlat.de/contact-us">
                            Contact us
                        </PrimaryLink>
                    </HeadingInfoContainer>
                </SingleColumn>

                <SvgDotPattern1/>
                <SvgDotPattern2/>
                <SvgDotPattern3/>
                <SvgDotPattern4/>
            </Container>
            <Footer/>
        </AnimationRevealPage>
    );
};
